.mainHeader {
  padding: 11px 9px 11px 14px;
  // background: var(--Main);
  background-color: #fff;
  box-shadow: 0 0 10px #b0b0b0;
  // position: sticky;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;

  .headerRow {
    width: 100%;
    margin-left: 0;
    transition: all 0.3s;
  }

  &.activeHeader {
    .headerRow {

      padding-left: 110px;
    }
  }

  .logo {
    text-decoration: none;
    // width: 210px;
    width: auto;
    opacity: 0;

    img {
      height: 36px;
    }

    h5 {
      font-size: 26px;
      color: #fff;
      margin: 0px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    width: 100%;

    .logoHeading {
      // background: linear-gradient(#65BEAC, #3E8575);
      font-weight: 500;
      font-size: 18px;
      letter-spacing: .1px;
      color: #222;
      text-align: center;
      width: 100%;
      margin: 0px 10px;
    }


  }

  .countryDropdownMain {
    display: inline-block;
    background: linear-gradient(#65beac, #3e8575);
    margin-left: 30px;
    border-radius: 8px;
  }

  .countryDropdown {
    background-color: transparent;
    color: #fff;
    border: 1px solid transparent;
    color: white;
    border-radius: 8px;
    padding: 7px 15px;
    font-size: 14px;
    width: 190px;
    filter: brightness(0) invert(1);

    &:focus {
      box-shadow: none;
    }
  }

  .hembugmenu {
    display: none;
  }

  .infoheaderRight {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: right;
      align-items: center;

      li {
        margin-left: 30px;

        &.searchBox {
          img {
            height: 20px;
          }
        }

        &.notificationBox {
          img {
            height: 22px;
          }
        }

        :global(.dropdown) {
          :global(.dropdown-toggle) {
            padding: 0px;
            text-decoration: none;
            display: flex;
            align-items: center;
            white-space: normal !important;

            img {
              height: 40px;
              width: 40px;
              border-radius: 50px;
              object-fit: cover;
            }

            &::after {
              display: none;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }

            .menuTT {
              font-size: 13px;
              margin-left: 6px;
            }

            .arrow {
              margin-left: 10px;
            }
          }

          :global(.dropdown-menu) {
            background-color: #f3f4f9;
            border-color: #f3f4f9;
            color: #333;
            min-width: 200px;
            border-radius: 8px;
            margin-right: 2px !important;
            padding: 0px;
            box-shadow: 0px 4px 7px -5px #0000004d;

            :global(.dropdown-item) {
              color: #333;
              padding: 15px 15px;
              font-size: 14px;

              &.logout {
                color: var(--Main2);
                font-weight: 500;
                padding-top: 5px;
                padding-bottom: 10px;
              }

              &.borderM {
                border-bottom: 1px solid #d6d6d6;
                padding: 15px 15px;
              }

              &.settingM {
                padding-top: 5px;
                padding-bottom: 0;
                border: none;
              }


              &.pSpace {
                margin-top: 10px;
              }

              p {
                margin-bottom: 5px;
                font-size: 14px;
              }

              h5 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
              }

              &:hover,
              &:focus {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    // margin-top: 20px;

    .additionalMenu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: 13px;
      margin-left: 0px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;

        &.notification {
          a {
            img {
              height: 27px;
              width: 27px;
              filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(325deg) brightness(103%) contrast(101%);

            }
          }
        }

        :global(.publish_btn) {
          // background-image: linear-gradient(var(--Main2), var(--Main3));
          color: #fff;
          background: #7C7C7C;
          font-size: 15px;
          border-radius: 10px;
          border: none;
          margin: 0px 3px;

          &:active {
            border: none;
          }
        }

        :global(.dropdown) {
          :global(.dropdown-toggle) {
            padding: 0px;
            text-decoration: none;
            display: flex;
            align-items: center;
            white-space: normal !important;

            &:focus{
              border-color: transparent;
            }

            .fakeLogo {
              background-image: linear-gradient(#65beac, #3e8575);
              height: 35px;
              width: 35px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                color: #fff;

                text-transform: uppercase;
              }
            }

            &:active {
              border-color: transparent;
            }

            img {
              height: 35px;
              width: 35px;
              border-radius: 50px;
              object-fit: cover;
              border: 1px solid #dcdcdc;
            }

            &::after {
              display: none;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }

            .menuTT {
              font-size: 13px;
              margin-left: 6px;
            }

            .arrow {
              margin-left: 10px;
            }
          }

          :global(.dropdown-menu) {
            // background-color: #f3f4f9;
            background-color: #fff;
            border-color: #f3f4f9;
            color: #333;
            min-width: 200px;
            border-radius: 8px;
            margin-right: 2px !important;
            padding: 0px;
            box-shadow: 0px 4px 7px -5px #0000004d;
            // transform: translate3d(51px, 0px, 0px) !important;


            :global(.dropdown-item) {
              color: #333;
              padding: 15px 15px;
              font-size: 14px;

              &.logout {
                color: var(--Main2);
                font-weight: 500;
                padding-top: 5px;
                padding-bottom: 10px;
              }

              &.borderM {
                border-bottom: 1px solid #d6d6d6;
                padding: 15px 15px;
              }

              &.settingM {
                padding-top: 5px;
                padding-bottom: 0;
                border: none;
              }


              &.pSpace {
                margin-top: 10px;
              }

              p {
                margin-bottom: 5px;
                font-size: 14px;
              }

              h5 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
              }

              &:hover,
              &:focus {
                background: transparent;
              }
            }
          }
        }

        .signleMenu {
          border: none;
          background: transparent;

          .menuIcon {
            img {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
  .mainHeader {
    .brand {
      width: 100%;

      .countryDropdown {
        margin: 0 0 0 auto;
      }
    }

    .companyLogo {
      width: 50%;
      margin-top: 10px;
      display: flex;

      .logo {
        text-align: left;
      }
    }

    .infoheaderRight {
      margin-top: 10px;
    }

    .profileBox {
      :global(.dropdown) {
        :global(.dropdown-toggle) {
          img {
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .mainHeader {
    .brand {}

    .logo {
      width: 116px;

      img {
        height: 30px;
      }
    }

    .hembugmenu {
      background-image: linear-gradient(to top, var(--Main3), var(--Main2));
      color: #fff;
      padding: 0;
      border: none;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      border-radius: 5px;
      margin-right: 10px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .infoheaderRight {
      ul {
        li {
          margin-left: 20px;

          :global(.dropdown) {
            :global(.dropdown-toggle) {
              img {
                height: 35px;
                width: 35px;
              }
            }
          }
        }
      }
    }
  }
}