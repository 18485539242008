.rollTable {
  padding-left: 18px !important;
  padding-right: 0px !important;
  :global(.RollManagement_rollTable__VkZEv) {
    tr,
    th {
      vertical-align: top !important;
    }
  }

  .tableContainer {
    width: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    max-height: calc(100dvh - 226px);

    // &::-webkit-scrollbar {
    //   width: 8px;
    //   height: 4px;
    // }

    // &::-webkit-scrollbar-track {
    //   background: #fff;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #000;
    //   border-radius: 6px;
    // }

    // &* {
    //   scrollbar-width: thin;
    //   scrollbar-color: #000 #fff;
    // }
  }

  .table {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #e3edf0;
      height: 20px;
      z-index: -1;
    }

    tr {
      th {
        margin-right: 20px;
        color: #3c746d;
        font-weight: 600;
        font-size: 14px;
        min-width: 108px;

        &:first-child {
          min-width: 180px;
          max-width: 180px;
        }
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 12px;
      // white-space: nowrap;
      color: #212529;
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
}

@media (max-width: 720px) {
  th:nth-child(2) {
    width: 35% !important;

    padding-left: 0;
    padding-right: 0;
  }

  th:nth-child(4) {
    width: 30% !important;
    padding-left: 0;
    padding-right: 0;
  }

  th:nth-child(6) {
    width: 10% !important;
  }
}

@media (max-width: 500px) {
  th:nth-child(2) {
    width: 90% !important;
  }

  th:nth-child(4) {
    width: 100% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
