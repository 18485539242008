$color-1: #65beac;
$color-2: #3e8575;

.container {
  height: 40vh;
  overflow: hidden;
  // rlLoading.module.scss

  .rlLoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 80%;
  }

  .rlLoadingThumb {
    width: 6px;
    height: 38px;
    background-image: linear-gradient($color-1, $color-2);
    margin: 3px;
    border-radius: 3px;
    animation: rl-loading 1.5s ease-in-out infinite;

    &.thumb1 {
      animation-delay: 0s;
    }
    &.thumb2 {
      animation-delay: 0.2s;
    }
    &.thumb3 {
      animation-delay: 0.4s;
    }
    &.thumb4 {
      animation-delay: 0.6s;
    }
    &.thumb5 {
      animation-delay: 0.8s;
    }
    &.thumb6 {
      animation-delay: 1s;
    }
  }

  @keyframes rl-loading {
    20% {
      transform: scale(1.5);
    }
    40% {
      transform: scale(1);
    }
  }
}
