.businessWrapper {
    border-right: 1px solid #e6e5e5;
    margin: 0 auto;
    // max-width: 1920px;
    max-width: 4220px;
}
 

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/


@media (max-width:991px) {
    
  
     

}

@media only screen and (min-width:768px) and (max-width:991px) {
  
}