// navlink style

.teamLink {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.logoBox {
  margin: 15px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  h2 {
    position: absolute;
    bottom: 0;
    transform: translateY(70%);
    font-size: 18px;
    color: #7f8699;
  }

  .box {
    height: 150px;
    width: 150px;
    border-radius: 15px;
    background-color: #eff0f5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    .upload_img_remove {
      position: absolute;
      right: -8px;
      top: -10px;
      z-index: 9;
      border: none;
      background: none;
      padding: 0;
      line-height: 24px;

      :global(svg) {
        width: 20px;
        height: 20px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 50%;
        padding: 3px;
        color: #dd2828;
      }
    }

    &.v2 {
      background-color: #fff;
      border: 1px solid red solid #eff0f5;
    }

    input[type="file"] {
      display: none;
    }

    .boxImage {
      border-radius: 20px;
      width: 60px;
      height: 60px;
      object-fit: cover;
      fill: #7f8699;
    }

    .bottomLogo {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50%, 30%);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
      background-color: #fff;
      border-radius: 10px;
      height: 31px;
      width: 31px;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        img {
          transform: translate(0, -20%);
          cursor: pointer;
        }

        input {
          font-size: 2px;
          position: absolute;
          top: 50%;
          left: 0;
          opacity: 0;
        }
      }
    }
  }

  .upload {
    font-weight: 600;
    font-size: 18px;
    color: #7f8699;
  }

  .boxDiv {
    width: 100%;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
  }

  h4 {
    color: #7f8699;
    font-size: 14px;
    font-weight: 400;
  }
}

.uploadBox {
  // width:
  //   150;
  height: 150px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;

  img {
    background-position: center;
    object-fit: cover;
  }
}

.uploadImageBox {
  // border: 2px solid #eff0f5;
  background-color: #DCDCDC;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 160px;
  cursor: pointer;
  margin-bottom: 15px;

  span{
    background-color: #fff;
    box-shadow: 0px 3px 14px 0px rgba(29, 33, 30, 0.1019607843);
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -16px;
    right: -16px;
  }

  .cross {
    position: absolute;
    top: -16px;
    right: -16px;
    transform: translate(-10%, 10%);
  }

  .bgImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  label {
    .boxImage {
      width: 52.36px;
      height: 42.12px;
      cursor: pointer;
      fill: #777C88;
    }

    input {
      font-size: 4px;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  h2 {
    color: #7f8699;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    bottom: -40%;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.iconBox {
  margin: 10px 0 20px 0;
}

.locationSelect {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f5;
  padding: 7px 0;
  margin-top: 10px;

  .addLocation {
    min-width: 100%;
    gap: 5px;
  }

  // for business id

  .businessId {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    margin: 0;

    span {
      color: #7f8699;
    }
  }

  .changeView {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    :global(.filterDropdown) {
      padding: 2px 7px;
      margin: 0 9px;
      border-radius: 10px;
      background-color: #eff0f5 !important;

      :global(.show) {
        :global(.dropdown-menu) {
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 30%);
          border-radius: 10px;
          margin-top: 5px;
          padding: 10px 5px !important;

          a {
            font-size: 14px;
            font-weight: 400;
            color: #132326;
            margin-bottom: 5px;
          }
        }
      }
    }

    .addTeamBtn {
      min-width: 130px !important;
      display: flex;
      gap: 5px;
      margin-right: 10px;
    }

    .listImage {
      fill: var(--Main2);
      font-size: 28px;
      cursor: pointer;

      &.hide {
        display: none;
      }
    }
  }

  div {
    .showMobile {
      display: none;
    }

    .hideMobile {
      display: block;
    }

    img {
      height: 17.99px;
      width: 17.99px;
    }
  }

  &.borderNone {
    border: none;
  }

  .headingV2 {
    border-bottom: 2px solid #eff0f5;
    padding-bottom: 10px;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 13px;

    img {
      height: 17.99px;
      width: 17.99px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: var(--Main2);

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      color: inherit;

      :hover {
        text-decoration: underline;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  .smallText {
    color: #1ab192;
    font-size: 14px;
  }

  .editBtn {
    background: transparent;
    border: none;
    padding-left: 5px;
    padding-right: 5px;

    &:active {
      background: transparent;
      border: none;
    }

    img {
      height: 18px;
      width: 18px;
      margin-bottom: 5px;
    }
  }
}

.topboxHeading {
  h2 {
    font-size: 22px;
    border-bottom: 2px solid #eff0f5;
    font-weight: 600;
    padding-bottom: 10px;
  }

  p {
    color: #777c88;
    font-size: 15px;
    line-height: inherit;
  }

}

.tab_accordion_main {
  margin-bottom: 10px;

  .cart_box_design{
    background-color: #fff;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    padding: 20px 20px;

    h3{
      font-size: 16px;
      color: #1D211E;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 24px;
    }

    p{
      color: #777C88;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }

  .accordion_para{
    font-size: 15px;
    color: #4e4e4e;
    line-height: inherit;
  }


  :global(.accordion) {
    :global(.accordion-item) {
    

      :global(.accordion-header) {
        :global(.accordion-button) {
          // padding: 15px 0px;
          background-color: transparent;
          box-shadow: none;
          color: #212529;
          font-weight: 500;
          font-size: 17px;
          border-radius: inherit;

          &:global(.collapsed) {
            &::after {
              content: "+";
              transform: rotate(0);
            }
          }

          &::after {
            display: flex;
            align-items: center;
            font-size: 20px;
            content: "-";
            background-image: none !important;
            transform: rotate(0);
            transition: content 3s;
          }
        }
      }

      :global(.accordion-body) {
        // padding: 0px 0px;
        border-top: 1px solid gainsboro;
      }
    }
  }

}

.weekDays {
  .weekDaysList {
    margin: 0;
    padding: 0;
    list-style: none;

    // width: 60%;
    .weekDaysListItem {
      display: flex;
      align-items: center;

      // gap: 30px;
      .days {
        font-size: 14px;
        font-weight: 600;
        line-height: 18.27px;
        color: #7f8699;
        width: 35%;
      }

      .time {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        width: 65%;
      }
    }
  }
}

.timings {
  border-bottom: 2px solid #eff0f5;
  padding: 13px 0;

  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .items {
    display: flex;

    .from {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #7f8699;
      width: 15%;
      margin-bottom: 4px;
    }

    .time {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      width: 85%;
      margin-bottom: 4px;

      .to {
        color: #7f8699;
        font-weight: 500;
        font-size: 16px;
        margin: 0 10px;
      }
    }
  }
}

.content_Typemain {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    // align-items: center;

    .content_leftType {
      color: #7f8699;
      font-weight: 600;
      margin-bottom: 12px;
      font-size: 15px;
      width: 20%;
    }

    .content_rightType {
      margin-bottom: 12px;
      font-size: 15px;
      width: 80%;
    }
  }
}

.teamBox {
  background-color: #eff0f5;
  padding: 20px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  cursor: pointer;
  gap: 10px;
  box-shadow: 1px solid red 2px 3px #062e241a;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;

  .editBtn {
    position: absolute;
    top: 12px;
    right: 15px;
    height: 17px;
    width: 17px;
    cursor: pointer;
  }

  .deleteBtn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-30%, 30%);
  }

  &.active {
    border: 2px solid var(--Main2);
    box-shadow: none;

    &::after {
      content: "";
      position: absolute;
      height: 24px;
      width: 24px;
      top: 0;
      right: 0;
      background-image: url("../../../assets/images/Tick.svg");
      transform: translate(40%, -40%);
    }

    .left {
      span {
        color: var(--Main2);
      }

      img {
        filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg) brightness(87%) contrast(92%);
      }
    }
  }

  .left {
    background-color: #fff;
    max-height: 53px;
    max-width: 53px;
    padding: 15px 17px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    // width: 200px;
    // width: 100%;
    .memberName {
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      margin: 0;
      margin-bottom: 3px;
    }

    .occupation {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--Main2);
      margin: 0;
    }
  }
}

.mapBox {
  iframe {
    width: 95%;
    height: 178px;
  }
}

.service {
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: rgba(30, 140, 118, 10%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
}

.timeSelect {
  display: flex;
  gap: 10px;
  align-items: center;

  .emptyLabel {
    height: 20px;
  }
}

.dateBox {
  position: relative;

  label {
    width: 100%;
  }

  .dobInput {
    text-align: center;

    &::-webkit-calendar-picker-indicator {
      z-index: 10;
      opacity: 0;
    }
  }

  .scheduleIcon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 130%);
    z-index: 1;
  }
}

.addBranchLocation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;

  div {
    .branchIcon {
      margin: 0 auto;
      display: block;
      margin-bottom: 25px;
    }

    .branchHeading {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #7f8699;
      letter-spacing: 0.02px;
    }

    a {
      text-decoration: none;

      button {
        background-image: linear-gradient(var(--Main2), var(--Main3));
        color: #fff;
        border-radius: 10px;
        border: none;
        font-weight: 500;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin: 0 auto;
        margin-top: 20px;

        img {
          margin-right: 6px;
        }
      }
    }
  }
}

.priceOption {
  position: relative;

  // display: flex;
  // align-items: center;
  // gap: 7px;
  input {

    /* For Chrome, Safari, and Opera */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* For Firefox */
    -moz-appearance: textfield;
    appearance: textfield;
    padding: 0 15px !important;
  }

  .currency {
    position: absolute;
    top: 0;
    transform: translateY(0%);
    right: 0;
    padding: 0 20px;
    border: none;
    border-left: 2px solid #fff;
    font-weight: 500;
    font-size: 14px;
  }
}

.iconPos {
  position: relative;

  .bold {
    font-size: 20px;
    font-weight: 600;
    margin: 0
  }

  .about {
    width: 100%;

    img {
      width: 100% !important;
    }
  }

  .businessName {
    font-size: 18px;
  }

  .busenessDesc {
    font-size: 20px;
    margin: 0;
  }

  div {
    p {
      color: #7f8699;
      font-size: 14px;
    }

    input,
    select {
      padding-left: 40px !important;
    }
  }

  .icon {
    position: absolute;
    top: 30%;
    left: 10px;

    &.v2 {
      top: 25%;
    }

    &.v3 {
      top: 22%;
      left: 10px;
    }

    &.v4 {
      left: 10px;
    }

    &.v5 {
      left: 15px;
      top: 30%;
    }
  }

  // for non editable fields
}

.aboutInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #7f8699;
  }
}

.daySelector {
  display: flex;
  align-items: center;
  // gap: 10px;
}

.imageBox {
  height: 135px;
  width: 100%;
  background-color: #eff0f5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input[type="file"] {
    opacity: 0;
    display: none;
  }

  img {
    object-fit: cover;
  }

  span {
    text-align: center;
    display: block;
    font-size: 20px;
    color: rgba(0, 0, 0, 20%);
    font-weight: 600;
    line-height: 28px;
    position: relative;
    top: 10px;
  }
}

.contentBox {
  // height: 135px;
  width: 100%;
  background-color: #eff0f59b;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 15px;
  border-left: 10px solid #65BEAC;

  .left {
    width: 100%;

    .top {
      display: flex;
      gap: 2px;
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
        font-weight: 600;
      }

      span {
        background-color: #08ac8b;
        color: #fff;
        padding: 2px 10px;
        font-size: 13px;
        height: 25px;
        border-radius: 4px;
        margin: 0;
        background-image: linear-gradient(var(--Main2), var(--Main3));
        margin-left: 10px;
      }
    }

    .bottom {
      .top {
        display: flex;
        align-items: center;

        margin: 0;

        .empty {
          margin-left: 30px;
        }

        .branchLoc {
          width: 41%;
        }

        h3 {
          color: #7f8699;
          font-size: 14px;
          margin: 0;
          width: 50px;

        }

        .whatsAppHeading {
          width: 80px;
        }

        .location {
          width: 60px;
        }

        .locationDetail {
          word-spacing: 1px;
          font-size: 14px;
          margin-left: 10px;
        }

        p {
          font-size: 14px;
          margin: 0;
          margin-bottom: 0px;
        }
      }
    }
  }

  .right {
    .actionTable {
      display: flex;
      align-items: center;
      justify-content: space-around;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 5px;

      li {
        position: relative;
        cursor: pointer;

        p {
          margin: 0;
        }

        img:not(.insideImage) {
          height: 35px;
          width: 35px;

          &:hover {
            opacity: 0.8;
          }
        }

        .insideImage {
          position: absolute;
          top: 17%;
          left: 25%;
          height: 18px;
          width: 18px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.saveButtonTop{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: -61px;
  right: 0px;

  button{
    padding: 10px 15px;
    color: #fff !important;
    border-radius: 10px;
    background-color: #7C7C7C !important;
    border: none;
    font-size: 18px;
    font-weight: 600;
  }
}

.editorContainer {
  min-height: 200px;
  /* Initial minimum height */
  height: auto;
  // margin-bottom: 25px;
  margin-bottom: 5px;

  :global(.quill) {
    height: 150px;

    :global(.ql-toolbar) {
      background-color: #fff;
      border: 1px solid red solid #eff0f5;
      padding: 10px 10px;
      font-size: 14px;
      border-radius: 8px 8px 0 0;
      box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
    }

    :global(.ql-container) {
      background-color: #fff;
      border: 1px solid red solid #eff0f5;
      padding: 0px 3px;
      font-size: 14px;
      border-radius: 0 0 8px 8px;
      box-shadow: 0 1px solid red 3px rgba(0, 0, 0, 0.25);
    }
  }

}

.accountSettingHeading {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;

  h1 {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }

  span {
    padding: 4px 13px 4px 15px;
    font-weight: 600;
    font-size: 14px;
    color: #1e8c76;
    background-color: #eff0f5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 15%);
    border-radius: 5px;
  }
}

.priceOption {
  position: relative;

  input {

    /* For Chrome, Safari, and Opera */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* For Firefox */
    -moz-appearance: textfield;
    appearance: textfield;
    padding: 0 80px 0 30px !important;
  }

  .currency,
  .duration {
    position: absolute;
    top: 0;
    transform: translateY(190%);
    right: 0;
    padding: 0 15px;
    border: none;
    border-left: 2px solid #fff;
    font-weight: 500;
    font-size: 14px;
  }

  .duration {
    width: 40%;
    background-color: transparent !important;
    box-shadow: none;
    border-radius: inherit;

    &:focus {
      border: none;
      border-left: 2px solid #fff;
    }
  }
}

.setting {
  display: flex;
  align-items: center;

  .businessName {
    font-size: 18px;
  }

  label {
    // width: 80px;
    margin-right: 10px;
  }

  .taxRate {
    display: flex;
    gap: 5px;
    align-items: center;
    gap: 7px;

    p {
      font-weight: 600;
    }

    img {
      cursor: pointer;
      height: 16px;
      width: 16px;
      margin-bottom: 5px;
    }
  }

  .editSetting {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;

    &.v1 {
      flex-direction: column;
    }

    button {
      font-weight: 600;
      padding: 0px 15px;
      font-size: 14px;
      border-radius: 8px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #fff;
      background-image: linear-gradient(var(--Main2), var(--Main3));

      border: none;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1490196078);
    }

    .percent {
      position: absolute;
      // top: 0;
      right: 12px;
      opacity: 0.7;
    }
  }
}

// for category icons list


.businessCat {
  color: #7f8699;
  font-weight: 600 !important;
  margin-bottom: 12px;
  font-size: 15px !important;
  width: 20%;
}

.busines_category_itmes {
  width: 80%;

  :global(.owl-carousel) {
    :global(.owl-item) {
      margin-right: 6px !important;
    }

    :global(.owl-nav) {
      text-align: end;

      :global(button) {

        &:focus-visible {
          outline: transparent;
        }

        &:hover {
          background-color: transparent;

          // &:global(.disabled){
          //   color: #919496;
          // }
        }

        :global(span) {
          background-color: #b8f1de;
          padding: 0px 11px;
          font-size: 24px;
          border-radius: 6px;
        }
      }
    }
  }

  ul {
    li {
      h6 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.icons {
  overflow-x: auto;
  width: 100%;
  margin-top: 0px;
  padding-top: 2px;
  padding-bottom: 4px;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(#65BEAC, #3E8575);
    border-radius: 50px;
  }

  li {
    div {
      position: relative;

      .deleteCross {
        position: absolute;
        top: 0;
        right: 0;
        height: 18px;
        width: 18px;
        filter: none !important;
      }
    }
  }

  .addCat {
    img {
      height: 20px;
      width: 20px;
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 980px) {
  .teamBox {
    padding: 23px 10px;
  }

  .teamSelect {
    flex-direction: column;

    .changeView {
      margin-top: 10px;
    }
  }


  .timings {
    .items {
      .from {
        width: 35%;
      }

      .time {
        width: 65%;
      }
    }
  }

  .contentBox {
    padding: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;

    .left {
      .top {
        align-items: baseline;
        margin-bottom: 12px;

        h2 {
          font-size: 16px;
        }

        span {
          height: 22px;
          font-size: 11px;
        }
      }

      .bottom {
        .top {
          display: flex;
          align-items: baseline;
          gap: 25px;
          margin-bottom: 10px;


          p {
            font-size: 13px;
            margin-bottom: 0;
            line-height: 15px;
          }
        }
      }
    }

    .right {
      .actionTable {
        flex-direction: column;
      }
    }
  }

  .content_Typemain {
    margin-bottom: 20px;

    ul {
      .content_leftType {
        width: 32%;
      }

      .content_rightType {
        width: 68%;
      }
    }
  }

  .editorContainer {
    margin-bottom: 7px;

    :global(.quill) {
      height: 100px;
    }
  }

  .businessCat {
    width: 33%;
  }

  .busines_category_itmes {
    width: 67%;
  }
}

@media (max-width: 480px) {
  .contentBox {
    .left {
      .bottom {
        .top {
          p {
            width: 140px;
          }
        }
      }
    }
  }

  .businessHeader {
    flex-direction: column;
  }

  .imageBox {
    margin-top: 10px;
  }
}

@media (max-width: 720px) {
  .editorContainer {
    margin-bottom: 0px !important;

    :global(.quill) {
      height: 130px;
    }
  }

  .locationSelect {
    div {
      .showMobile {
        display: block;
      }

      .hideMobile {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .locationSelect {
    .gallery {
      margin-top: 50px;
    }
  }

  .mapBox {
    iframe {
      width: 100%;
      height: 178px;
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 1199px) {
  .imageBox {
    img {
      width: 100%;
    }
  }
}