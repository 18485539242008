:global(.MuiDataGrid-panel) {

  div,
  label,
  span {
    font-family: "Poppins" !important;
    font-size: 14px;
  }

  :global(.Mui-focused) {
    color: var(--Main3) !important;
  }

  :global(.MuiInputBase-root) {
    &:after {
      border-bottom: 2px solid var(--Main3);
    }
  }

  :global(.MuiSwitch-switchBase) {
    &:global(.Mui-checked) {
      :global(.MuiSwitch-thumb) {
        color: var(--Main3);
      }
    }
  }

  :global(.MuiSwitch-track) {
    background-image: linear-gradient(var(--Main2), var(--Main3));
  }

  :global(.MuiButton-root) {
    color: var(--Main);
  }
}

.dataTable {

  div,
  label,
  span {
    font-family: "Poppins" !important;
  }

  :global(.MuiDataGrid-toolbarContainer) {
    padding: 0px 0px;
    background: transparent;
    border-bottom: none;
    margin-bottom: 12px;

    :global(.MuiButtonBase-root) {
      color: #7f8699;
      margin: 0px;
      padding: 2px 10px;
      background: #fff;
      margin-right: 8px;
      font-family: "Poppins" !important;
      font-weight: normal;
      font-size: 13px;

      :global(.MuiButton-startIcon) {
        :global(.MuiSvgIcon-root) {
          font-size: 18px;
        }
      }

      &:nth-child(4) {
        display: none;
      }
    }

    :global(.MuiFormControl-root) {
      background: #fff;
      // padding: 10px 10px;
      padding: 6px 8px;
      border-radius: 6px;
      box-shadow: 1px 0px 3px rgba(6, 46, 36, 0.1019);
      border: 1px solid #e5e5e5;

      :global(.MuiInput-root) {

        &::after,
        &::before {
          display: none;
        }

        svg {
          color: var(--Main3);
        }

        input {
          padding: 0px 0px;
          margin-left: 5px;
          font-size: 14px;
        }

        :global(.MuiIconButton-root) {
          display: none;
        }
      }
    }
  }

  :global(.MuiTablePagination-selectLabel) {
    margin-bottom: 4px !important;
  }

  :global(.MuiTablePagination-displayedRows) {
    margin-bottom: 0px !important;
  }

  :global(.MuiDataGrid-cellCheckbox) {

    &:focus,
    &:focus-within {
      outline: none !important;
    }
  }

  :global(.MuiCheckbox-root) {
    :global(.MuiSvgIcon-root) {
      color: #9b9a9a;
      font-size: 23px;
      border-radius: 10px;
    }
  }

  :global(.MuiDataGrid-root) {
    display: block;
    border: none;

    :global(.MuiDataGrid-main) {
      :global(.MuiDataGrid-columnHeaders) {
        background-color: #fff;
        border-radius: 0px;
        border: none;
        min-height: 30px !important;
        max-height: 30px !important;
        line-height: 30px !important;

        :global(.MuiDataGrid-columnHeadersInner) {
          :global(.MuiDataGrid-columnHeader) {
            font-family: "Poppins" !important;

            &:focus,
            &:focus-within {
              outline: none;
            }

            :global(.MuiDataGrid-columnHeaderTitleContainerContent) {
              height: 30px !important;

              :global(.MuiDataGrid-columnHeaderTitle) {
                height: 30px !important;
                line-height: 30px;
                color: #7f8699;
              }
            }
          }
        }
      }

      :global(.MuiDataGrid-virtualScroller) {
        min-height: 1px;
        // max-height: calc(100vh - 277px);
        max-height: calc(100dvh - 355px);
        overflow: auto !important;

        :global(.MuiDataGrid-virtualScrollerRenderZone) {
          :global(.MuiDataGrid-row) {
            font-family: "Poppins" !important;

            :global(.MuiDataGrid-cell) {
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    :global(.MuiDataGrid-footerContainer) {
      // display: none;
    }
  }
}

.actionTable {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    :global(.btn) {
      width: 30px;
      height: 30px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-right: 10px;
      margin-right: 3px;
      border: none;
      margin-bottom: 0px;
    }
  }
}

:global(.css-5wly58-MuiDataGrid-root) {

  :global(.MuiDataGrid-columnHeader:focus-within),
  :global(.MuiDataGrid-cell:focus-within) {
    outline: transparent !important;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dataTable {
    :global(.MuiDataGrid-toolbarContainer) {
      :global(.MuiFormControl-root) {
        margin-top: 10px;
      }
    }

    // :global(.MuiDataGrid-root) {
    //   :global(.MuiDataGrid-virtualScroller) {
    //     max-height: calc(100vh - 310px) !important;
    //   }
    // }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tableAction {
    :global(.btn) {
      font-size: 12px;
      margin-right: 8px;
    }

    .dropMenu {
      width: 180px;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 1099px) and (min-height: 500px) and (max-height: 1100px) {
  // .dataTable {
  //   :global(.MuiDataGrid-root) {
  //     :global(.MuiDataGrid-main) {
  //       :global(.MuiDataGrid-virtualScroller) {
  //         max-height: calc(100vh - 470px);
  //       }
  //     }
  //   }
  // }
}