// .businessFooter {
//     background: #EFF0F5;
//     padding: 20px;
//     margin-left: 225px;
// }
.add {
    border: 2px solid rgba(26, 177, 147);
    opacity: 1;
  img {
    height: 24px;
    width: 24px; 
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
}
