/// table style

//for removing border on header

.DnDCalendar {
  margin-top: 1px;

  :global(.custom-event) {
    width: 100% !important;
    left: 0% !important;
  }

  // calendar toolbar
  .toolbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 8px;
    display: none !important;

    .availabilityBox {
      margin: 0;
      width: 15%;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.219);
      border-radius: 50px;
      box-shadow: none;
      overflow: hidden;

      select {
        border: none;
        height: 40px;
        text-align: center;
        font-size: 13px;
        background-color: #fff;
        cursor: pointer;
        background-position: 93% 50%;
      }
    }

    .dateBox {
      width: 38%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.219);
      border-radius: 50px;

      button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        height: 40px;
        transition: background 3s;

        &:hover {
          color: #fff;
          background-image: linear-gradient(#65beac, #3e8575);
        }
      }

      .dateInput {
        width: 50%;
        margin: 0;

        input {
          background-color: #fff;
          border-radius: 50px;
          height: 40px;
          text-align: center;
          box-shadow: none;
          font-size: 13px;
          margin: 0;
          border: none;
        }
      }
    }

    .dayBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 17%;

      .availabilityBox {
        width: 100%;
        margin: 0;

        select {
          background-color: #fff;
          border-radius: 50px;
          height: 40px;
          text-align: center;
          box-shadow: none;
          font-size: 14px;
          cursor: pointer;
        }
      }

      button {
        display: flex;
        gap: 7px;
        min-width: 20px;
        font-size: 14px;
        height: 40px;
        width: 101px !important;
      }

      .addIcon {
        height: 20px;
        width: 20px;
      }
    }
  }

  :global(.rbc-time-view) {
    border: none !important;
  }

  :global(.rbc-time-header-content) {
    // min-width: 150px !important;
    border: none !important;
    padding: 0;
    // max-height: 100px;

    :global(.rbc-allday-cell) {
      display: none !important;
    }
  }

  :global(.rbc-day-slot) {
    min-width: 180px !important;
    z-index: 1;

    // :global(.rbc-timeslot-group){
    //   position: relative;
    //   background-color: #f7fafa;
    //     &::after{
    //       content: "Available";
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //       color: #127d68;
    //       font-size: 14px;
    //       font-weight: 500;

    //     }
    // }
  }

  :global(.rbc-time-content) {
    border: none !important;

    :nth-child(3) {
      :global(.rbc-day-slot) {
        background-color: red !important;
      }
    }

    :global(.rbc-time-gutter) {
      border: none;
      z-index: 5;

      :global(.rbc-timeslot-group) {
        border: none;
        border-right: 1px solid #ddd;
        border-top: 1px solid #cacbcd;

        :global(.rbc-time-slot) {
          // min-height: 50px !important;
          min-width: 59.8px;
          z-index: 1;
          background-color: #eff0f5;
          display: flex;
          align-items: flex-start;

          // border: 1px solid #eff0f5;
          :global(.rbc-label) {
            color: #7f8699;
            font-size: 12px;
          }
        }
      }
    }
  }

  :global(.rbc-event-content) {
    z-index: 100;
  }

  :global(.rbc-timeslot-group) {
    min-height: 100px !important;
    background-color: #fbfbfc;
  }

  :global(.rbc-addons-dnd-resizable) {
    position: relative;

    :global(.rbc-event-label) {
      // position: absolute;
      display: none !important;
      // background-image: url('../../../assets//images/clockGreen.svg');
      // background-repeat: no-repeat;
      // margin-right: 10px;
    }
  }

  :global(.rbc-event-label) {
    display: none !important;
  }

  :global(.rbc-events-container) {
    margin: 0 !important;

    :global(.rbc-event-unassign) {
      background-color: #eff2f5 !important;
      border: 1px solid #c8cacc78 !important;
      color: black !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      z-index: 20 !important;
      border-radius: 0px;
      padding: 0 !important;

      &:hover {
        z-index: 100 !important;
      }

      :global(.rbc-addons-dnd-resizable) {
        // padding: 10px !important;
        padding: 10px 5px;
      }
    }

    :global(.rbc-event) {
      background-color: #eff2f5 !important;
      border: 1px solid #c8cacc78 !important;
      color: black !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      z-index: 20 !important;
      border-radius: 0px;
      padding: 0 !important;

      transition: all 0.3s;

      &::-webkit-scrollbar {
        width: 5px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: black;
      }

      &::-moz-scrollbar {
        width: 5px;
        height: 4px;
      }

      &::-moz-scrollbar-thumb {
        background-color: black;
      }

      &:hover {
        z-index: 100 !important;
      }

      :global(.rbc-addons-dnd-resizable) {
        // padding: 10px !important;
        padding: 10px 5px;
      }
    }
  }

  :global(.rbc-header) {
    padding: 0 !important;
    border: none !important;
    min-width: 181px;
  }

  // :global(.rbc-row) {
  //   height: 100% !important;
  //   // width: 100%;
  //   border: none !important;
  // }

  :global(.rbc-time-header.rbc-overflowing) {
    border-right: none !important;

    :global(.rbc-label) {
      background-color: #eff0f5 !important;
      border: none !important;
      min-width: 59px;
      z-index: 5;
      border-bottom: 1px solid #dddddd !important;
      border-right: 1px solid #dddddd !important;
      border-top: 1px solid #dddddd !important;
    }
  }

  :global(.rbc-toolbar) {
    background-color: #eff0f5 !important;
    display: none !important;
  }
}

.grayColor {
  background-color: #f6f7f9;
}

.specialDay {
  background-color: #ffff;
  z-index: 1;

  &:hover {
    background-color: #e8f9f7;
  }
}

.filtering {
  opacity: 0.5;
}

.calendartoolbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
  width: 95%;
  margin: 0 0 0 auto;
  margin-bottom: 9px;

  // display: none !important;
  &.listToolBox {
    margin: 0 auto 8px auto;
    width: 97.5%;
  }

  .firstBox {
    // width: 30%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    align-items: center;
    gap: 10px;

    &.calendar {
      // width: 18%;

      .availabilityBox {
        // width: 75%;|
        // width: auto;
        min-width: 100px;
      }
    }

    .availabilityBox {
      margin: 0;
      // width: 45%;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.219);
      border-radius: 8px;
      box-shadow: none;
      overflow: hidden;

      select {
        border: none;
        height: 31px;
        text-align: center;
        font-size: 13px;
        background-color: #fff;
        cursor: pointer;
        padding-right: 21px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
    }

    .view {
      margin: 0;
      list-style: none;
      padding: 0;

      li {
        // background-image: linear-gradient(#65beacf7, #3e8575);
        background: #7c7c7c;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        padding: 8px 9px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        img {
          height: 14px;
        }
      }
    }
  }

  .dateBox {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.219);
    border-radius: 50px;

    button {
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      height: 40px;
      transition: background 3s;

      &:hover {
        color: #fff;
        background-image: linear-gradient(#65beac, #3e8575);
      }
    }

    .dateInput {
      width: 50%;
      margin: 0;

      input {
        background-color: #fff;
        border-radius: 50px;
        height: 40px;
        text-align: center;
        box-shadow: none;
        font-size: 13px;
        margin: 0;
        border: none;
      }
    }
  }

  .dayBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    // width: 225px;
    // margin-right: 28px;
    justify-content: center !important;

    .availabilityBox {
      width: 101px;
      margin: 0;

      select {
        background-color: #fff;
        border-radius: 50px;
        height: 31px;
        text-align: center;
        box-shadow: none;
        // font-size: 14px;
        cursor: pointer;
      }
    }

    button {
      display: flex;
      gap: 7px;
      min-width: 20px;
      font-size: 14px;
      height: 40px;
      width: 101px !important;
    }

    .addIcon {
      height: 20px;
      width: 20px;
    }
  }

  .calendarBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      background-color: #fff;
      border-radius: 8px;
      height: 31px;
      text-align: center;
      box-shadow: none;
      font-size: 13px;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.219);
      width: 80px;
      color: #212529;

      &.active {
        border: none;
        // background-image: linear-gradient(#65beac, #3e8575);
        background: #7c7c7c;
        color: #fff;
      }
    }

    // gap: 1px;
    button {
      border: none;
      background-color: transparent;

      .left {
        font-size: 26px;
        transition: all 0.3s;
        color: #212529;

        &:active {
          height: 25px;
        }
      }
    }
  }

  .add_reportingMain_sec {
    display: flex;
    gap: 12px;

    .add_reporting_box {
      margin-bottom: 0px;
      // min-width: 94px;

      :global(.form-label) {
        font-size: 12px;
        white-space: nowrap;
        margin-bottom: 3px;
      }

      :global(.form-control) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        background-color: #fff;
        border-radius: 8px;
        height: 36px;
        box-shadow: none;
        border: 1px solid gainsboro;
        line-height: 29px;
        padding: 3px 7px;
        font-size: 14px;
      }
    }
  }
}

// team list style

.teamBox {
  height: 100%;
  display: flex;
  gap: 8px;
  padding: 13px 10px;
  align-items: flex-start;
  border: 1px solid #dddddd;
  border-bottom: none;
  // background-color: #fbfbfb;
  background-color: #ecebeb;
  justify-content: center;

  // padding-bottom: 20px;

  &.teamAvailablityBox {
    height: 86px;
    align-items: center;
  }

  &.Unassigned {
    // background-image: linear-gradient(var(--Main2), var(--Main3));
    // border-radius: 27px 0 0 0;
    height: 86px;
    transform: translate(0px, 0 \px);
    align-items: center;
    padding-bottom: 0px;

    .contentBox {
      width: 100%;
      display: flex;
      padding: 0;
      justify-content: center;

      .label {
        color: #525c62;
        font-weight: 600;
        font-size: 13px;
        // text-align: center;
        text-wrap: wrap;
        // margin-top: 10px;
        display: flex;
        align-items: flex-start;
        text-align: center;
        gap: 6px;
        justify-content: center;

        &.title {
          font-size: 10px;
          color: #176757;
        }

        .icon {
          margin-top: 4px;
          height: 13px;
          width: 13px;
        }
      }
    }
  }

  .imageBox {
    display: flex;
    align-items: center;
    border-radius: 50%;
    height: 45px;
    min-width: 45px;
    width: 45px;
    overflow: hidden;

    &.emotyBox {
      background-image: linear-gradient(var(--Main2), var(--Main3));
      display: flex;
      align-items: center;
      justify-content: center;

      .logoFake {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left !important;
    margin-bottom: 5px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    // display: -webkit-box;
    -webkit-box-orient: vertical;

    p {
      margin: 0;

      &.label {
        font-weight: 600;
        font-size: 13px;
        color: #525c62;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        width: 100%;
      }

      &.title {
        font-size: 12px;
        color: #176757;
      }

      &.availablity {
        font-weight: 400;
        font-size: 11px;
        color: #1e8c76;
      }

      &.notAvailable {
        font-weight: 400;
        font-size: 11px;
        color: rgb(233, 34, 24);
      }
    }
  }
}

.guestUser_input_Main {
  padding-top: 8px;
  padding-bottom: 1px;
  display: flex;
  gap: 20px;
  border-top: 1px solid #d1d1d1;
  margin-top: 4px;

  .guestUser_input_box {
    display: flex;
    margin-bottom: 6px;
    align-items: center;
    font-weight: 500;
    color: #3e3e3e;

    label {
      text-wrap: nowrap;
      white-space: nowrap;
      width: 62px;
      padding-right: 6px;
      font-size: 13px;
    }

    :global(.form-control) {
      font-size: 14px;
      height: 30px;
      max-width: 150px;
      background-color: #fdfdfd;

      &:focus {
        box-shadow: none;
        border-color: #1ab192;
      }
    }
  }
}
.eventContainer {
  display: flex;
  height: 100%;
  .eventContainerBorder{
    height: 100%;
    width: 10px;
    &.bookingBorder {
      background: #538f31;
      border-radius: 6px 0px 0px 6px;
    }
  
    &.unassignBorder {
      background-color: #1b979e;
      border-radius: 6px 0px 0px 6px;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
}
.events {
  height: 100%;
  width: 100%;
  padding: 10px;
  background-color: #e8f9f7;
  overflow-y: auto;
  position: relative;

  &.noPadding {
    padding: 0;
  }

  &.events_b2c {
    background-color: #efffecfa;
  }

  &.noShow {
    &::after {
      content: "No Show";
      position: absolute;
      top: 0;
      right: 0;
      background: #f99398;
      color: #fff;
      font-size: 10px;
      padding: 3px 5px;
    }
  }

  &.cancelled {
    &::after {
      content: "Cancelled";
      position: absolute;
      top: 0;
      right: 0;
      background-color: #e88a0e;
      color: #fff;
      font-size: 10px;
      padding: 3px 5px;
    }
  }

  &.completed {
    position: relative;

    &::after {
      content: "Completed";
      position: absolute;
      top: 0;
      right: 0;
      background-image: linear-gradient(#65beac, #3e8575);
      color: #fff;
      font-size: 10px;
      padding: 3px 5px;
    }
  }

  &.cancel {
    position: relative;

    &::after {
      content: "Completed";
      position: absolute;
      top: 0;
      right: 0;
      background: #fdeaeb;
      color: black;
      font-size: 10px;
      padding: 3px 5px;
    }
  }

  // / For WebKit browsers (Chrome, Safari) /
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  // / For Internet Explorer /
  &::-ms-scrollbar {
    width: 2px;
  }

  &::-ms-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-ms-scrollbar-thumb {
    background-color: #888;
  }

  // border-radius: 8px;
  &.unavailableBox {
    background-color: #e8f0f7;
    box-shadow: none;
    position: relative;
    border-radius: 0;
    cursor: auto;

    .compareBox {
      display: flex;
      align-items: center;
      width: 100%;
      transition: 0.3s all;

      .allTeamClount {
        background-color: #e8f0f7;
      }

      .teamCount {
        background-color: #fff;
        cursor: pointer;

        &.allTeamClount {
          background-color: #f6f7f9;
        }
      }

      .allTeamClount,
      .teamCount {
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }

    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      background-color: #f7fafa;
      height: 0px;
      width: 0;
    }
  }

  &.emptyBoxes {
    background-color: #ebeced;
    box-shadow: none;
    position: relative;
    border-radius: 0;
    cursor: auto;
    z-index: 1;
  }

  .teamTitle {
    font-weight: 600;
    font-size: 14px;
    color: black;
    margin: 0;
    margin-bottom: 6px;
    letter-spacing: 0.08px;

    &.unavailable {
      color: #7f8699;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-weight: 500;
      font-size: 12px;
    }

    .teamperson_id {
      font-size: 11px;
      font-weight: 500;
      color: #3e8575;
    }
  }

  .services {
    font-weight: 400;
    font-size: 15px;
    color: #525c62;
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 8px;

    img {
      height: 14px;
      width: 16px;
      pointer-events: none;
    }

    span {
      font-weight: 400;
      font-size: 11px;
      color: #525c62;
      pointer-events: none;
    }
  }

  .assignContainer {
    display: flex;
    z-index: 2;

    .assignBtn {
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      background-image: linear-gradient(#65beacf7, #3e8575);
      box-shadow: 0 1px 3px 0 #00000040;
      padding: 8px 15px;
      border: none;
      border-radius: 6px;
      margin: 0 0 0 auto;
    }
  }
}

// .bodyTable {
//   // padding-top: 0 !important;
//   // padding-right: 0 !important;
//   // padding-bottom: 0 !important;
//   // overflow: hidden;
// }

// .listTable{
//   :global(.MuiDataGrid-columnHeader){
//     // background-image: linear-gradient(#65BEAC,#3E8575);
//     // color: #fff !important;
//     // padding: 100px,0 !important;
//     // :global(.MuiDataGrid-columnHeaderTitle){
//     //   color: #fff !important;
//     // }

//   }
// }
.timeBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .date {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 2px;
  }

  .time {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
}

.serviceCatList {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #d7eaf0;
  border-radius: 7px;
  padding: 8px;
  height: 80px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
  }

  &::-moz-scrollbar {
    width: 5px;
    height: 4px;
  }

  &::-moz-scrollbar-thumb {
    background-color: black;
  }

  .listItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }

    .categoryName {
      color: #176757;
      background-color: #fff;
      padding: 3px 8px;
      border-radius: 5px;
      margin: 0 !important;
      font-size: 12px;

      .bold {
        font-weight: 700px;
        font-size: 13px;
      }

      &.v2 {
        color: #fff !important;
        background-image: linear-gradient(#1e8c76, #0c9b7e);
        // padding: 3px 10px;
        border-radius: 5px;
        margin: 0 auto;
        display: block;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.client {
  .action {
    display: flex;
    gap: 10px;
    align-items: center;

    .imageBox {
      background-color: #eff0f5;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      a {
        margin-bottom: 2px;
      }

      span {
        display: block;
      }
    }
  }
}

//////

.rightBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .statusBar {
    margin: 0;

    select {
      border: none;
      height: 40px;
      text-align: center;
      font-size: 13px;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.219);
      border-radius: 50px;
      box-shadow: none;
    }
  }
}

.addButton {
  display: flex;
  gap: 7px;
  min-width: 20px;
  font-size: 14px;
  height: 40px;
  width: 135px;
}

.addIcon {
  height: 20px;
  width: 20px;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    gap: 10px;

    .statusBar {
      margin: 0;
      min-width: 160px;

      select {
        border: none;
        height: 40px;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.219);
        border-radius: 50px;
        box-shadow: none;
      }
    }

    .buttons {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      padding: 0;
      background-color: #fff;
      padding: 3px;
      border-radius: 10px;
      border: 1px solid #0000001a;
      gap: 10px;

      li {
        button {
          border: none;
          border-radius: 8px;
          background-color: transparent;
          padding: 7px 20px;
          font-size: 12px;
          text-align: center;
          color: #1e8c76;
          font-weight: 500;
          transition: background-image 0.3s ease-in-out;

          &.active {
            background-image: linear-gradient(#65beac, #3e8575);
            color: #fff;

            font-weight: 600;
          }
        }
      }
    }

    .view {
      margin: 0;
      list-style: none;
      padding: 0;

      li {
        background-image: linear-gradient(#65beacf7, #3e8575);
        border: none;
        border-radius: 10px;
        padding: 6px 10px;
        cursor: pointer;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1px 3px;
  border-radius: 8px;
  gap: 8px;
  height: 32px;

  .todayButton {
    height: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    // background-image: linear-gradient(#65beac, #3e8575);
    background-color: #7c7c7c !important;
    border: none;
    color: #fff;
    width: 90px;
    font-size: 12px;
    font-weight: 600;

    &.inActive {
      // background-image: none;
      color: #3e8575;
      background-color: transparent !important;
    }
  }

  span {
    width: 130px;
    font-size: 14px;
    text-align: center;
  }

  .dateSelector {
    border: none;
    width: 130px;
    font-size: 14px;
    height: 31px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

// list View
.listViewMargin {
  margin-left: 15px;
  margin-right: 15px;
}

.rollTable {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  // / Style the scrollbar track /
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  // / Style the scrollbar thumb /
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  // / Style the scrollbar on hover /
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  padding: 0px !important;
  z-index: -2;
  padding: 0;

  &::before {
    background-image: none !important;
  }

  .tableContainer {
    // overflow: scroll !important;
    min-height: calc(100dvh - 130px);
    max-height: calc(100dvh - 130px);

    .table {
      margin: 0;
      position: relative;

      .emptyBooking {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 400%);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  :global(.RollManagement_rollTable__VkZEv) {
    tr,
    th {
      vertical-align: top !important;
    }
  }

  :global() .table {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // background-color: #e3edf0;
      background-image: linear-gradient(#65beac, #3e8575);
      height: 20px;
      z-index: -1;
    }

    tr {
      td {
        // padding: 25px 40px 15px 40px;
        padding: 20px 6px 15px 6px;
        min-width: 100px;

        &.noShow {
          background: #fdeaeb;
          border-bottom: 2px solid #fff;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          font-weight: 400;
          font-size: 15px;
        }

        p {
          margin: 0;
        }

        .date {
          font-weight: 400;
          font-size: 17px;
        }

        .time {
          font-weight: 700;
          font-size: 14px;
        }

        .client {
          font-weight: 700;
          font-size: 14px;
        }

        .stylist {
          font-weight: 700;
          font-size: 14px;

          &.unAssigned {
            color: #1ab192;
            cursor: pointer;
          }
        }

        .action {
          display: flex;
          gap: 10px;
          align-items: center;

          .imageBox {
            background-color: #eff0f5;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            a {
              margin-bottom: 2px;
            }

            span {
              display: block;
            }
          }
        }

        .dotDropdown {
          position: absolute;

          :global(.dropdown) {
            position: absolute;
            top: 100%;

            :global(.dropdown-toggle) {
              .dots {
                position: absolute;
                top: 0px !important;
                cursor: pointer;
              }
            }

            :global(.btn) {
              background-color: transparent;
              border: none;

              &::after {
                display: none;
              }
            }

            .dotDropInside {
              display: flex;
              gap: 10px;
              align-items: center;
              margin: 0;
            }
          }

          .correctImage {
            height: 20px;
            width: 20px;
            opacity: 0.6;
          }
        }
      }

      th {
        margin-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        // background-color: #e3edf0;
        background-image: linear-gradient(#65beac, #3e8575);
        min-width: 100px;
        // padding: 12px 40px;
        padding: 12px 6px;
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }
}

.height600 {
  position: relative;
  height: 600px;
}

.wrapper {
  position: relative;
  overflow-x: hidden;
  padding-top: 14px;

  .title {
    width: 95%;
    margin: 0 0 0 auto;
  }
}

// booking sidebar style
.bookingSidebar {
  box-shadow: 0 4px 8px 0 rgba(13, 22, 25, 0.0196078431),
    0 12px 20px 0 rgba(13, 22, 25, 0.1607843137);
  position: fixed;
  top: 0;
  right: 0;
  width: 42%;
  height: 100dvh;
  background: #fff;
  z-index: 500;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &.v2 {
    z-index: 1000;
  }

  .cross {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-125%, 17px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 4px 8px 0 #0d161905, 0 12px 20px 0 #0d161929;
    line-height: 0;
    cursor: pointer;

    img {
      height: 18px;
      width: 18px;
    }
  }

  &.show {
    transform: translateX(0%);
    pointer-events: all;
  }

  .mainheading_flexArea {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    margin-top: 24px;
    margin-bottom: 24px;

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      margin-top: 5px;
      margin-left: 6px;
    }
  }

  .mainHeading {
    word-wrap: break-word;
    min-width: 0;
    max-width: 100%;
    transition: color 200ms ease-in-out;
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0 22px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: linear-gradient(#65beac, #3e8575);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    padding-right: 0;

    .leftArr {
      font-size: 24px;
      cursor: pointer;
      color: #0c9b7e;
    }
  }

  .subNames {
    padding-left: 22px;
    margin-bottom: 0;
    font-size: 18px;
    margin-top: 7px;
  }

  .inside {
    overflow-y: auto;
    height: 400px;
    height: calc(100dvh - 210px);
    padding: 0px 22px;

    &.v2 {
      // height: 470px
      height: calc(100dvh - 115px);
    }

    .topbox {
      .selectClientBox {
        display: flex;
        border: 1px solid #bfbfbf;
        padding: 15px 20px;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.noBorder {
          border: none;
          padding: 0;
          margin-bottom: 18px;
        }

        .left {
          display: flex;
          align-items: center;
          gap: 20px;

          img {
            height: 65px;
            width: 65px;
          }

          .userInfo {
            h4,
            h3 {
              font-weight: 400;
              font-size: 15px;
              color: #7f8699;
              margin: 0;
              margin-bottom: 5px;
            }

            h3 {
              color: #132326;
            }

            p {
              font-weight: 600;
              font-size: 15px;
              color: #0c9b7e;
              margin: 0;
            }
          }
        }

        .right {
          img {
            cursor: pointer;
          }
        }
      }
    }

    .selectedClient {
      background-color: #eff0f5;
      padding: 15px 20px;
      border-radius: 8px;
      margin: 15px 0;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          gap: 15px;
          width: 100%;
          align-items: center;

          img {
            height: 65px;
            width: 65px;
          }

          p {
            color: #1e8c76;
            margin: 0;
            margin-bottom: 6px;
            font-weight: 400;
            font-size: 13px;
            margin: 0;
          }

          .userInfo {
            display: flex;
            justify-content: space-between;
            gap: 19px;
            margin-bottom: 0px;
            padding-top: 1px;

            h4 {
              color: #132326;
              font-weight: 600;
              font-size: 15px;
              margin: 0;
              margin-top: 3px;
              margin-bottom: 3px;
            }

            p {
              color: #1e8c76;
              margin: 0;
              margin-bottom: 6px;
              font-weight: 400;
              font-size: 13px;
              margin: 0;
            }
          }
        }

        .right {
          a {
            color: #7f8699;
            font-weight: 500;
            font-size: 16px;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .bottom {
        .tags {
          padding: 5px 15px 5px 15px;
          border-radius: 20px;
          background-color: rgba(184, 220, 216, 0.454);
          font-weight: 400;
          font-size: 16px;
          color: #1e8c76;
          margin-top: 14px;
          display: inline-block;
        }
      }
    }

    .middleBox {
      margin-top: 17px;
      // margin-bottom: 80px;

      .addservice {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 12px;

        .dateBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .date {
            font-size: 15px;
            font-weight: 500;
            margin: 0;
          }

          .error {
            color: red;
            font-size: 13px;
            margin: 0;
          }

          .desc {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            color: gray;
          }
        }

        .editBtn {
          background-color: #fff;
          border-radius: 5px;
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          display: inline-block;
          border: 1px solid gray;
          padding: 7px 10px;
          line-height: 1;
          box-shadow: none;
          transition: background-color 300ms ease, border 300ms;

          &:hover {
            border: 1px solid black;
          }
        }
      }

      .serviceInput {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #bfbfbf;
        padding: 10px 20px;
        border-radius: 10px;
        margin-top: 10px;
        cursor: pointer;

        p {
          margin: 0;
        }

        span {
          display: block;
          font-size: 30px;
          margin: 0;
        }
      }

      .services {
        .bundleScreen {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h5 {
            font-size: 18px;
            margin: 0;
          }

          span {
            white-space: nowrap;
            font-size: 18px;
            font-weight: 600;
            color: #1ab192;
            margin: 0;
          }

          .bundlePromotion {
          }

          .deleteAction {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              cursor: pointer;
            }
          }
        }

        .serviceBox {
          margin-top: 10px;
          border: 1px solid #bfbfbf;
          padding: 12px 20px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .info {
            width: 95%;

            .serviceName {
              font-weight: 400;
              margin: 0;
              font-size: 15px;
              margin-bottom: 6px;
            }

            .duration {
              font-weight: 400;
              margin: 0;
              font-size: 14px;
              color: #7f8699;
              display: flex;
              align-items: center;
              gap: 5px;

              span {
                img {
                  height: 12px;
                  width: 12px;
                  cursor: pointer;
                }
              }
            }

            .bookingInputBox {
              width: 95%;
              gap: 14px;
              display: flex;
              margin-bottom: 2px;

              select {
                background-color: #fff;
                box-shadow: none;
                width: 100%;
                padding: 0px 12px;
                height: 35px;
                font-size: 13px;
                margin-top: 4px;

                &.error {
                  border: 1px solid red;
                }
              }
            }

            .packRadeem {
              margin-top: 10px;

              .checkPlace {
                padding-left: 30px;

                .check {
                  top: 3px;
                }

                .text {
                  font-size: 13px;
                  color: #7f8699;
                }
              }
            }
          }

          .deleteAction {
            height: 100%;
            display: flex;
            align-items: center;

            img {
              // height: 34px;
              // width: 34px;
              cursor: pointer;
            }
          }
        }
      }

      .addAnother {
        margin-top: 10px;
        margin-bottom: 5px;
        color: #3e8575;
        font-size: 15px;
        font-weight: 500;

        span {
          cursor: pointer;
        }
      }
    }
  }

  .bottomBox {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 15px 32px 20px 32px;
    border-top: 1px solid rgba(128, 128, 128, 0.637);
    width: 100%;
    height: 130px;

    .total {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5,
      p {
        font-weight: 600;
        font-size: 18px;
        margin: 0;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 13px;

      button {
        width: 48%;
        border-radius: 8px;
        padding: 10px;
        border: none;
        box-shadow: 0px 4px 4px 0px #00000026;

        &.submitBtn {
          background-color: none;
          background-image: linear-gradient(#65beac, #3e8575);
          color: #fff;

          &.disabled {
            opacity: 0.8;
          }
        }
      }
    }
  }

  // client bar style
  .searchBox {
    position: relative;

    :global(.form-control) {
      background-color: #e9f6f1;
      border-color: #e9f6f1;
      height: 55px;
      border-radius: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 46px;
      color: #1ab192;
      font-weight: 400;
      font-size: 16px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: #1ab192;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 5px;
      color: #1ab192;
      transform: translate(50%, -50%);
      font-size: 22px;
    }
  }

  .addClientLink {
    color: #7f8699;
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
    width: 100%;

    span {
      color: #65beac;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.089);
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .addClient {
    display: flex;
    border: 2px solid #b8dcd8;
    padding: 20px;
    border-radius: 20px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;

    &.noBorder {
      border: none;
      padding: 0;
      margin-bottom: 18px;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 20px;

      .userInfo {
        h4,
        h3 {
          font-weight: 400;
          font-size: 16px;
          color: #7f8699;
          margin: 0;
          margin-bottom: 5px;
        }

        h3 {
          color: #132326;
        }

        p {
          font-weight: 600;
          font-size: 16px;
          color: #0c9b7e;
          margin: 0;
        }
      }
    }

    .right {
      img {
        cursor: pointer;
      }
    }
  }

  .selectedClient {
    background-color: #eff0f5;
    padding: 20px;
    border-radius: 8px;
    margin: 15px 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        gap: 15px;

        .userInfo {
          h4 {
            color: #132326;
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            margin-top: 5px;
            margin-bottom: 3px;
          }

          p {
            color: #1e8c76;
            margin: 0;
            margin-bottom: 6px;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .right {
        a {
          color: #7f8699;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .bottom {
      .tags {
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        background-color: rgba(184, 220, 216, 0.454);
        font-weight: 400;
        font-size: 16px;
        color: #1e8c76;
        margin-top: 14px;
        display: inline-block;
      }
    }
  }

  // service bar style

  .addServices_accordianMain {
    :global(.accordion) {
      :global(.accordion-item) {
        border: none;

        :global(.accordion-header) {
          :global(.accordion-button) {
            padding: 15px 0px;
            background-color: transparent;
            box-shadow: none;
            border-bottom: 1px solid gainsboro;
            color: #212529;
            border-radius: inherit;

            &:global(.collapsed) {
              &::after {
                content: "+";
                transform: rotate(0);
              }
            }

            &::after {
              display: flex;
              align-items: center;
              font-size: 20px;
              content: "-";
              background-image: none !important;
              transform: rotate(0);
              transition: content 3s;
            }
          }
        }

        :global(.accordion-body) {
          padding: 0px 0px;
        }
      }
    }
  }

  .serviceList {
    .catHeader {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      align-items: center;
      width: 95%;
    }

    .insideAccordion {
      padding: 0 20px;

      .catHeader {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        align-items: center;
        width: 93%;
      }
    }

    .catHeading {
      // padding: 10px 0px;
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 600;
      // border-bottom: 1px solid #d1d1d1;

      &.insideHead {
        font-size: 16px;
        margin: 0;
      }

      span {
        color: #7f8699;
        font-size: 14px;
        margin-left: 5px;
        font-weight: 400;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        width: 100%;
        background: #eff0f5;
        border-radius: 10px;
        padding: 15px 15px;
        margin-bottom: 10px;
        margin-top: 14px;

        .bodyLeft {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .category_selected {
            .serviceDesc {
              display: flex;
              align-items: center;
              gap: 10px;

              h5 {
                font-size: 15px;
                color: #000;
                margin-bottom: 2px;
                // min-width: 130px;
              }

              h4 {
                font-size: 15px;
                color: #000;
                margin-bottom: 2px;
                min-width: 130px;
              }
            }

            h5 {
              font-size: 15px;
              color: #000;
              margin-bottom: 2px;
            }
          }

          .price_time {
            display: flex;
            gap: 10px;
            margin-right: 14px;
            text-align: end;

            h5 {
              font-size: 15px;
              color: #000;
              margin-bottom: 2px;
              min-width: 89px;
            }
          }

          .list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              padding: 0;
              margin: 0px;
              color: #7f8699;
              font-size: 14px;
            }
          }

          h5 {
            margin-bottom: 4px;
            font-size: 16px;
          }

          p {
            margin: 0px;
            color: #7f8699;
            font-size: 14px;
          }
        }

        .bodyRight {
          display: flex;
          justify-content: right;
          width: max-content;
          align-items: center;

          &.v2 {
            gap: 10px;

            .price {
              display: flex;
              flex-direction: column;

              .correct {
                font-weight: 700;
                font-size: 16px;
                color: #1e8c76;
              }

              .cancel {
                color: #ff4848;
                text-decoration: line-through;
                font-weight: 500;
              }
            }
          }

          .btnGroup {
            display: flex;
            box-shadow: 0 0 2px #b0b0b0;
            border-radius: 5px;
            align-items: center;

            :global(.btn) {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              color: #7f8699;
              border: none;
            }
          }

          .signleBtn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: #7f8699;
            border: none;
            box-shadow: 0 0 2px #b0b0b0;
            border-radius: 5px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .discount_charges_accordian {
    margin-top: 26px;

    :global(.accordion) {
      :global(.accordion-item) {
        border: var(--bs-accordion-border-width) solid #bfbfbf;
      }

      :global(.accordion-button) {
        padding: 19px 21px !important;

        &:not(.collapsed) {
          background-color: #c7fcef4a;
          color: #285d51;
          z-index: 0;

          &::after {
            filter: invert(47%) sepia(13%) saturate(1305%) hue-rotate(116deg)
              brightness(94%) contrast(94%);
          }
        }

        &:global(.collapsed) {
          background-color: transparent;
          color: #212529;

          &::after {
            filter: invert(0%) sepia(94%) saturate(7500%) hue-rotate(64deg)
              brightness(95%) contrast(102%);
          }
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    :global(.accordion-item:first-of-type) {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }

    :global(.accordion-item:first-of-type) {
      :global(.accordion-button) {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
      }
    }

    :global(.accordion-item:last-of-type) {
      :global(.accordion-button.collapsed) {
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }

    :global(.accordion-item:last-of-type) {
      border-bottom-right-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }

  .new_appoint_discount_box {
    // margin-top: 80px;

    .new_appoint_discountDiv {
      margin-bottom: 6px;

      h4 {
        font-size: 18px;
        font-weight: 600;
        border-bottom: 3px solid #eff0f5;
        padding-bottom: 8px;
        padding-top: 10px;
        margin-bottom: 2px;
      }

      .input_left,
      .right_left {
        label {
          color: #181818;
          font-weight: 600;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 4px;
        }
      }

      .input_left {
        :global(.form-control),
        :global(.form-select) {
          background-color: #eff0f5;
          border: 1px solid #eff0f5;
          font-size: 15px;
          border-radius: 6px;
          height: 38px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          color: #0e0e0e;

          &:focus {
            box-shadow: none;
            background-color: #fff;
            border: 1px solid var(--Main2);
          }
        }
      }

      .right_left {
        :global(.form-control) {
          background-color: #eff0f5;
          border: 1px solid #eff0f5;
          font-size: 15px;
          border-radius: 6px;
          height: 38px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          color: #0e0e0e;

          &:focus {
            box-shadow: none;
            background-color: #fff;
            border: 1px solid var(--Main2);
          }
        }
      }

      .line_withTotal_amt {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .line {
          width: 100%;
          border-top: 2px solid #e3edf0;
        }

        .total_amt {
          // text-wrap: nowrap;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          color: #1ab192;
          margin-left: 12px;

          .total_time {
            color: #132326;
          }
        }
      }
    }
  }
}

.reloadBooking_btn {
  :global(.btn) {
    // background-image: linear-gradient(rgba(101, 190, 172, 0.968627451),
    //     #3e8575);
    background: #7c7c7c;
    border: transparent;
    color: #fff;
    padding: 0px 8px;
    font-size: 17px;
    border: 8px;

    &:active {
      background: #7c7c7c !important;
      border: transparent;
      color: #fff !important;
    }
  }
}

.calendy_todayBookHrs_main {
  width: 100%;
  background-color: #fff;
  margin: 0 0 0 auto;

  ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
    gap: 7px;
    padding: 7px 0px;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 0px;

    li {
      padding-right: 0px;

      .left {
        font-size: 14px;
        font-weight: 500;
      }

      .right {
        font-size: 13px;
        color: #1e6a58;
      }

      &:first-child {
        :global(svg) {
          color: red;
        }
      }

      &:nth-child(2) {
        :global(svg) {
          color: green;
        }
      }

      &:nth-child(3) {
        :global(svg) {
          color: blue;
        }
      }

      &:nth-child(4) {
        :global(svg) {
          color: yellow;
        }
      }

      &:nth-child(5) {
        :global(svg) {
          color: skyblue;
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .borderLine {
    border-bottom: none;
  }

  .bookingSidebar {
    width: 65%;
  }
}

@media (max-width: 640px) {
  .bookingSidebar {
    width: 100%;
  }
}

@media (max-width: 468px) {
  .calendartoolbox {
    flex-direction: column;
    margin: 0;
    width: 100%;
    gap: 8px;

    .firstBox {
      width: 100%;

      button {
        height: 32px;
        font-size: 12px;

        .addIcon {
          height: 16px;
          width: 16px;
        }
      }

      .availabilityBox {
        select {
          padding: 0;
          height: 32px;
          font-size: 12px;
        }
      }

      .view {
        li {
          padding: 3px 8px;

          img {
            height: 13px;
            width: 14px;
          }
        }
      }
    }

    .calendarBox {
      button {
        .left {
          font-size: 22px;
        }
      }

      .buttonBox {
        height: 30px;
        display: flex;
        align-items: center;

        button {
          // padding :4px 0;
          height: 97%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input {
          height: 97%;
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1099px) {
  // .bookingSidebar {
  //   .inside {
  //     height: calc(100vh - 320px);
  //   }

  //   .bottomBox {
  //     bottom: 80px;

  //     .btns {
  //       margin-bottom: 3px;
  //     }
  //   }
  // }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bookingSidebar {
    width: 52%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1099px) and (min-height: 500px) and (max-height: 1100px) {
  .calendartoolbox {
    width: 99%;
  }
}
