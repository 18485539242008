.customTooltipBox {
    :global(.tooltip-inner) {
        max-width: 300px;
    }
    .customTooltipContent{
        line-height: normal;
        margin-bottom: 4px;
    }
}

.customTooltipClick {
    display: inline-block;
    line-height: 23px;

    :global(.btns) {
        // font-weight: 700;
        line-height: 16px;
        padding: 0;
        border: 1px solid transparent;
        cursor: pointer;
        // border: 1px solid gainsboro;
        // box-shadow: rgba(0, 0, 0, 0.16) 1px 0px 3px;
        // width: 21px;
        // height: 21px;
        // display: inline-flex;
        // justify-content: center;
        // align-items: center;
        // border-radius: 50%;
        // font-size: 15px;
        // color: #4e4d4d;
        // line-height: normal;

        &:active{
            border-color: transparent;
        }

        :global(svg){
            font-size: 19px;
            color: #51a291;
        }
    }
}