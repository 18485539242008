@import "variables.module.scss";
@import "fonts.module.scss";

:global(.errorMessage) {
  color: red !important;
  font-size: 13px;
  margin: 2px 0px 0px 2px;
  margin-top: 2px;
  position: relative;
}

:global(.out) {
  position: absolute;
  bottom: 0;
  transform: translateY(20px);
}

:global(.up) {
  transform: translateY(-85px);
}

:global(.v2) {
  width: 95%;
  margin: 0 auto;
  transform: translateY(-13px);
}

body {
  // background-color: #eff0f5 !important;
  background-color: #fbfbfb !important;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;
  width: 100%;
  height: 100%;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  font-family: "Poppins" !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
}

:global(.form-control)::placeholder {
  color: #bfbfbf;
}

:global(.form-control):-ms-input-placeholder {
  color: #bfbfbf;
}

:global(.form-control)::-ms-input-placeholder {
  color: #bfbfbf;
}

// services css
:global(.btn) {
  &.addBlue {
    color: #fff;
    background-color: #508aaa;
    font-size: 14px;
    border-radius: 54px;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    font-weight: 500;
    border-color: transparent;
    text-align: center;
    justify-content: center;
    height: 37px;

    &:hover,
    &:active,
    &:focus {
      color: #fff !important;
      background-color: #508aaa !important;
    }
  }
}

select:required:invalid {
  // color: #bfbfbf !important;
  color: #212529 !important;
}

option {
  color: black;
}

a {
  &:hover {
    color: var(--Main2);
  }
}

:global(.errorBorder) {
  border: 1px solid red;
}

:global(.grayout) {
  opacity: 0.7;
}

.switch {
  position: relative;
  vertical-align: top;
  // width: 100px;
  width: 90px;
  height: 27px;
  background: #fff;
  cursor: pointer;
  box-sizing: content-box;

  &:focus {
    outline: transparent;
    border-color: transparent;
  }
}

.buttonflexDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .threeButton {
    background-color: #fff;
    border-radius: 12px;
    margin: 0;

    li {
      padding: 3px;
      margin: 0 !important;

      .btn {
        color: #509f8e;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;

        border: none;
        border-radius: 12px;
        padding: 10px 30px;

        &.active {
          background-image: linear-gradient(#65beac, #3e8575) !important;
          color: #fff !important;
        }
      }
    }
  }
}

.switchInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
}

.switchLabel {
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  background-image: linear-gradient(#eceeef, #eceeef);
  box-sizing: content-box;
  border: 1px solid #e4dcdc;
  overflow: hidden;
  border-radius: 5px;
}

.switchLabel:before,
.switchLabel:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing: content-box;
}

.switchLabel:before {
  content: attr(data-off);
  right: 5px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switchLabel:after {
  content: attr(data-on);
  left: 11px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.switchInput:checked~.switchLabel {
  background-image: linear-gradient(var(--Main2), var(--Main3));
}

.switchInput:checked~.switchLabel:before {
  opacity: 0;
}

.switchInput:checked~.switchLabel:after {
  opacity: 1;
}

.switchHandle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 23px;
  height: 23px;
  background: #d8d8d8;
  border-radius: 5px;
}

.switchHandle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #d8d8d8;
  border-radius: 6px;
}

.switchInput:checked~.switchHandle {
  left: 64px;
  background: #fff;
}

.switchInput:checked~.switchHandle:before {
  background: #fff;
}

/* Transition
========================== */
.switchLabel,
.switchHandle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

// on-off css start

.onOff_switch {
  width: 60px;

  .switchLabel {
    border-radius: 30px;
    background-image: linear-gradient(98.3deg,
        rgb(163 4 4) 10.6%,
        rgb(197 22 22) 97.7%);
    border: none;
  }

  .switchHandle {
    border-radius: 50%;
    top: 3px;
    width: 21px;
    height: 21px;
  }

  .switchLabel:before {
    content: "Off";
    color: #fff;
    right: 10px;
  }

  .switchLabel:after {
    content: "On";
  }

  .switchInput:checked~.switchHandle {
    left: 36px;
  }
}

// on-off css end

.radio {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 7px 0px 0px 40px;

  &:hover input~.checkmark {
    background-color: #f3f1f1;
    border: 1px solid #f3f1f1;
  }

  input {
    opacity: 0;
    margin-right: 5px;
    position: absolute;

    &:checked~.checkmark:after {
      background-image: linear-gradient(var(--Main2), var(--Main3));
    }

    &:checked~.radio {
      font-weight: 800;
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 28px;
    width: 28px;
    background-image: linear-gradient(to right, #f3f4f9, #f3f4f9);
    border-radius: 50%;
    border: 1px solid #eee;
    box-shadow: -4px -2px 4px 0px #fff, 4px 2px 8px 0px #d1d9e6;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      transform: translate(-50%, -50%);
      height: 20px;
      border-radius: 50%;
      background: white;
      background-image: linear-gradient(to right, #ffffff, #dae0ee);
      box-shadow: -4px -2px 4px 0px #d1d9e6, 4px 2px 8px 0px #fff;
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 24px;

  &:hover input~.checkmark {
    background-color: #fff;
    border: 1px solid var(--Main3);
  }

  input {
    opacity: 0;
    margin-right: 10px;
    position: absolute;

    &:checked~.checkmark {
      border: transparent;
      background-image: linear-gradient(var(--Main2), var(--Main3));
    }

    &:checked~.checkmark:after {
      display: block;
    }
  }

  &:hover input~.checkmark {
    &.grayCheck {
      background-color: #e9e9e9;
    }

    // border: 1px solid var(--Main3);
  }

  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdcdc;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.grayCheck {
      background-color: #e9e9e9;

      &:after {
        border: solid #857e7e;
        border-width: 0 2px 2px 0;
      }
    }
  }
}

// Define custom variables for colors and dimensions
$muiSwitchWidth: 48px;
$muiSwitchThumbSize: 20px;
$muiSwitchThumbTranslate: $muiSwitchWidth - $muiSwitchThumbSize - 2;

.greenSwitch {
  position: relative;
  width: 52px;
  height: 28px;
  padding: 5px 9px;
  box-shadow: 1.2075471878051758px 1.2075471878051758px 3px 0px rgba(74, 81, 91, 45%);
  border-radius: 100px;
  cursor: pointer;
  overflow: hidden;

  .toggleInput {
    display: none;
  }

  .toggleLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // Styles for the thumb (the circular part) of the switch
  .toggleThumb {
    position: absolute;
    top: 4px;
    left: 4px;
    padding: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }

  .toggleInput:checked~.toggleThumb {
    transform: translateX($muiSwitchThumbTranslate ); // Move the thumb to the right when checked
  }
}

.listUl {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 3px;

  li {
    margin-right: 20px;
  }
}

.iconSelectList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 3px;

  li {
    display: inline-block;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    max-width: 60px;
    min-width: 60px;

    .iconImg {
      border: 1px solid #d5d7dd;
      background: #eff0f5;
      width: 60px;
      text-align: center;
      height: 60px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 30px;
      }
    }

    h6 {
      font-size: 13px;
      color: #7f8699;
      margin: 5px 0px 0px 0px;
      font-weight: 400;
    }

    &.active {
      width: 60px;
      min-width: 60px;

      .iconImg {
        border: 1px solid #1ab192;
        background: #e9f5f1;

        img {
          filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg) brightness(87%) contrast(92%);
        }
      }

      h6 {
        color: var(--Main3);
        font-weight: 600;
        font-size: 12px;
        // word-wrap: break-word;
      }
    }
  }
}

.titleBox {
  .title {
    font-size: 30px;
    font-weight: 600;
  }
}

.sectionPadding {
  padding: 80px 0px;
}

.sectionPaddingBottom {
  padding-bottom: 80px;
}

.formBox {
  width: 100%;
  margin-bottom: 15px;

  :global(.form-label) {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 600;

    .required {
      color: red;
    }
  }

  :global(.form-control) {
    // background-color: #eff0f5;
    // border: 1px solid #eff0f5;
    // padding: 3px 13px;
    // font-size: 14px;
    // border-radius: 6px;
    // height: 38px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 25%);
    // use for date input (ipad devices)
    // display: inline-flex;
    // align-items: center;

    background-color: #ffffff;
    padding: 3px 13px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    height: 38px;

    &:focus {
      box-shadow: none;
      background-color: #fff;
      border: 1px solid var(--Main2);
    }

    &.removeArrow {

      &::-webkit-outer-spin-butto,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  :global(.form-select) {
    background-color: #eff0f5;
    border: 1px solid #eff0f5;
    // padding: 10px 13px;
    // font-size: 14px;
    // height: 45px;
    padding: 3px 13px;
    font-size: 14px;
    height: 38px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 25%);
    background-position: 96% 50%;

    &:focus {
      box-shadow: none;
      border: 1px solid #fbbf15;
    }

    &.removeArrow {

      &::-webkit-outer-spin-butto,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .eyeIcon {
    position: absolute;
    right: 3px;
    // top: 24px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #686868;
    cursor: pointer;
  }

  &.iconFormBox {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(0%, -50%);
      width: 20px;
      text-align: center;
      height: 100%;
      color: #3d4755;
    }

    .unseenIcon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0%, -50%);
      width: 20px;
      text-align: center;
      height: 100%;
      color: #3d4755;
      cursor: pointer;
    }

    :global(.form-control) {
      // padding-left: 40px;
      border: 1px solid #dcdcdc;
      background: #fff;
      box-shadow: none;

      &:focus {
        box-shadow: none;
        background-color: #fff;
        border: 1px solid var(--Main3);
      }
    }
  }

  .btnsGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    :global(.btn) {
      font-weight: 600;
      padding: 0px 15px;
      font-size: 16px;
      border-radius: 8px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      min-width: 200px;
      margin: 0px 10px;
      border: none;
      box-shadow: 0px 4px 4px 0px #00000026;

      &.btn2 {
        background: #7f8699;
        color: #fff;
      }

      &.btn1 {
        color: #7f8699;
        background: #eff0f5;
      }

      &.btn3 {
        color: #fff;
        background-image: linear-gradient(var(--Main2), var(--Main3));
        width: 100%;
        margin: 0px;
        height: 45px;
      }

      &.btn4 {
        // color: #fff;
        // background-image: linear-gradient(var(--Main2), var(--Main3));
        height: 47px;
        background: #7c7c7c;
        background-image: none !important;
        box-shadow: none !important;
        color: #fff;
        border-radius: 9px !important;
      }
    }
  }

  .telBox {
    :global(.PhoneInput) {
      padding-left: 40px;
      border: 1px solid #7f8699;
      background: #fff;
      box-shadow: none;
      padding: 10px 15px;
      font-size: 14px;
      border-radius: 6px;
      height: 40px;

      :global(.PhoneInputInput) {
        width: 100% !important;
        height: auto !important;
        background: transparent;
        border: none;

        &:focus-visible {
          border: none;
          box-shadow: none;
          outline: none;
        }

        &:focus {
          border: none;
        }
      }

      :global(.flag-dropdown) {
        background: transparent !important;
        border: none !important;

        &:global(.open) {
          background: transparent;
        }

        :global(.selected-flag) {
          width: 60px;
          padding-left: 12px;
          background: transparent !important;

          &:hover,
          &:focus {
            background: transparent;
          }

          &:global(.open) {
            background: transparent;
          }
        }

        :global(.country-list) {
          :global(.country) {
            span {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .phoneBox {
    :global(.react-tel-input) {
      :global(.form-control) {
        width: 100% !important;
        padding-left: 45px;
        height: auto !important;
        border-radius: 8px !important;
        background: #eff0f5;
        border: none;

        &:focus {
          border: none;
        }
      }

      :global(.flag-dropdown) {
        background: transparent !important;
        border: none !important;

        &:global(.open) {
          background: transparent;
        }

        :global(.selected-flag) {
          width: 60px;
          padding-left: 12px;
          background: transparent !important;

          &:hover,
          &:focus {
            background: transparent;
          }

          &:global(.open) {
            background: transparent;
          }
        }

        :global(.country-list) {
          :global(.country) {
            span {
              display: inline-block;
            }
          }
        }
      }
    }

    :global(.PhoneInput) {
      background-color: #eff0f5;
      border: 1px solid #eff0f5;
      padding: 0 15px;
      font-size: 14px;
      border-radius: 8px;
      height: 38px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

      :global(.PhoneInputInput) {
        width: 100% !important;
        height: auto !important;
        // background: #eff0f5;
        background-color: transparent;
        border: none;

        &:focus-visible {
          border: none;
          box-shadow: none;
          outline: none;
        }

        &:focus {
          border: none;
        }
      }

      :global(.flag-dropdown) {
        background: transparent !important;
        border: none !important;

        &:global(.open) {
          background: transparent;
        }

        :global(.selected-flag) {
          width: 60px;
          padding-left: 12px;
          background: transparent !important;

          &:hover,
          &:focus {
            background: transparent;
          }

          &:global(.open) {
            background: transparent;
          }
        }

        :global(.country-list) {
          :global(.country) {
            span {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &.formBox_new {
    max-width: 210px;
    margin-bottom: 0px;

    .phoneBox {
      :global(.PhoneInput) {
        background-color: #ffffff;
        border: 1px solid #dcdcdc;
        border-radius: 9px;
        box-shadow: none;
        height: 38px;

        :global(.PhoneInputInput) {
          font-size: 13px;

          &::placeholder {
            color: #222;
          }
        }
      }
    }
  }

  .booking {
    :global(.css-b62m3t-container) {
      :global(.css-1jqq78o-placeholder) {
        color: #65beac;
      }

      :global(.css-13cymwt-control),
      :global(.css-t3ipsp-control) {
        background-color: #fff;
        border: 1px solid #fff;
        padding: 3px 5px;
        font-size: 14px;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        min-height: 42px;
        height: auto;

        :global(.css-1hb7zxy-IndicatorsContainer) {
          :global(.css-1u9des2-indicatorSeparator) {
            display: none;
          }

          :global(.css-1xc3v61-indicatorContainer) {
            color: #4e4e4e;
          }
        }

        :global(.css-3w2yfm-ValueContainer),
        :global(.css-1dyz3mf) {
          :global(.css-1p3m7a8-multiValue) {
            background-image: linear-gradient(var(--Main2), var(--Main3));
            color: #fff;

            :global(.css-wsp0cs-MultiValueGeneric),
            :global(.css-9jq23d) {
              color: #fff;
            }

            :global(.css-12a83d4-MultiValueRemove),
            :global(.css-v7duua) {
              background: transparent;
              color: #fff;
            }
          }
        }
      }

      :global(.css-t3ipsp-control) {
        box-shadow: none;
        border-color: #86b7fe;
      }
    }
  }

  .multiSelbookingect_whiteBg {

    :global(.css-13cymwt-control),
    :global(.css-t3ipsp-control) {
      background-color: #fff !important;
    }
  }

  &.selectbooking_whiteBg {
    :global(.form-select) {
      background-color: #fff !important;
    }
  }

  :global(.css-b62m3t-container) {
    z-index: 11;

    // :global(.css-1nmdiq5-menu){
    //   position: relative !important;
    // }

    :global(.css-13cymwt-control),
    :global(.css-t3ipsp-control) {
      background-color: #eff0f5;
      border: 1px solid #dee2e6;
      padding: 0px 5px;
      font-size: 14px;
      border-radius: 9px;
      // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

      :global(.css-1hb7zxy-IndicatorsContainer) {
        :global(.css-1u9des2-indicatorSeparator) {
          display: none;
        }

        :global(.css-1xc3v61-indicatorContainer),
        :global(.css-15lsz6c-indicatorContainer) {
          color: #4e4e4e;
          padding-right: 3px;
        }
      }

      :global(.css-3w2yfm-ValueContainer),
      :global(.css-1dyz3mf) {
        :global(.css-1p3m7a8-multiValue) {
          background-image: linear-gradient(var(--Main2), var(--Main3));
          color: #fff;

          :global(.css-wsp0cs-MultiValueGeneric),
          :global(.css-9jq23d) {
            color: #fff;
          }

          :global(.css-12a83d4-MultiValueRemove),
          :global(.css-v7duua) {
            background: transparent;
            color: #fff;
          }
        }
      }
    }

    :global(.css-t3ipsp-control) {
      box-shadow: none;
      border-color: #86b7fe;
    }
  }
}

.formSignGroup {
  // gap: 16px;

  .btnGray {
    background: #7c7c7c;
    background-image: none !important;
    box-shadow: none !important;
    color: #fff;
    border-radius: 9px !important;
    min-width: auto;

    &:hover,
    &:active {
      background: #7c7c7c !important;
      color: #fff !important;
    }

    &.btnGrayLight {
      background: #f1f1f1;
      color: #7f8699 !important;

      &:hover,
      &:active {
        background: #f1f1f1 !important;
        color: #7f8699 !important;
      }
    }
  }
}

:global(.singleBtn) {
  font-weight: 600;
  padding: 0px 15px;
  font-size: 14px;
  border-radius: 8px;
  color: #fff;
  // height: 45px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 250px;
  border: none;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 25%);
  background-image: linear-gradient(var(--Main2), var(--Main3));
}

:global(.react-tel-input) {
  :global(.form-control) {
    width: 100% !important;
    padding-left: 70px;
    height: auto !important;
    border-radius: 8px !important;

    &:focus {
      border: 1px solid #ccc;
    }
  }

  :global(.flag-dropdown) {
    background: transparent !important;
    border: none !important;

    &:global(.open) {
      background: transparent;
    }

    :global(.selected-flag) {
      width: 60px;
      padding-left: 12px;
      background: transparent !important;

      &:hover,
      &:focus {
        background: transparent;
      }

      &:global(.open) {
        background: transparent;
      }
    }

    :global(.country-list) {
      :global(.country) {
        span {
          display: inline-block;
        }
      }
    }
  }
}

.editBtn {
  border: none;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 10px;
  z-index: 10;
  transform: translate(-40%, -10%);
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 0.8;
  }
}

.font {
  font-family: "Poppins" !important;
}

.pageWrapper {
  margin-left: 85px;
  padding: 25px 25px 32px 25px;
  transition: all 0.3s;
  padding-top: 68px !important;

  .tablePadding_shadow {
    padding: 20px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    border-radius: 6px;
  }

  // &.maxWidth_pageWrapper {
  //   max-width: 975px;
  //   margin: auto;
  // }

  &.pageWrapperWebsite {
    margin-left: 0;
  }

  .pageTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleInfo {
      display: flex;
      width: 100%;

      h5 {
        margin: 0px;
        font-size: 19px;
        color: #525c62;
      }

      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #1d211e;
        margin-bottom: 10px;
      }

      .icon {
        margin-right: 10px;
      }
    }

    .rightInfo {
      width: 100%;
      display: flex;
      justify-content: end;

      .searchBox {
        position: relative;
        width: 300px;

        :global(.form-control) {
          padding: 10px 15px;
          font-size: 14px;
          box-shadow: 1px 2px 3px #062e241a;
          border-radius: 10px;
          border: none;
          height: 45px;
        }

        .searchBtn {
          position: absolute;
          right: 2px;
          top: 50%;
          background: transparent;
          border: none;
          padding: 0px;
          transform: translate(-50%, -50%);

          img {
            height: 18px;
          }
        }
      }

      .filterBox {
        background-image: linear-gradient(var(--Main2), var(--Main3));
        border: none;
        width: 45px;
        height: 45px;
        padding: 0px;
        border-radius: 10px;
        margin-left: 15px;
        box-shadow: 1px 2px 3px #062e241a;

        img {
          height: 16px;
        }
      }
    }
  }

  .buttonsAction {
    margin-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: end;
      flex-wrap: wrap;
      row-gap: 10px;

      li {
        margin-left: 10px;

        .whiteBtn {
          background: #fff;
          // box-shadow: 1px 2px 3px rgba(6, 46, 36, 0.1019607843);
          color: #7f8699;
          border-radius: 10px;
          border: none;
          font-weight: 500;
          padding: 0px 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          // height: 45px;
          height: 38px;
          position: relative;
          overflow: hidden;
          font-size: 15px;

          img {
            margin-right: 8px;
          }

          input[type="file"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
          }
        }

        .darkBtn {
          // background-image: linear-gradient(var(--Main2), var(--Main3));
          color: #fff;
          background: #508aaa;
          border-radius: 10px;
          border: none;
          font-weight: 500;
          padding: 0px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          // height: 45px;
          height: 38px;
          border-radius: 31px;
          font-size: 15px;

          :global(svg) {
            font-size: 14px;
            margin-right: 7px;
          }

          img {
            margin-right: 8px;
            filter: invert(8%) sepia(13%) saturate(2397%) hue-rotate(60deg) brightness(665%) contrast(360%);
          }
        }
      }
    }
  }

  .tableBody {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      width: 98%;
      height: 20px;
      background: #b8dcd8;
      bottom: -10px;
      transform: translate(-50%, 0%);
      border-radius: 0px 0px 20px 20px;
      display: none;
    }

    .tableBodyIn {
      // background: #fff;
      // padding: 15px 17px 20px 35px;
      padding: 6px 0px 0px 0px;
      border-radius: 20px;
      margin-top: 18px;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        // width: 18px;
        height: 100%;
        // background-image: linear-gradient(to right, var(--Main3), var(--Main2));
        top: 0;
        border-radius: 20px 0px 0px 20px;
      }

      .type {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        gap: 10px;

        span {
          font-size: 16px;
        }
      }
    }
  }

  .deleteBtnIcon {
    text-align: end;
    margin-top: 6px;

    button {
      background: transparent;
      border: none;

      img {
        filter: grayscale(1);
      }
    }
  }

  .deleteBtnGap {
    margin-top: 29px;
  }
}

.tabbingBox {
  margin-top: 20px;
  position: relative;
  // box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
  // background-color: #fff;
  border-radius: 12px;

  .tabbing_pillBox {
    display: flex;
    align-items: end;
    padding-right: 0px;

    :global(.saveBtn) {
      background-color: #7c7c7c;
      color: #fff;
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 15px;
      font-weight: 500;
      margin-left: 30px;
    }
  }

  .display_Mode {
    font-size: 15px;
    color: #3e8575;
    margin-bottom: 11px;
    white-space: nowrap;
    margin-left: 15px;
  }

  :global(.nav) {
    background-color: #ffffff00;
    display: flex;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 2px solid gainsboro;
    margin-bottom: 5px;
    // padding: 12px 10px 0px 18px;
    gap: 14px;
    width: 100%;

    .linespan {
      background: #606060;
      min-width: 1px;
      max-width: 1px;
      height: 22px;
      margin-top: 10px;
    }

    :global(.nav-item) {
      :global(.nav-link) {
        background-color: #ffffff00;
        color: #222;
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 10px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 20px 20px 0px 0px;
        position: relative;
        padding: 10px 0px;

        &::before {
          content: "";
          position: absolute;
          background-color: transparent;
          height: 2px;
          left: 0;
          bottom: -2px;
          border-radius: 14px;
          width: 100%;
        }

        .active {
          display: none;
          margin-right: 5px;
        }

        .defalut {
          margin-right: 5px;
        }

        &:global(.active) {
          color: var(--Main3);
          font-weight: 600;
          // background-color: #fff;
          // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
          // position: relative;
          // border-radius: 20px 20px 0px 0px;
          // top: -5px;
          // padding: 20px 25px 20px 25px;

          &::before {
            background-color: var(--Main3);
          }

          .active {
            display: block;
          }

          .defalut {
            display: none;
          }

          &::after {
            content: "";
            position: absolute;
            // bottom: 6px;
            bottom: 10px;
            left: 0;
            width: 100%;
            // height: 15px;
            height: 10px;
            background: #fff;
            z-index: 2;
            display: none;
          }
        }
      }
    }
  }

  :global(.tab-content) {
    border-radius: 20px;
    background: #ffffff00;
    padding: 22px 20px 20px;
    // box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
    // margin-top: -15px;
    z-index: 1;
    position: relative;
    // min-height: 500px;
    padding: 18px 0px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 98%;
    height: 20px;
    background: #b8dcd8;
    bottom: -10px;
    transform: translate(-50%, 0%);
    border-radius: 0px 0px 20px 20px;
    display: none;
  }
}

.sliderRow {
  :global(.owl-item) {
    :global(.item) {
      padding: 0px 10px;
    }
  }

  :global(.owl-nav) {
    button {
      position: absolute;
      top: -70px;
      margin: 0px !important;

      &:hover {
        background: transparent !important;
      }
    }

    :global(.owl-prev) {
      left: 0;
    }

    :global(.owl-next) {
      right: 0;
    }
  }
}

.radiusSlider {
  :global(.MuiSlider-root) {
    :global(.MuiSlider-rail) {
      color: #3c746d;
    }

    :global(.MuiSlider-track) {
      color: #3c746d;
    }

    :global(.MuiSlider-thumb) {
      width: 16px;
      height: 16px;

      &:before {
        background-image: linear-gradient(to right, var(--Main2), var(--Main3));
      }

      :global(.MuiSlider-valueLabelOpen) {
        top: 50px;
        background: transparent;

        &:before {
          content: "";
          display: none;
        }

        :global(.MuiSlider-valueLabelCircle) {
          :global(.MuiSlider-valueLabelLabel) {
            font-size: 16px;
            color: var(--Main2);

            &::after {
              content: " m";
            }
          }
        }
      }
    }
  }
}

// sub side bar page wrapper style
:global(.activesubsidebar) {
  margin-left: 180px !important;
}

// new pages css
.newRedesign_main {
  // padding-top: 10px;

  .head_paraBox {
    margin-bottom: 35px;

    img {
      width: 30px;
      margin-right: 6px;
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    h4 {
      font-size: 18px;
      color: #777c88;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      color: #222;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      margin-left: 4px;
    }
  }

  .branch_locatedBox_bg {
    background-color: #fff;
    border-radius: 8px;
    padding: 18px 18px;
    margin-bottom: 8px;
    border: 1px solid #dcdcdc;

    h3 {
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 8px;
      margin-bottom: 20px;
    }

    .sub_headings {
      h6 {
        span {
          font-weight: 500;
          color: #1d211e;
        }

        font-size: 14px;
        color: #222;
        font-weight: 300;
      }

      .form_located {
        :global(.form-control) {
          font-size: 14px;
          height: 38px;
          border-radius: 4px;

          &:focus,
          &:active {
            box-shadow: none;
          }
        }
      }
    }
  }

  .branchContact_tabBox {
    border: 1px solid #dcdcdc;
    padding: 11px 12px;
    border-radius: 8px;
    height: 100%;
    background: #fff;

    .branchOperating_tab_line {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 0px;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 10px;

      .branchOperatingLine_left {
        min-width: 94px;
      }

      .branchLine_content {
        font-size: 13px;
        font-weight: 600;
      }

      .branchOperatingLine_right {
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding-right: 40px;
        padding-left: 21px;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
      line-height: 24px;
    }

    p {
      font-size: 14px;
      color: #222;
      margin-bottom: 26px;
      font-weight: 300;
      margin-left: 4px;
      line-height: 20px;
    }

    .contact_addressInputs {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: flex;
        // justify-content: space-between;
        gap: 12px;
        margin-bottom: 10px;
        // align-items: center;

        label {
          white-space: nowrap;
          min-width: 154px;
          font-size: 14px;
          font-weight: 500;
          line-height: 33px;
        }

        :global(.form-control) {
          height: 36px;
          border: 1px solid #dcdcdc;
          border-radius: 4px;
          font-size: 14px;

          &::placeholder {
            color: #222;
            font-size: 13px;
          }

          &:focus,
          &:active {
            box-shadow: none;
            border-color: #3e8575;
          }
        }
      }
    }

    &.branchContact_tabBox2 {
      .contact_addressInputs {
        li {
          label {
            min-width: 110px;
          }

          :global(.form-control) {
            max-width: 250px;
          }
        }
      }
    }
  }

  // location tab css

  .edit_publishBranchFlex {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .edit_publishBranchMain {
    border-radius: 8px;
    padding: 10px 15px;
    position: relative;
    padding-left: 20px;
    background: #fff;
    border: 1px solid #dcdcdc;
    width: 100%;
    margin-bottom: 14px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: linear-gradient(180deg, #65beac 0%, #3e8575 100%);
      border-radius: 14px 0px 0px 14px;
    }

    .topBox_withBtn {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 0px;
      align-items: center;

      h4 {
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 0px;
      }

      // .topBox_imgBox {
      //   display: flex;
      //   align-items: center;
      //   gap: 8px;

      //   img {
      //     width: 40px;
      //     height: 40px;
      //     border-radius: 50%;
      //   }
      // }

      .topBox_imgBox {
        display: flex;
        align-items: center;
        gap: 8px;

        .fakeImage {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(var(--Main2), var(--Main3));

          span {
            color: #fff;
            font-size: 16px;
            height: 100%;
            width: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      h5 {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 400;

        span {
          color: #777c88;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .bottomBox_details {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      margin-top: 4px;

      li {
        width: 50%;

        &.leftWidth {
          width: 60%;
        }

        .infoSide {
          font-size: 14px;
          color: #222;
          display: flex;
          gap: 4px;
          margin-bottom: 5px;
          word-break: break-all;

          span {
            font-weight: 600;
            color: #1d211e;
            white-space: nowrap;
          }
        }
      }
    }
  }

  // operating tab css
  .branchOperating_tab_InputMian {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 20px;

    li {
      display: flex;
      gap: 14px;
      margin-bottom: 15px;
      // align-items: center;

      .firstBox {
        min-width: 112px;

        h6 {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 2px;
        }

        .labelName {
          font-size: 13px;
          font-weight: 500;
          color: #222;
          margin-top: 1px;
        }
      }

      .copyAllDays {
        .checkbox {
          padding: 0;
          cursor: text;

          .checkmark {
            display: none;
          }

          .labelName {
            font-weight: 600;
            font-size: 12px;
            color: rgb(29, 33, 30);
            position: relative;
            top: -3px;
          }
        }
      }

      .lastCopyClip {
        button {
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          margin-left: 8px;
          margin-top: 5px;

          img {
            width: 19px;
          }
        }
      }

      .secondBox {
        .inoutArea {
          position: relative;

          label {
            position: absolute;
            top: -8px;
            left: 11px;
            background-color: #fff;
            font-size: 11px;
            color: #222;
            font-weight: 700;
            padding: 0px 7px;
          }

          :global(.form-control),
          :global(.form-select) {
            max-width: 154px;
            font-size: 14px;
            height: 38px;
            border-radius: 9px;

            &:focus,
            &:active {
              box-shadow: none;
            }
          }

          :global(.form-select) {
            min-width: 92px;
            padding-right: 10px;
            padding-left: 10px;
            background-position: 94% 50%;
            font-size: 13px;
            border-radius: 9px;
          }
        }
      }
    }
  }

  // non-perating tab css
  .branch_nonOperating_tabing {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      gap: 13px;
      margin-bottom: 13px;

      .nonOperating_inputBox {
        width: 50%;

        .labelling {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 3px;
        }

        .nonOperating_input {
          display: flex;
          gap: 13px;
          align-items: center;

          :global(.form-control),
          :global(.form-select) {
            max-width: 170px;
            font-size: 14px;
            height: 38px;
            border-radius: 4px;

            &:focus,
            &:active {
              box-shadow: none;
            }

            &::-webkit-datetime-edit-fields-wrapper {
              color: #959595;
            }
          }

          :global(.form-select) {
            padding-right: 10px;
            padding-left: 10px;
            background-position: 94% 50%;
          }
        }
      }

      :global(.saveBtn) {
        background-color: #7c7c7c;
        color: #fff;
        border-radius: 4px;
        padding: 7px 13px;
        font-size: 15px;
        font-weight: 500;
        height: 38px;
        min-width: 65px;
      }
    }
  }

  :global(.saveButton) {
    background-color: #7c7c7c;
    color: #fff;
    border-radius: 4px;
    padding: 7px 18px;
    font-size: 15px;
    font-weight: 500;
    height: 38px;
    min-width: 65px;
    margin: 0 0 10px auto;
    display: block;
    border: none;
  }
}

.addBtn {
  :global(.btn) {
    background-color: transparent;
    color: #959595;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    padding: 3px 11px;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    gap: 5px;

    :global(svg) {
      font-size: 14px;
    }

    &:focus,
    &:active,
    &:hover {
      background-color: transparent !important;
      color: #959595 !important;
      border: 1px solid #dcdcdc !important;
    }
  }
}

.branch_maintain_bg {
  // box-shadow: 0px 3px 15px 0px rgba(29, 33, 30, 0.1019607843);
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
  padding: 22px 16px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  p {
    color: #222;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 300;
    line-height: 20px;
  }

  :global(.btn) {
    color: #fff;
    background-color: #508aaa;
    font-size: 14px;
    border-radius: 54px;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    font-weight: 500;
    border-color: transparent;

    &:hover,
    &:active,
    &:focus {
      color: #fff !important;
      background-color: #508aaa !important;
    }
  }
}

.bundle_discountPriced {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 17px;
  margin-bottom: 17px;

  h6 {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .reFormBox {
    margin-bottom: 0px;
  }

  :global(.form-control) {
    max-width: 140px;
  }
}

.bundle_discountPricedText {
  text-align: end;
  padding-right: 25px;

  span {
    font-size: 17px;
    text-decoration: underline;
    font-weight: 600;
    color: #3e8575;
  }
}

.dotsDropDown {
  :global(.dropdown) {
    :global(.dropdown-toggle) {
      border: none;
      background-color: transparent;
      padding: 0;
      color: #727683;
      font-size: 17px;

      &:active,
      &:focus {
        background: none !important;
        color: #42454e !important;
      }

      &::after {
        display: none;
      }
    }

    :global(.dropdown-menu) {
      min-width: 120px;
      max-width: 120px;
      text-align: left;
      padding: 5px 0px 6px;

      :global(.editDeleteBtn) {
        display: flex;
        justify-content: center;

        :global(.btn) {
          padding: 2px 14px;
          text-align: left;
          display: inline-block;
          border: none;
          background-color: transparent;
          border-radius: 0px;
          font-size: 15px;

          &:hover {
            background-color: #ececec;
          }

          img {
            width: 15px;
            height: 16px;
          }
        }
      }
    }
  }
}

.gridList_view {
  margin-bottom: 2px;
  margin-left: 15px;
  display: flex;

  :global(.btn) {
    padding: 0px 4px;
    color: #222;
    border: transparent;

    &:hover,
    :focus {
      border: transparent !important;
    }

    :global(svg) {
      font-size: 24px;
    }

    &:global(.listView) {
      :global(svg) {
        font-size: 25px;
      }
    }

    &:global(.active) {
      color: #1ab192;
    }
  }
}

.btnList {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 0;

  li {
    button {
      border: none;
      background-color: transparent;
      padding: 0px 6px;

      img {
        width: 18px;
        height: 19px;
      }
    }
  }
}

.services_leftMain_shadow {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  background-color: #fff;
  padding: 14px 16px;

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 18px;
  }

  p {
    color: #222;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }

  .serve_listing {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      gap: 6px;

      .titlesIcon {
        margin-left: 7px;
        width: 15px;
      }

      .categoryName {
        font-size: 14px;
        color: #222;
        font-weight: 300;
        cursor: pointer;

        &.active {
          color: #3e8575;
          font-weight: 400;
        }
      }

      .categoryAvail {
        display: inline-flex;
        gap: 9px;
        align-items: center;

        .value {
          font-size: 14px;
          color: #222;
          font-weight: 300;
        }

        button {
          border: none;
          background-color: transparent;
          padding: 0;

          img {
            width: 15px;
            height: 16px;
          }
        }
      }
    }
  }
}

.listHeading {
  margin-top: 24px;
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }
}

.newAccordionList {
  :global(.accordion) {
    :global(.accordion-body) {
      margin-top: 8px;
      padding: 0px 0px;
      padding-left: 25px;
    }

    :global(.accordion-item) {
      padding: 18px 0px;
      border: none;
      // border-bottom: 1px solid #e7e7e7;
      border-radius: 0px;
      background-color: #ffffff00;
      padding-top: 0px;

      :global(.accordion-header) {
        :global(.accordion-button) {
          background-color: transparent;
          padding: 0px 0px;
          color: #222;

          // &:global(.collapsed) {
          //   &::after {
          //     content: "+";
          //     transform: rotate(0);
          //   }
          // }

          // &::after {
          //   display: flex;
          //   align-items: center;
          //   font-size: 20px;
          //   content: "-";
          //   background-image: none !important;
          //   transform: rotate(0);
          //   transition: content 3s;
          //   font-weight: 600;
          // }

          &:not(.collapsed) {
            box-shadow: none;
          }

          :global(.categoryTypes) {
            font-weight: 500;
            font-size: 17px;
            margin-right: 10px;
          }

          &:focus {
            box-shadow: none;
            border: none;
          }
        }
      }

      .newAccordionFlexBtn {
        display: flex;
        align-items: center;
        gap: 4px;

        :global(.accordion-button) {
          padding-left: 25px;

          &::after {
            position: absolute;
            left: 0;
          }
        }
      }

      .inside_Accordion {
        .inside_head {
          color: #777c88;
          font-size: 14px;
        }

        :global(.accordion) {
          :global(.accordion-item) {
            padding-bottom: 8px;
          }
        }

        .newInside_AccordionFlexBtn {
          display: flex;
          justify-content: space-between;
          align-items: end;
        }

        .noData {
          margin: 0px 0px;
          font-size: 14px;
          font-weight: 500;
        }

        :global(.accordion-button) {
          padding-left: 20px;

          &:global(.collapsed) {
            &::after {
              content: "+";
              transform: rotate(0);
            }
          }

          &::after {
            display: flex;
            align-items: center;
            font-size: 20px;
            content: "-";
            background-image: none !important;
            transform: rotate(0);
            transition: content 3s;
            font-weight: 600;
          }

          &::after {
            position: absolute;
            left: 0;
            font-weight: 500;
            color: #61666a;
          }
        }

        :global(.accordion-body) {
          padding: 14px 0px;
        }
      }
    }
  }
}

.addServe {
  background-color: transparent;
  color: #508aaa;
  border: 1px solid #508aaa;
  padding: 4px 3px;
  border-radius: 5px;
  font-size: 15px;
  border-radius: 54px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  justify-content: center;
  border: transparent;
  font-weight: 400;

  :global(svg) {
    font-size: 16px;
  }
}

.tableCollapse {
  border: 1px solid #dcdcdc;
  padding: 9px;
  border-radius: 6px;
  background: #fff;

  .noBundleData {
    color: #222;
    text-align: center;
    margin: 12px 0;
    font-weight: 500;
  }

  :global(.table) {
    margin-bottom: 0px;
    border: transparent;

    thead {
      tr {
        border-bottom: 1px solid #dcdcdc;
      }

      th {
        // background-color: #F4FAF6;
        font-weight: 600;
        font-size: 12px;
        padding: 3px 6px;
        color: #777c88;
      }
    }

    tbody {
      tr {
        vertical-align: middle;
      }

      td {
        font-size: 14px;
        padding: 4px 8px 0px;
        word-break: break-all;

        .textGreenTable {
          text-decoration: underline;
          color: #3e8575;
          font-weight: 500;
        }
      }
    }
  }
}

.offTab_heading {
  margin-bottom: 12px;

  h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.offcanvas_para {
  color: #222;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 9px;

  p {
    color: #222;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 14px;
  }
}

// end

// new icon check-box
.iconLogo_Para {
  display: inline-flex;
  align-items: center;
  gap: 10px;

  .iconPara {
    font-size: 15px;
    color: #777c88;
  }
}

.iconLogo_checkBox {
  display: inline-flex;

  .borderBox {
    border: 1px solid gainsboro;
    // padding: 0px 5px 2px 5px;
    padding: 0px 5px 2px 5px;
    border-radius: 9px;
    background: #fff;

    .checkbox {
      .checkmark {
        height: 17px;
        width: 17px;

        &:after {
          left: 6px;
          top: 1px;
        }
      }

      .labelName {
        margin-top: -5px;
        margin-left: 1px !important;
      }
    }

    img {
      filter: drop-shadow(0.35px 0.35px 0.6px rgba(0, 0, 0, 0.3));
      width: 19px;
    }
  }
}

.edit_publish_checkBox {
  // min-width: 143px;
  border-radius: 4px;
  // padding: 4px 10px;

  .labelName {
    color: #7c7c7c;
    font-size: 13px;
    // line-height: 16px;
    display: inline-block;
  }
}

.edit_publish_checkBox {
  display: inline-flex;
  gap: 24px;
  margin-left: 4px;

  .labelName {
    font-weight: 400;
    color: #727683;
  }

  &.checkBox_FlexColumn {
    flex-direction: column;
    gap: 8px;
  }
}

.topboxHeadingSub {
  margin-bottom: 24px;

  h4 {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    color: #222;
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
    margin-left: 4px;
  }
}

// end

// form
.reFormBox {
  margin-bottom: 13px;

  .teamTitleInfo {
    display: flex;
    align-items: center;
    gap: 26px;
    justify-content: space-between;
  }

  label {
    font-size: 15px;
    margin-bottom: 0px;
    font-weight: 500;
  }

  .sub_label {
    display: block;
    font-size: 14px;
    margin-bottom: 7px;
    font-weight: 300;
    color: #222;
    line-height: 20px;
    margin-left: 4px;
  }

  :global(.form-control),
  :global(.form-select) {
    font-size: 14px;
    height: 38px;
    border-radius: 9px;
    margin-left: 4px;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  :global(.form-select) {
    padding-right: 10px;
    padding-left: 10px;
    background-position: 98% 50%;
  }

  &.label_Relative {
    position: relative;

    label {
      position: absolute;
      top: -8px;
      left: 11px;
      background-color: #fff;
      font-size: 11px;
      color: #222;
      font-weight: 700;
      padding: 0px 7px;
    }
  }
}

// end

// stepper
.stepperMain {
  margin-top: 33px;
  margin-bottom: 35px;
  padding: 18px 0px;
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  background-color: #fff;

  .stepper_heading {
    font-size: 18px;
    text-align: center;
    margin-bottom: 23px;
    padding: 0px 20px;

    img {
      width: 30px;
      margin-right: 6px;
    }
  }

  :global(.css-1hv8oq8-MuiStepLabel-label),
  :global(.css-2fdkz6) {
    font-family: "Poppins" !important;
    margin-top: 10px !important;
  }
}

// end

:global(.newOffcanvas_width) {
  &:global(.offcanvas) {
    &:global(.offcanvas-end) {
      width: 650px;
      background-color: #fbfbfb;
    }

    :global(.offcanvas-header) {
      padding-bottom: 20px;
      // border-bottom: 1px solid #DCDCDC;

      :global(.btn-close) {
        position: absolute;
        top: 21px;
        left: -52px;
        opacity: 1;
        background-color: white;
        border-radius: 50%;
        font-size: 16px;
        padding: 12px;
        background-image: url(../images/cross.svg);
      }

      .offHeader_main {
        width: 100%;

        h6 {
          color: #222;
        }

        h3 {
          font-size: 24px;
          background: linear-gradient(#65beac, #3e8575);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 10px;
        }
      }
    }

    :global(.offcanvas-body) {
      // height: calc(100vh - 169px);
      max-height: 100dvh;
      overflow: hidden;
      overflow-y: auto;
      padding-bottom: 190px;
      // padding-bottom: 10px;
      padding-top: 8px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: gainsboro;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--Main);
        border-radius: 50px;
      }

      :global(.saveGrey) {
        background-color: #7c7c7c;
        color: #fff;
        border-radius: 9px;
        padding: 7px 12px;
        font-size: 15px;
        font-weight: 500;
        border: transparent;

        &:active,
        &:focus {
          background-color: #7c7c7c !important;
          color: #fff !important;
        }
      }
    }
  }
}

:global(.btn) {
  &.addGrey {
    background-color: #7c7c7c;
    color: #fff;
    border-radius: 9px;
    padding: 8px 11px;
    font-size: 14px;
    font-weight: 500;
    border: transparent;

    &:active,
    &:focus,
    &:hover {
      background-color: #7c7c7c !important;
      color: #fff !important;
    }
  }
}

:global(.btn) {
  &.addCancel {
    background-color: #eff0f5;
    color: #7f8699;
    border-radius: 9px;
    padding: 8px 11px;
    font-size: 14px;
    font-weight: 500;
    border: transparent;

    &:active,
    &:focus,
    &:hover {
      background-color: #eff0f5 !important;
      color: #7f8699 !important;
    }
  }
}

.searchBoxIcon {
  :global(.form-control) {
    padding-left: 36px;
    color: #222;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 11px;
    font-size: 20px;
    transform: translate(0%, -50%);
    color: #222;
  }
}

.serviceBundle_tableList {
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
  padding: 14px 12px;
  overflow-x: auto;
}

.breadCrumb_All {
  margin: 40px 0px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 3px;

    .homeBtn {
      button {
        font-size: 14px;
        color: #3e8575;
        line-height: 25px;
        padding: 0;
      }
    }

    .mainPages {
      button {
        color: #3e8575;
        font-size: 14px;
        line-height: 25px;
        padding: 0px 0px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .currentPage {
      span {
        padding: 0px 0px;
        font-size: 14px;
        line-height: 25px;
      }
    }

    .arrowForard {
      font-size: 15px;
    }
  }

  button {
    background: none;
    border: none;
  }
}

.pt50 {
  padding-top: 50px;
}

.pt40 {
  padding-top: 40px;
}
.pt30 {
  padding-top: 30px;
}
.pt20 {
  padding-top: 20px;
}

.outerBox_heading {
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 16px;
}

.tabbing_para {
  font-size: 14px;
  color: #222;
  margin-bottom: 26px;
  font-weight: 300;
}

.publish_pagesHeading {
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #508aaa;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
  .pageWrapper {
    margin-left: 0px;
    padding: 20px 20px;

    .pageTitle {
      display: block;

      .titleInfo {
        display: flex;
        margin-bottom: 10px;
      }

      .rightInfo {
        width: 100%;

        .searchBox {
          width: 100%;
        }

        .filterBox {
          min-width: 45px;
          max-width: 45px;
        }
      }
    }

    .buttonsAction {
      margin-top: 20px;

      ul {
        display: block;

        li {
          margin-left: 0px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: inline-block;
        }
      }
    }

    .tableBody {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        width: 98%;
        height: 20px;
        background: #b8dcd8;
        bottom: -10px;
        transform: translate(-50%, 0%);
        border-radius: 0px 0px 20px 20px;
      }

      .tableBodyIn {
        padding: 20px 15px 15px 20px;
        margin-top: 10px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 10px;
          height: 100%;
          background-image: linear-gradient(to right,
              var(--Main3),
              var(--Main2));
          top: 0;
          border-radius: 20px 0px 0px 20px;
        }
      }
    }

    .tabbingBox {
      margin-top: 10px;

      :global(.nav) {
        // background-color: #fcfdff;
        display: flex;
        border-radius: 20px 20px 0px 0px;
        flex-wrap: unset;
        white-space: nowrap;
        overflow: scroll;
      }

      :global(.tab-content) {
        border-radius: 0px 0px 20px 20px;
      }
    }
  }

  .iconSelectList {
    display: flex;
    // white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;
  }

  .listUl {
    margin-top: 0px;
    display: block;

    li {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  .formBox {
    .btnsGroup {
      :global(.btn) {
        min-width: 110px;
      }
    }
  }

  .sliderRow {
    :global(.owl-nav) {
      button {
        position: relative;
        top: 0;
        margin: 0px 20px !important;
      }
    }
  }
}

@media (max-width: 990px) {
  :global(.form-control) {
    font-size: 12px !important;
  }

  :global(.form-select) {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (max-width: 5520px) and (min-width: 768px) {}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {}

@media only screen and (min-width: 700px) and (max-width: 1099px) and (min-height: 500px) and (max-height: 1100px) {}

@media only screen and (max-width: 1120px) {
  .pageWrapper {
    margin-left: 71px;
  }
}

@media only screen and (min-width: 1121px) {
  .pageWrapper {

    &.maxWidth_pageWrapper {
      max-width: 975px;
      margin: auto;
    }
  }
}

/* =======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  ======================================================= */