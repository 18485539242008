.logoBox {
    margin: 7px auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .profileCheck {
        // transform: translateX(40%);
    }

    // .box {
    //   height: 98px;
    //   width: 98px;
    //   border: 1px solid black;
    //   border-radius: 15px;
    //   // background: linear-gradient(180.35deg, #65beac 0.27%, #3e8575 98.11%);
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   font-size: 36px;
    //   font-weight: 500;
    //   margin-bottom: 20px;
    //   position: relative;
    //   transform: translateX(50%);
    //   &.v2 {
    //     transform: translateX(0%);
    //   }

    //   .bottomLogo {
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     transform: translate(50%, 30%);
    //     box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    //     background-color: #fff;
    //     border-radius: 10px;
    //     height: 31px;
    //     width: 31px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: pointer;

    //     input[type="file"] {
    //       font-size: 1px;
    //       opacity: 0;
    //       position: absolute;
    //       z-index: -1;
    //     }
    //   }
    // }



    .box {
        height: 130px;
        width: 138px;
        border-radius: 6px;
        background-color: #eff0f5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;

        .upload_img_remove {
            position: absolute;
            right: -8px;
            top: -10px;
            z-index: 9;
            border: none;
            background: none;
            padding: 0;
            line-height: 24px;

            :global(svg) {
                width: 20px;
                height: 20px;
                background-color: white;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                border-radius: 50%;
                padding: 3px;
                color: #dd2828;
            }
        }

        .img_content_showName {
            width: 100%;
            height: 100%;
            background: linear-gradient(#65beac, #3e8575);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
        }

        &.v2 {
            background-color: #fff;
            border: 1px solid red solid #eff0f5;
        }

        input[type="file"] {
            display: none;
        }

        .boxImage {
            border-radius: 20px;
            width: 60px;
            height: 60px;
            object-fit: cover;
            fill: #7f8699;
        }

        .bottomLogo {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(50%, 30%);
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            background-color: #fff;
            border-radius: 10px;
            height: 31px;
            width: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            border-radius: 50%;

          
                img {
                    width: 14px;
                    cursor: pointer !important;
                }

                input {
                    font-size: 2px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    opacity: 0;
                }
            
        }
    }

    .upload {
        font-weight: 600;
        font-size: 18px;
        color: #7f8699;
    }

    .boxDiv {
        width: 100%;
    }

    h2 {
        font-size: 18px;
        font-weight: 500;
    }

    h4 {
        color: #7f8699;
        font-size: 14px;
        font-weight: 400;
    }
}

.offcanvas_footer {
    padding: 0px 16px;
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
    border-top: 1px solid #d9d9d9;
    // new
    padding: 0px 15px;
    position: fixed;
    bottom: 0;
    z-index: 99;
    background: #fff;
    border-top: 1px solid #d9d9d9;
    width: 649px !important;
    right: 0;
}

.formSign {
    .formSignGroup {
        gap: 16px;

        .btnGray {
            background: #7C7C7C;
            background-image: none !important;
            box-shadow: none !important;
            color: #fff;
            border-radius: 4px !important;
            min-width: auto;

            &.btnGrayLight {
                background: #DCDCDC;
                background-image: none !important;
                color: #777C88 !important;
            }
        }
    }
}

.hrLine{
    margin: 15px 0px;
    border-bottom: 1px solid #DCDCDC;
}