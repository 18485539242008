.scroll {
  overflow: hidden;
  // height: calc(100vh - 180px);
  // height: calc(100vh - 90px);
  height: calc(100dvh - 52px);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--Main);
    border-radius: 50px;
  }
}

.sidebarMain {
  position: fixed;
  // width: 66px;
  width: 70px;
  height: 100%;
  // top: 60px;
  // z-index: 10;
  top: 0;
  z-index: 99;
  background: #fff;
  transition: 0.2s ease-in;
  border-right: 1px solid #eeeeee;
  // box-shadow: 0 4px 20px 0 #0F5E4B40;
  box-shadow: 0 4px 4px 0 #0f5e4b40;
  box-shadow: -4px -4px 4px #fff, 3px 2px 10px #bdc1d1;
  background: linear-gradient(0deg, #e6eaf0, #e6eaf0),
    linear-gradient(0deg, #e3e9ee, #e3e9ee),
    linear-gradient(0deg, #f0f3f6, #f0f3f6), #fff;
  box-shadow: -4px -4px 4px #fff, 3px 2px 10px #bdc1d1;
  background: #fff;
  padding: 5px 0;

  .menuList {
    .scroll {
      list-style: none;
      margin: 0;
      padding: 0px 0px;

      li {
        margin: 0px;
        // padding: 10px 0;

        .signleMenu {
          display: flex;
          align-items: center;
          color: #525c62;
          text-decoration: none;
          // padding: 12px 20px;
          padding: 8px 7px;
          background-color: transparent;
          transition: 0.5s;
          width: 100%;
          border: none;
          position: relative;
          font-size: 16px;
          font-weight: 500;
          flex-direction: column;

          &:focus {
            box-shadow: none;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
            background-image: linear-gradient(to right,
                var(--Main2),
                var(--Main3));
            border-radius: 0px 50px 50px 0px;
            transition: 0.5s;
            opacity: 0;
          }

          &.active {
            // background: linear-gradient(89.16deg, #E3EDF0 0.56%, rgba(249, 86, 15, 0) 99.18%);
            background: none;

            .menuIcon {
              .icon {
                &.defalut {
                  display: none;
                }

                &.deactive {
                  display: inline-block;
                }

                &.active {
                  display: inline-block;
                  // filter: invert(52%) sepia(8%) saturate(2349%) hue-rotate(116deg) brightness(85%) contrast(85%);
                }
              }
            }

            &::after {
              filter: none;
            }

            &::before {
              opacity: 0;
            }

            .menuMainHeading {
              color: #3e8575;
            }
          }

          &.isDisabled {
            &.active {
              background: transparent;

              .menuMainHeading {
                color: inherit;
              }
            }
          }

          // &::before {
          //     content: '';
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     width: 8px;
          //     height: 100%;
          //     background-image: linear-gradient(to right, var(--Main2) , var(--Main3));
          //     border-radius: 0px 50px 50px 0px;
          //     transition: 0.5s;
          //     opacity: 0;
          // }

          .menuIcon {
            // margin-right: 5px;
            position: relative;
            text-align: center;
            top: -1px;
            width: 30px;

            .icon {
              height: 24px;
              width: 21px;
              object-fit: contain;

              &.defalut {
                display: inline-block;
              }

              &.active {
                display: none;
              }
            }
          }

          .menuName {
            position: relative;
            padding: 15px 0px;
            text-wrap: nowrap;
            // opacity: 0;
          }

          &:global(.active),
          &:hover {
            // background: linear-gradient(89.16deg, #E3EDF0 0.56%, rgba(249, 86, 15, 0) 99.18%);
            background: none;

            .menuIcon {
              .icon {
                &.defalut {
                  display: none;
                }

                &.deactive {
                  display: inline-block;
                }

                &.active {
                  display: inline-block;
                }
              }
            }

            &::after {
              filter: none;
            }

            &::before {
              // opacity: 1;
              opacity: 0;
            }
          }

          &:global(.active) {
            .menuMainHeading {
              color: #3e8575;
            }
          }
        }

        .menuMainHeading {
          font-size: 10px;
          text-align: center;
          margin: 0;
          color: #525c62;
          margin-bottom: 0px;
          margin-left: 3px;
          margin-top: 4px;
          font-weight: 500;
        }
      }
    }

    .fixedBox {
      position: absolute;
      bottom: 62px;
      z-index: 99;
      background: #fff;
      padding: 20px 20px;
      width: 100%;
      left: 0;

      .settingBtn {
        border: 1px solid var(--Main3);
        color: var(--Main3);
        background: rgba(26, 177, 146, 0.1019607843);
        font-weight: 600;
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
      }

      .countBox {
        color: #ff4848;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      :global(.progress) {
        height: 4px;
        margin-bottom: 0px;
        border-radius: 50px;
        background: #e3e7ee;

        :global(.progress-bar) {
          background-image: linear-gradient(var(--Main2), var(--Main3));
        }
      }
    }
  }

  .collpaseExpandBtn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 0px;
    background: #fff;
    padding: 6px 4px;
    text-align: center;
    width: 100%;

    :global(.btn) {
      background-color: #fff;
      color: #fff;
      padding: 2px 9px;
      border-radius: 6px;
      display: inline-flex;
      transition: 0.1s;

      &:focus,
      &:active,
      &:hover {
        background-color: #fff !important;
        color: #fff !important;
      }

      .borderApply {
        border: 2px solid #3e8575;
        display: inline-flex;
        position: relative;
        padding: 2px 0px;
        padding-left: 1px;
        padding-right: 6px;
        border-radius: 3px;
        transition: 0.2s;
        color: #3e8575;

        &::before {
          position: absolute;
          content: "";
          width: 2px;
          height: 100%;
          right: 5px;
          top: 0px;
          background-color: #3e8575;
        }

        :global(svg) {
          transform: rotate(0deg);
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
}

.sidebarExpandActive {
  width: 170px;

  .menuList {
    .scroll {
      li {
        .signleMenu {
          align-items: flex-start;
          flex-direction: row;
        }

        .menuMainHeading {
          font-size: 14px;
        }
      }

      .accordion_Expand {
        :global(.accordion) {
          :global(.accordion-item) {
            border: none;
            padding: 0px 7px;
            padding-right: 4px;

            :global(.accordion-header) {

              // padding-bottom: 10px;
              :global(.accordion-button) {
                padding: 8px 6px;

                &::after {
                  background-image: url(../../../assets/images/accordionSidebarDrop.png);
                  background-size: 14px;
                  background-position: 51% 51%;
                }

                &:not(.collapsed) {
                  color: #3e8575;
                  background: #fbfbfb;
                  box-shadow: none;
                }

                &:global(.collapsed) {
                  color: #525c62;
                  background: #fff;
                }

                &:focus {
                  box-shadow: none;
                }

                :global(.img_withContent) {
                  img {
                    height: 24px;
                    width: 21px;
                    object-fit: contain;
                  }

                  :global(.contentSpan) {
                    font-size: 14px;
                    text-align: center;
                    margin: 0;
                    margin-bottom: 0px;
                    margin-left: 6px;
                    margin-top: 4px;
                    font-weight: 500;
                  }
                }
              }
            }

            :global(.accordion-collapse) {
              :global(.accordion-body) {
                padding: 5px 2px;
                padding-left: 17px;
                background: #fbfbfb;

                li {
                  .signleMenu {
                    padding: 4px 0px;
                    font-size: 13px;
                    align-items: center;

                    .menuNameArrow {
                      font-size: 20px;
                      margin-right: 8px;
                      color: #525c62;
                    }

                    &:hover {
                      .menuNameArrow {
                        background: linear-gradient(to right, #65beac, #3e8575);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }

                    .menuName {
                      padding: 0px 0px;
                      white-space: normal;
                      display: contents;

                      img {
                        margin-right: 4px;
                      }
                    }

                    &.active {
                      .menuName {
                        color: #3e8575;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .collpaseExpandBtn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 0px;
    background: #fff;
    padding: 6px 4px;
    text-align: center;
    width: 100%;

    :global(.btn) {
      background-color: #fff;
      color: #fff;
      padding: 2px 9px;
      border-radius: 6px;
      display: inline-flex;
      transition: 0.3s ease-in-out;

      &:focus,
      &:active,
      &:hover {
        background-color: #fff !important;
        color: #fff !important;
      }

      .borderApply {
        border: 2px solid #3e8575;
        display: inline-flex;
        position: relative;
        padding: 2px 0px;
        padding-left: 24px;
        padding-right: 16px;
        border-radius: 3px;
        color: #3e8575;

        &::before {
          position: absolute;
          content: "";
          width: 2px;
          height: 100%;
          left: 10px;
          top: 0px;
          background-color: #3e8575;
          transition: 0.2s ease-in-out;
        }

        :global(svg) {
          transform: rotate(180deg);
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
}

:global(.tooltip) {
  &:global(.show) {
    opacity: 1;
  }

  :global(.tooltip-arrow) {
    opacity: 0;
  }

  :global(.tooltip-inner) {
    background-color: #fff;
    border: 1px solid #b9b9b9;
    border-radius: 10px;
    max-width: 481px;
    padding: 8px 0;
    box-shadow: rgba(0, 0, 0, 0.24) 2px 2px 8px;

    ul {
      margin: 0;
      padding: 0;
      text-align: left;
      list-style: none;

      li {
        :global(.dropList) {
          display: block;
          padding: 4px 16px;
          font-size: 15px;
          margin: 0;
          color: #32373a;
          margin-bottom: 0px;
          font-weight: 500;
          transition: 0.3s;
          border: none;
          background: none;
          font-family: "Poppins";
          width: 100%;
          text-align: left;

          &:hover {
            background-color: #f4f4f4;
            color: #3e8575;
          }
        }
      }
    }
  }
}

.businessRelative {
  position: relative;

  .businessAbsolute {
    position: absolute;
    left: 100%;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .sidebarBody {
    .sidebarMain {
      left: -250px;
      transition: 0.5s;
      z-index: 9999;
      top: 0;
    }

    .hideBg {
      width: 100%;
      position: fixed;
      left: -100%;
      background: rgba(0, 0, 0, 0.3);
      height: 100%;
      top: 0;
      z-index: 99;
    }

    &.show {
      .sidebarMain {
        left: 0px;
      }

      .hideBg {
        left: 0%;
      }
    }
  }
}

// @media only screen and (min-width: 700px) and (max-width: 1099px) and (min-height: 500px) and (max-height: 1100px) {
//     .scroll {
//         height: calc(100vh - 270px);
//     }
// }