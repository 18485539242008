.scroll {
    overflow: hidden;
    height: calc(100vh - 142px);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--Main);
        border-radius: 50px;
    }
}

.sidebarMain {
  position: fixed;
  width: 225px;
  height: 100%;
  top: 60px;
  z-index: 10;
  background: #fff;
  transition: 0.2s ease-in;
  border-right: 1px solid #eeeeee;
  padding: 10px 0px;


    .menuList {

        .scroll {
            list-style: none;
            margin: 0;
            padding: 0px 0px;
            

            li {
                margin: 0px;

                .signleMenu {
                    display: flex;
                    align-items: center;
                    color: #525C62;
                    text-decoration: none;
                    padding:0px 15px;
                    background-color: transparent;
                    transition: 0.5s;
                    width: 100%;
                    border: none;
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;

                    &:focus {
                        box-shadow: none;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 8px;
                        height: 100%;
                        background-image: linear-gradient(to right, var(--Main2) , var(--Main3));
                        border-radius: 0px 50px 50px 0px;
                        transition: 0.5s;
                        opacity: 0;
                    }

                    .menuIcon {
                        margin-right: 5px;
                        position: relative;
                        text-align: center;
                        top: -1px;
                        width: 30px;

                        .icon {
                           height: 24px;

                           &.defalut {
                              display: inline-block;
                           }
                           &.active {
                              display: none;
                           }
                        }
                    }
                    .menuName {
                      position: relative;
                      padding:15px 0px;

                    }
                    
                    &:global(.active) ,&:hover {
                      background: linear-gradient(89.16deg, #E3EDF0 0.56%, rgba(249, 86, 15, 0) 99.18%);

                        .menuIcon {

                          .icon {
 
                            &.defalut {
                               display: none;
                            }
                            &.active {
                               display: inline-block;
                            }
                         }
                        }

                        &::after {
                            filter: none;
                        }
                        &::before {
                            opacity: 1;
                        }
                    }
                }

            }


            :global(.accordion ){
                    
                .dropdownMenu{

                    :global(.accordion-item){

                        .dropdownMenuMain {

                            :global(.accordion-button) {
                                display: flex;
                                align-items: center;
                                color: #525C62;
                                text-decoration: none;
                                padding:0px 15px;
                                background-color: transparent;
                                transition: 0.5s;
                                width: 100%;
                                border: none;
                                position: relative;
                                font-size: 16px;
                                font-weight: 500;
                                border-radius: 0px;

                                &:focus {
                                    box-shadow: none;
                                }

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 8px;
                                    height: 100%;
                                    background-image: linear-gradient(to right, var(--Main2) , var(--Main3));
                                    border-radius: 0px 50px 50px 0px;
                                    transition: 0.5s;
                                    opacity: 0;
                                    transform: none;
                                }
                                

                                .menuIcon {
                                    margin-right: 5px;
                                    position: relative;
                                    text-align: center;
                                    top: -1px;
                                    width: 30px;

                                    .icon {
                                    height: 24px;

                                    &.defalut {
                                        display: inline-block;
                                    }
                                    &.active {
                                        display: none;
                                    }
                                }
                                
                              }

                              
                              &[aria-expanded="true"] {
                                background: linear-gradient(89.16deg, #E3EDF0 0.56%, rgba(249, 86, 15, 0) 99.18%);
          
                                  .menuIcon {
          
                                    .icon {
           
                                      &.defalut {
                                         display: none;
                                      }
                                      &.active {
                                         display: inline-block;
                                      }
                                   }
                                  }
          
                                  &::after {
                                      filter: none;
                                  }
                                  &::before {
                                      opacity: 1;
                                  }
                              }
                            }
                            .menuName {
                                position: relative;
                                padding:15px 0px;
          
                              }
                              &:global(.active) ,&:hover {
                                background: linear-gradient(89.16deg, #E3EDF0 0.56%, rgba(249, 86, 15, 0) 99.18%);
          
                                  .menuIcon {
          
                                    .icon {
           
                                      &.defalut {
                                         display: none;
                                      }
                                      &.active {
                                         display: inline-block;
                                      }
                                   }
                                  }
          
                                  &::after {
                                      filter: none;
                                  }
                                  &::before {
                                      opacity: 1;
                                  }
                              }
                        }
                  
                        :global(.accordion-collapse){
                            :global(.accordion-body){
                                padding: 0 !important;

                                li {

                                    .signleMenu {
                                        padding:15px 10px 15px 30px;
                                        background: #f6fffd;
                                        width: 100%;
                                        font-size: 15px;

                                        .menuName {
                                            padding: 0px 0px;
                                        }
                                        &:global(.active) {
                                            background: #d5d5d5;
                                        }
                                    }

                                    :global(.accordion) {

                                        :global(.accordion-item) {

                                            :global(.accordion-header) {

                                                :global(.accordion-button) {
                                                    padding:15px 10px 15px 30px;
                                                    background: #f6fffd;
                                                    color: #525C62;
                                                    font-weight: 500;
                                                    font-size: 15px;

                                                    &:focus {
                                                        box-shadow: none;
                                                    }
                                                }
                                            }
                                            :global(.accordion-body) {

                                                    li {

                                                    .signleMenu {
                                                        padding:15px 10px 15px 30px;
                                                        background: #eff0f5;
                                                        width: 100%;
                                                        font-size: 15px;
            
                                                        .menuName {
                                                            padding: 0px 0px;
                                                        }

                                                        &:global(.active) {
                                                            color: #222;
                                                            background: #d5d5d5;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }

        }

        .fixedBox {
            position: absolute;
            bottom: 62px;
            z-index: 99;
            background: #fff;
            padding: 20px 20px;
            width: 100%;
            left: 0;

            .settingBtn {
                border: 1px solid var(--Main3);
                color: var(--Main3);
                background: rgba(26, 177, 146, 0.1019607843);
                font-weight: 600;
                width: 100%;
                padding: 10px 15px;
                border-radius: 10px;
            }
            .countBox {
                color: #FF4848;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 5px;
            }
            :global(.progress) {
                height: 4px;
                margin-bottom: 0px;
                border-radius: 50px;
                background: #E3E7EE;

                :global(.progress-bar) {
                    background-image: linear-gradient(var(--Main2), var(--Main3));
                }
            }
        }
    }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

    .sidebarBody {
        
        .sidebarMain {
            left: -250px;
            transition:0.5s;
            z-index:9999;
            top: 0;
        }
        
        .hideBg {
            width: 100%;
            position: fixed;
            left: -100%;
            background: rgba(0, 0, 0, 0.3);
            height: 100%;
            top: 0;
            z-index: 99;
        }
        
        &.show {

            .sidebarMain {
                left: 0px;
            }
            
            .hideBg {
                left: 0%;
            }
        }
    }
}