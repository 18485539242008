.activity_mainBox {
  border-radius: 8px;
  padding: 18px 15px;
  position: relative;
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 14px;
  overflow: hidden;
  height: 100%;
  max-height: 366px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .dashboard_FlexBox {
    border-radius: 8px;
    padding: 9px 15px;
    position: relative;
    padding-left: 20px;
    background: #fff;
    border: 1px solid #dcdcdc;
    width: 100%;
    margin-bottom: 14px;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: #b09b28;
      border-radius: 14px 0px 0px 14px;
    }

    .boxLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2px;
      gap: 10px;

      .timing {
        font-size: 13px;
        font-weight: 400;
        color: #b09b28;
        text-align: end;
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0px 0px;
      }

      .availableProgress {
        color: #9296a0;
        font-size: 13px;
        font-weight: 400;
        text-align: end;

        .availability {
          font-weight: 500;
          color: #212529;
          font-size: 14px;
        }
      }
    }

    .boxRight {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        font-size: 16px;
        font-weight: 600;
      }

      p {
        margin-bottom: 0;
        color: #7f8699;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }

      .progressBox {
        width: 100%;
        margin-top: 4px;
        margin-bottom: 1px;

        :global(.progress) {
          background-color: #dcdcdc;
          height: 10px;

          :global(.progress-bar) {
            background-color: #23b14d;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
.notFoundMessage {
  font-size: 14px;
}

.teamService_mainBox {
  // box-shadow: 0px 0px 5.984355926513672px 0px rgba(0, 0, 0, 0.10);
  // background-color: #fff;
  // border-radius: 11px;
  // height: 100%;

  border-radius: 8px;
  // padding: 18px 15px;
  position: relative;
  padding-left: 0px;
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 14px;
  overflow: hidden;

  .teamService_main_heading {
    padding: 18px 15px 18px 26px;

    h3 {
      font-size: 20px;
      font-weight: 600;
    }

    span {
      color: #1d211e;
      font-size: 14px;
      font-weight: 400;
      margin-left: 2px;
    }

    .usd {
      color: #1ab192;
      font-size: 30px;
      font-weight: 700;
    }

    .appointment {
      font-size: 14px;
      font-weight: 400;
      color: #7f8699;
      margin: 0;
      margin-bottom: 4px;

      span {
        font-size: 14px;
        font-weight: 600;
        color: #7f8699;
      }
    }
  }

  :global(.recharts-default-legend) {
    opacity: 0;
  }

  .ChartContainer {
    padding: 20px 30px 20px 0px;
    margin: 0 auto;
  }

  .serviceTable {
    thead {
      tr {
        th {
          background-color: #f4f4f4;
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          td {
            background-color: #f4f4f4;
          }
        }
      }
    }

    table {
      tr {
        th {
          color: #1d211e;
          font-size: 15px;
          font-weight: 600;

          &:nth-child(1) {
            min-width: 180px;
            max-width: 1px;
            padding-left: 25px;
          }

          &:nth-child(2),
          &:last-child {
            text-align: center;
          }
        }

        td {
          color: #000;

          &:nth-child(1) {
            min-width: 180px;
            max-width: 1px;
            padding-left: 25px;
          }

          &:nth-child(2),
          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }

  .teamTable {
    thead {
      tr {
        th {
          background-color: #f4f4f4;
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          td {
            background-color: #f4f4f4;
          }
        }
      }
    }

    table {
      tr {
        th {
          color: #1d211e;
          font-size: 15px;
          font-weight: 600;

          &:nth-child(1) {
            min-width: 180px;
            max-width: 1px;
            padding-left: 25px;
          }

          &:nth-child(2),
          &:last-child {
            text-align: center;
          }
        }

        td {
          color: #000;

          &:nth-child(1) {
            min-width: 180px;
            max-width: 1px;
            padding-left: 25px;
          }

          &:nth-child(2),
          &:last-child {
            text-align: center;
            vertical-align: middle;
          }

          .teamimgBox {
            display: flex;
            gap: 12px;
            align-items: center;

            .avatar {
              background: #fff;
              border-radius: 50%;
              .fakeLogo {
                border-radius: 50%;
                height: 45px;
                width: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: linear-gradient(var(--Main2), var(--Main3));
                span {
                  color: #fff;
                  text-transform: uppercase;
                }
              }

              img {
                border-radius: 50%;
                height: 45px;
                width: 45px;
                object-fit: cover;
              }

              .avatarAlt {
                width: 46px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 600;
                background: var(
                  --gradient-green,
                  linear-gradient(270deg, #65beac 0%, #3e8575 98.44%)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius: 50%;
              }
            }

            .detail {
              h5 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 2px;
              }

              span {
                color: #1e8c76;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

// neww
.promoLine_gap {
  margin-top: 33px;
  margin-bottom: 35px;
}

.promoLine_card {
  border-radius: 8px;
  padding: 18px 15px;
  position: relative;
  padding-left: 20px;
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 14px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    // background: linear-gradient(180deg, #65beac 0%, #3e8575 100%);
    background: #ff4848;
    border-radius: 14px 0px 0px 14px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 6px;
    font-weight: 500;
  }

  p {
    color: #777c88;
    font-weight: 300;
    margin-bottom: 27px;
    font-size: 14px;
    line-height: 20px;
  }

  :global(.btn) {
    background-color: #ff4848;
    border: 1px solid #ff4848;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    min-width: 122px;
    padding: 8px 0px;
    height: 40px;
    border-radius: 8px;
    line-height: 20px;

    &:active,
    &:focus {
      background-color: #ff4848;
      border: 1px solid #ff4848;
      color: #fff;
    }
  }
}

.notification_card {
  border-radius: 8px;
  padding: 18px 15px;
  position: relative;
  // padding-left: 20px;
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 14px;
  overflow: hidden;
  height: 100%;

  .notification_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 4px;
    flex-wrap: wrap;

    .notification_titleCount {
      display: flex;
      align-items: center;

      h2 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 500;
        margin-bottom: 0px;
      }

      .count {
        border: 1px solid #dcdcdc;
        display: inline-flex;
        min-width: 22px;
        height: 19px;
        font-size: 14px;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px #00000014;
        align-items: center;
        color: #1d211e;
        justify-content: center;
        font-weight: 500;
        line-height: 2px;
        padding: 10px 5px;
        margin-left: 10px;
      }
    }

    .notification_action {
      display: flex;
      align-items: center;
      gap: 3px;
      color: #9296a0;

      button {
        background: none;
        font-size: 11px;
        padding: 0;
        border: none;
        color: #9296a0;
      }
    }
  }

  .notification_item {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-bottom: 1px solid #1d211e33;
      padding-bottom: 14px;
      margin-bottom: 14px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }

      .notification_ImgPara {
        display: flex;
        // align-items: center;
        gap: 10px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .avatarAlt {
          min-width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          font-weight: 600;
          background: var(
            --gradient-green,
            linear-gradient(270deg, #65beac 0%, #3e8575 98.44%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          border-radius: 50%;
          line-height: normal;
        }
        .paraBox {
          .paraTop {
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 0px;
            color: #1d211e;
            line-height: 20px;

            b {
              font-weight: 400;
              color: #777c88;
            }

            span {
              font-size: 11px;
              color: #9296a0;
            }
          }

          .paraTitle {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 10px;
            color: #1d211e;
          }

          .paraBottom {
            font-size: 13px;
            color: #1d211e;
            font-weight: 400;
            margin-bottom: 0px;

            span {
              color: #3e8575;
            }
          }
        }
      }

      .notification_time {
        white-space: nowrap;
        background: none;
        font-size: 11px;
        padding: 0;
        border: none;
        color: #9296a0;
      }
    }
  }
}

.notification_TipCard {
  background-color: #fffcf3;
  position: relative;

  .tipImg {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 20%;
    pointer-events: none;
    width: 66px;
  }
}

.upDownSale_card {
  border-radius: 8px;
  padding: 18px 15px;
  position: relative;
  // padding-left: 20px;
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  overflow: hidden;

  h3 {
    color: #9296a0;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  h2 {
    color: #1d211e;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 11px;
  }

  .upDown_percentMain {
    display: flex;
    gap: 8px;
    align-items: center;

    .upDownPercentBox {
      border-radius: 6px;
      font-size: 15px;
      font-weight: 400;
      min-width: 62px;
      padding: 2px 9px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 3px;

      &.upPercentBox {
        color: #3e8575;
        background-color: #3e857533;
      }

      &.downPercentBox {
        color: #ff4848;
        background-color: #ff484833;
      }
    }

    p {
      color: #9296a0;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
