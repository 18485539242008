.modalCts {
  &.hide {
    display: none !important;
  }

  &.modalsignUp {
    :global(.modal-dialog) {
      :global(.modal-content) {
        border-radius: 8px !important;
      }
    }

    .successHeading {
      font-size: 26px !important;
      font-weight: 500 !important;
      color: #1D211E !important;
    }

    // .desc2{
    //   color: #959595 !important;
    // }
  }

  .divider_line {
    border: 1px solid rgba(0, 0, 0, 0.089);
    height: 1px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
  }

  :global(.modal-dialog) {
    &.hide {
      display: none;
    }

    :global(.modal-content) {
      border: none;
      background-color: #fff;
      border-radius: 20px;

      :global(.modal-header) {
        // background-image: linear-gradient(var(--Main2), var(--Main3));
        background-image: linear-gradient(#f1f1f1, #dddddd);
        text-align: center;
        color: #000;
        padding: 15px 20px;
        border-radius: 20px 20px 0px 0px;

        :global(.modal-title) {
          font-weight: normal;
          text-align: center;
          width: 100%;
          font-size: 18px;
        }

        .closeIcon {
          padding: 0;
          border: none;
          width: 30px;
          height: 30px;
          // background: #fff;
          background-image: linear-gradient(var(--Main2), var(--Main3));
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          // color: #787474;
          color: #fff;
          position: absolute;
          right: 10px;
          top: 10px;

          &.v2 {
            top: 8px;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }

      :global(.modal-body) {
        padding: 25px 25px;

        .verifyButton {
          position: absolute;
          top: 29px;
          right: 0px;
          padding: 0 13px;
          min-height: 38px;
          border-radius: 0 6px 6px 0;
          border: none;
          background-image: linear-gradient(var(--Main2), var(--Main3));
          color: #fff;
        }

        .bodyContainer {
          height: 315px;
          overflow-x: hidden;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 5px;
          }

          /* Customize the scrollbar thumb (the draggable part) */
          &::-webkit-scrollbar-thumb {
            background: #888;
            /* Color of the thumb */
            border-radius: 5px;
            /* Rounded corners */
          }

          /* Customize the scrollbar track when hovering over it */
          &::-webkit-scrollbar-track:hover {
            background: #555;
            /* Color on hover */
          }

          .serviceError {
            color: #555;
            text-align: center;
            font-size: 14px;
          }

          .appointmentBox {
            border: 2px solid #e3edf0;
            border-radius: 15px;
            // height: 152px;
            width: 100%;
            padding: 10px 2px;
            margin-bottom: 13px;
            cursor: pointer;

            &.active {
              background-color: #e3edf0;
              border: 2px solid #e3edf0;
            }

            img {
              display: block;
              margin: 0 auto;
              object-fit: cover;
              height: 70px;
              width: 75px;
              border-radius: 15px;
            }

            h5 {
              color: #525c62;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              margin: 0;
              margin-top: 10px;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              color: #1e8c76;
              text-align: center;
              margin: 0;
            }
          }

          // new design
          .selectedServices {
            background-color: #eff0f5;
            padding: 20px;
            border-radius: 8px;
            margin-bottom: 15px;

            .top {
              display: flex;
              justify-content: space-between;

              .left {
                .makup {
                  font-weight: 700;
                  font-size: 16px;
                  margin: 0;
                  margin-bottom: 4px;
                }

                .makeupTime {
                  color: #7f8699;
                  font-weight: 400;
                  font-size: 14px;
                  margin-bottom: 7px;
                }
              }

              .middle {
                width: 91.79px;
                margin-right: 100px;

                .cancel {
                  color: #ff4848;
                  font-weight: 500;
                  font-size: 16px;
                  margin: 0;
                  margin-bottom: 4px;
                  text-decoration: line-through;
                }

                .usd {
                  color: #1e8c76;
                  font-weight: 500;
                  font-size: 16px;
                }
              }
            }

            .bottom {
              .amnitiesBox {
                padding: 10px 15px;
                font-size: 14px;
                border-radius: 5px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                background-color: #fff;
                margin-bottom: 14px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 5px;
                color: #65beac;
              }

              .options {
                margin-bottom: 3px;

                select {
                  background-color: #fff;
                  color: var(--Main2);
                }
              }

              .packRadeem {
                margin-top: 10px;

                .checkPlace {
                  padding-left: 30px;

                  .check {
                    top: 3px;
                  }

                  .text {
                    font-size: 14px;
                    color: #7f8699;
                  }
                }
              }
            }
          }
        }

        // success promition popup
        .successImage {
          display: block;
          margin: 0 auto;
        }

        .errorImage {
          display: block;
          margin: 0 auto;
          height: 50px;
          width: 50px;
        }

        .successHeading {
          font-weight: 700;
          font-size: 18px;
          color: black;
          text-align: center;
          margin-top: 35px;
        }

        .clrDarkGreen {
          color: #3E8575 !important;
          font-weight: 400 !important;
          font-size: 16px !important;
        }

        .desc1,
        .desc2 {
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          color: #525c62;
          margin-top: 15px;

          span {
            font-weight: 700;
            font-size: 16px;
            color: #0c9b7e;
          }
        }

        .buttonGroup {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-top: 12px;

          button {
            border-radius: 8px;
            padding: 7px 0;
            min-width: 170px;
            border: none;
            box-shadow: 0 4px 4px 0 #00000026;
          }

          .change {
            background-color: #eff0f5;
            color: #7f8699;
          }

          .ok {
            color: #fff;
            background-image: linear-gradient(#65beacf7, #3e8575);
            padding: 8px 0;
          }
        }

        .logoBox {
          margin: 15px auto;
          display: flex;
          align-items: center;
          justify-content: center;

          .profileCheck {
            transform: translateX(40%);
          }

          // .box {
          //   height: 98px;
          //   width: 98px;
          //   border: 1px solid black;
          //   border-radius: 15px;
          //   // background: linear-gradient(180.35deg, #65beac 0.27%, #3e8575 98.11%);
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   font-size: 36px;
          //   font-weight: 500;
          //   margin-bottom: 20px;
          //   position: relative;
          //   transform: translateX(50%);
          //   &.v2 {
          //     transform: translateX(0%);
          //   }

          //   .bottomLogo {
          //     position: absolute;
          //     bottom: 0;
          //     right: 0;
          //     transform: translate(50%, 30%);
          //     box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
          //     background-color: #fff;
          //     border-radius: 10px;
          //     height: 31px;
          //     width: 31px;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     cursor: pointer;

          //     input[type="file"] {
          //       font-size: 1px;
          //       opacity: 0;
          //       position: absolute;
          //       z-index: -1;
          //     }
          //   }
          // }
          .box {
            height: 100px;
            width: 100px;
            border-radius: 15px;
            background-color: #eff0f5;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;

            &.v2 {
              background-color: #fff;
              border: 1px solid red solid #eff0f5;
            }

            input[type="file"] {
              display: none;
            }

            .boxImage {
              border-radius: 20px;
              width: 60px;
              height: 60px;
              object-fit: cover;
              fill: #7f8699;
            }

            .bottomLogo {
              position: absolute;
              bottom: 0;
              right: 0;
              transform: translate(50%, 30%);
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
              background-color: #fff;
              border-radius: 10px;
              height: 31px;
              width: 31px;
              display: flex;
              align-items: center;
              justify-content: center;

              label {
                img {
                  transform: translate(0, -20%);
                  cursor: pointer !important;
                }

                input {
                  font-size: 2px;
                  position: absolute;
                  top: 50%;
                  left: 0;
                  opacity: 0;
                }
              }
            }
          }

          .upload {
            font-weight: 600;
            font-size: 18px;
            color: #7f8699;
          }

          .boxDiv {
            width: 100%;
          }

          h2 {
            font-size: 18px;
            font-weight: 500;
          }

          h4 {
            color: #7f8699;
            font-size: 14px;
            font-weight: 400;
          }
        }

        // view promotion popup

        .promotionServices {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 17px;
          background-color: #eff0f5;
          border-radius: 12px;
          margin-bottom: 15px;

          li {
            font-weight: 600;
            font-size: 14px;
            color: #7f8699;
            display: flex;
            gap: 10px;

            .regular {
              color: red;
              font-weight: 500;
              text-decoration: line-through;
            }

            &:first-child {
              font-weight: 700;
              font-size: 16px;
              color: black;
            }

            &:nth-child(3) {
              color: #1e8c76;
            }

            &:last-child {
              font-weight: 700;
              font-size: 16px;
              color: #1e8c76;
            }
          }
        }

        .promoDesc {
          display: flex;
          // gap: 7px;
          align-items: center;
          margin-bottom: 15px;

          p {
            font-weight: 600;
            font-size: 14px;
            color: #7f8699;
            margin: 0;
            min-width: 156px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            color: black;
          }

          .spanBox {
            border-radius: 6px;
            padding: 3px 0;
            text-align: center;
            background-color: #1ab192;
            color: #fff;
            font-size: 14px;
            font-weight: 16px;
            min-width: 79px;
          }
        }

        // view history popup & other
        .cancelConfirm {
          background-color: rgba(255, 72, 72, 0.2);

          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: absolute;
          // top: 0;
          // left: 0;
          width: 100%;

          h2 {
            font-size: 15px;
            letter-spacing: 0.01rem;
            font-weight: 700;
            margin: 0;
          }
        }

        .contactInfo {
          display: flex;
          justify-content: space-between;

          &.cancelVersion {
            margin-top: 60px;
          }

          .left {
            display: flex;
            gap: 15px;
            align-items: center;

            .imageBox {
              background-color: #eff0f5;
              height: 45px;
              width: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;

              span {
                display: block;
              }
            }

            .content {
              h3 {
                font-weight: 600;
                font-size: 16px;
                color: black;
                margin: 0;
                margin-bottom: 5px;
              }

              p {
                font-weight: 400;
                font-size: 14px;
                // color: #1e8c76;
                color: #212529;
                margin: 0;
              }
            }
          }

          .right {
            display: flex;
            gap: 10px;
            align-items: center;

            .imageBox {
              background-color: #eff0f5;
              height: 45px;
              width: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              cursor: pointer;

              span {
                display: block;
              }
            }
          }
        }

        .divider {
          height: 3px;
          background-color: #eff0f5;
          width: 100%;
          margin: 14px 0;
        }

        .appointmentSection {
          .booked {
            margin-bottom: 17px;

            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: 3px;

              h5 {
                font-weight: 400;
                font-size: 16px;
                color: #7f8699;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 5px;

                &.v2 {
                  display: block;
                }

                &.green {
                  color: #1e8c76;
                }

                span {
                  font-weight: 600;
                  font-size: 16px;
                  color: black;

                  &.v2 {
                    display: block;
                  }
                }
              }

              .time {
                color: #7f8699;
                font-weight: 400;
                font-size: 14px;
                min-width: 160px;
                display: inline-block;
                text-align: end;
              }
            }

            .bottom {
              display: flex;
              align-items: center;
              gap: 20px;
              margin-bottom: 5px;

              p {
                color: #7f8699;
                font-weight: 400;
                font-size: 17px;
                margin: 0;
                margin-top: 5px;
                display: flex;
                align-items: center;
                gap: 5px;

                &.green {
                  color: #1e8c76;
                }
              }
            }
          }
        }

        .serviceSection {
          &.rebookScreen {
            overflow: visible;
          }

          .coupon {
            p {
              color: #1e8c76;
              cursor: pointer;
            }

            .couponBox {
              .couponInput {
                display: flex;
                width: 50%;
                gap: 10px;

                input {
                  background-color: #eff0f5;
                  border: 1px solid #eff0f5;
                  padding: 10px 15px;
                  font-size: 14px;
                  border-radius: 8px;
                  height: 45px;
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                }

                button {
                  font-weight: 600;
                  padding: 0px 15px;
                  font-size: 14px;
                  border-radius: 8px;
                  height: 45px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-decoration: none;
                  color: #fff;
                  background-image: linear-gradient(var(--Main2), var(--Main3));
                  border: none;
                  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1490196078);
                }
              }
            }
          }

          .total {
            border: 1px solid #e3edf0;
            position: relative;
            margin-top: 40px;
            margin-bottom: 10px;

            p {
              position: absolute;
              top: 0px;
              transform: translateY(-50%);
              right: 0;
              background: #fff;
              width: 262px;
              text-align: end;
              font-weight: 600;
              font-size: 20px;
              color: #1ab192;

              span {
                font-weight: 400;
                font-size: 20px;
                color: #7f8699;
              }
            }
          }

          .new_appoint_discount_box {
            .new_appoint_discountDiv {
              margin-bottom: 4px;

              h4 {
                font-size: 18px;
                font-weight: 600;
                border-bottom: 3px solid #eff0f5;
                padding-bottom: 8px;
                padding-top: 10px;
                margin-bottom: 2px;
              }

              .input_left,
              .right_left {
                label {
                  color: #181818;
                  font-weight: 600;
                  font-size: 14px;
                  margin-top: 10px;
                  margin-bottom: 4px;
                }
              }

              .input_left {

                :global(.form-control),
                :global(.form-select) {
                  background-color: #eff0f5;
                  border: 1px solid #eff0f5;
                  font-size: 15px;
                  border-radius: 6px;
                  height: 38px;
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                  color: #0e0e0e;

                  &:focus {
                    box-shadow: none;
                    background-color: #fff;
                    border: 1px solid var(--Main2);
                  }
                }
              }

              .right_left {
                :global(.form-control) {
                  background-color: #eff0f5;
                  border: 1px solid #eff0f5;
                  font-size: 15px;
                  border-radius: 6px;
                  height: 38px;
                  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                  color: #0e0e0e;

                  &:focus {
                    box-shadow: none;
                    background-color: #fff;
                    border: 1px solid var(--Main2);
                  }
                }
              }

              .line_withTotal_amt {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .line {
                  width: 100%;
                  border-top: 2px solid #e3edf0;
                }

                .total_amt {
                  text-wrap: nowrap;
                  font-size: 18px;
                  font-weight: 600;
                  color: #1ab192;
                  margin-left: 12px;

                  .total_time {
                    color: #132326;
                  }
                }
              }
            }
          }

          .packageAction {
            display: flex;
            align-items: center;
            gap: 13px;
            margin-top: 20px;
            margin-bottom: 10px;

            .packageName {
              font-size: 16px;
              font-weight: 600;
              margin: 0;
            }

            .deleteItem {
              margin-bottom: 3px;
              cursor: pointer;

              &.saleDelete {
                margin-top: 40px !important;
              }

              &.packageVersion {
                margin: 0;
              }
            }
          }

          .buttonBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            // gap: 14px;
            .addTeamBtn {
              display: flex;
              gap: 10px;
              min-width: 220px;
              height: 50px;
              margin-top: 18px;
              background-color: #1ab192;
              background-image: none;
              border-radius: 20px;
            }
          }

          .addTeamBtn {
            display: flex;
            background-image: none;
            gap: 10px;
            min-width: 100%;
            height: 50px;
            margin-top: 18px;
            background-color: #1ab192;
            background-image: none;
            border-radius: 20px;
            margin-bottom: 12px;
          }

          .selectedServices {
            background-color: #eff0f5;
            padding: 20px;
            border-radius: 8px;
            margin-bottom: 15px;

            .top {
              display: flex;
              justify-content: space-between;

              .left {
                .makup {
                  font-weight: 700;
                  font-size: 16px;
                  margin: 0;
                  margin-bottom: 4px;
                }

                .makeupTime {
                  color: #7f8699;
                  font-weight: 400;
                  font-size: 14px;
                }
              }

              .middle {
                width: 91.79px;
                margin-right: 100px;

                .cancel {
                  color: #ff4848;
                  font-weight: 500;
                  font-size: 16px;
                  margin: 0;
                  margin-bottom: 4px;
                  text-decoration: line-through;
                }

                .usd {
                  color: #1e8c76;
                  font-weight: 500;
                  font-size: 16px;
                }
              }
            }

            .bottom {
              .amnitiesBox {
                padding: 10px 15px;
                font-size: 14px;
                border-radius: 5px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                background-color: #fff;
                margin-bottom: 14px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 5px;
                color: #65beac;
              }

              .options {
                select {
                  background-color: #fff;
                  color: var(--Main2);
                }
              }

              .packRadeem {
                margin-top: 10px;

                .checkPlace {
                  padding-left: 30px;

                  .check {
                    top: 3px;
                  }

                  .text {
                    font-size: 14px;
                    color: #7f8699;
                  }
                }
              }
            }
          }

          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 6px;
            height: 3px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            /* Track background color */
          }

          &::-webkit-scrollbar-thumb {
            background: black;
            /* Thumb color */
            border-radius: 6px;
            /* Rounded corners of the thumb */
          }

          /* Works in Firefox */
          * {
            scrollbar-width: thin;
            /* Width of the scrollbar */
            scrollbar-color: black #f1f1f1;
            /* Thumb and track color */
          }

          &.bundle {
            table {
              thead {
                tr {
                  th {
                    font-weight: 600;
                    font-size: 14px;
                    color: #1e8c76;
                    min-width: 150px !important;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    &:first-child {
                      min-width: 190px !important;
                    }

                    &:last-child {
                      font-weight: 600;
                      font-size: 16px;
                      min-width: 80px;
                    }

                    &:nth-child(3) {
                      font-weight: 400;
                      font-size: 14px;
                      color: black;
                      min-width: 100px;
                      max-width: 100px;
                    }
                  }
                }
              }
            }
          }

          table {
            margin-bottom: 17px;

            thead {
              tr {
                th {
                  font-weight: 600;
                  font-size: 14px;
                  color: #1e8c76;
                  min-width: 100px;

                  &:nth-child(2) {
                    min-width: 70px;
                  }

                  &:nth-child(3) {
                    min-width: 120px;
                  }

                  &:last-child {
                    min-width: 120px;
                  }
                }
              }
            }

            tbody {
              tr {
                margin-bottom: 10px;
                align-items: flex-start;

                &:nth-child(odd) {
                  background-color: #eff0f5;
                  width: 100%;
                }

                &:last-child {
                  background-color: #e9f6f1;
                }

                td {
                  // min-width: 110px;
                  padding: 10px 0;

                  &:first-child {
                    min-width: 130px;
                    font-weight: 600;
                    font-size: 16px;
                    // padding-left: 13px;
                  }

                  &:last-child {
                    font-weight: 600;
                    font-size: 16px;
                    min-width: 80px;
                  }

                  &:nth-child(3) {
                    font-weight: 400;
                    font-size: 14px;
                    color: black;
                    min-width: 100px;
                    max-width: 100px;
                  }
                }
              }
            }
          }
        }

        .coupon {
          p {
            color: #1e8c76;
            cursor: pointer;
          }

          .couponBox {
            .couponInput {
              display: flex;
              width: 50%;
              gap: 10px;

              input {
                background-color: #eff0f5;
                border: 1px solid #eff0f5;
                padding: 10px 15px;
                font-size: 14px;
                border-radius: 8px;
                height: 45px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
              }

              button {
                font-weight: 600;
                padding: 0px 15px;
                font-size: 14px;
                border-radius: 8px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color: #fff;
                background-image: linear-gradient(var(--Main2), var(--Main3));
                border: none;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1490196078);
              }
            }
          }
        }

        .total {
          border: 1px solid #e3edf0;
          position: relative;
          margin-top: 40px;
          margin-bottom: 10px;

          p {
            position: absolute;
            top: 0px;
            transform: translateY(-50%);
            right: 0;
            background: #fff;
            width: 262px;
            text-align: end;
            font-weight: 600;
            font-size: 20px;
            color: #1ab192;

            span {
              font-weight: 400;
              font-size: 20px;
              color: #7f8699;
            }
          }
        }

        .priceType {
          display: flex;
          gap: 16px;
          align-items: center;
          margin-top: 25px;
          margin-bottom: 10px;
        }

        .serviceHeading {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }

        .appointmentNote {
          padding: 10px 0;
          margin: 15px 0;

          h5 {
            font-weight: 600;
            font-size: 14px;
            margin: 0;
            margin-bottom: 3px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            color: #525c62;
            margin-bottom: 0px;
          }
        }

        .stylist {
          h5 {
            font-weight: 600;
            font-size: 14px;
            color: #181818;
          }

          .stylistBox {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 100%;
            margin-bottom: 13px;

            .emptyImage {
              min-height: 50px;
              min-width: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-image: linear-gradient(#65beac, #3e8575);
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              border-radius: 50%;
            }

            .image {
              height: 50px;
              min-width: 50px;
              max-width: 50px;
              border-radius: 50%;
            }

            .content {
              width: 100%;

              h5 {
                font-weight: 600;
                font-size: 15px;
                color: #525c62;
                margin: 0;
                margin-bottom: 5px;
              }

              .roleList {
                list-style: none;
                display: flex;
                gap: 5px;
                margin: 0;
                padding: 0;
                flex-wrap: wrap;

                li {
                  font-weight: 400;
                  font-size: 14px;
                  color: #1e8c76;
                  margin: 0;
                }
              }

              p {
                font-weight: 400;
                font-size: 14px;
                color: #1e8c76;
                margin: 0;
              }
            }
          }
        }

        .scheduleAppointment {
          padding: 10px 0;

          h5 {
            font-weight: 600;
            font-size: 14px;
            color: #181818;
            margin-top: 7px;
          }

          .appointmentSchedule {
            display: flex;
            gap: 30px;

            .date {
              display: flex;
              gap: 5px;
              align-items: center;

              span {
                color: #7f8699;
                font-weight: 400;
                font-size: 16px;
              }
            }

            .time {
              display: flex;
              gap: 5px;
              align-items: center;

              img {
                height: 20px;
                width: 20px;
              }

              span {
                color: #7f8699;
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
        }

        .rebook {
          h5 {
            font-weight: 600;
            font-size: 14px;
            color: #181818;
          }
        }

        .feedbackSection {
          h5 {
            font-weight: 600;
            font-size: 14px;
            color: #181818;
          }

          .star {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              margin-bottom: 4px;
            }

            .date {
              font-weight: 400;
              font-size: 14px;
              color: #7f8699;
            }
          }

          p {
            color: #132326;
            font-weight: 400;
            font-size: 16px;
          }

          .replyBtn {
            padding: 8px 20px;
            border-radius: 6px;
            background-color: #eff0f5;
            box-shadow: 0 1px 3px #00000040;
            font-weight: 500;
            font-size: 14px;
            color: #509f8e;
            border: none;
          }
        }

        .gotButton {
          width: 100%;
          margin: 0 auto;
          margin-top: 30px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;

          &.v2 {
            margin-top: 8px;
            margin-bottom: 0;
          }

          button {
            border-radius: 6px;
            background-image: linear-gradient(#65beac, #3e8575);
            box-shadow: 0 1px 3px #00000040;
            padding: 8px 60px;
            border: none;
            color: #fff;
            margin-bottom: 4px;

            &.cancel {
              background-color: #eff0f5;
              background-image: none;
              color: #7f8699;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }

        // client Modal
        .clientAdd {
          h2 {
            border-bottom: 1px solid #1ab192;
            font-weight: 600;
            font-size: 14px;
            color: #1ab192;
            padding-bottom: 4px;
          }

          .addMore {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            p {
              color: #1ab192;
              font-weight: 600;
              font-size: 16px;
              cursor: pointer;
            }
          }
        }

        // change password modal
        .change {
          .title {
            text-align: center;
            font-weight: 600;
            font-size: 24px;
            margin: 0;

            &.v2 {
              margin: 0;
            }
          }

          .desc {
            text-align: center;
            font-size: 16px;
            margin-bottom: 25px;
            color: #7f8699;
          }

          .numberTitle {
            font-size: 13px;
            font-weight: 400;
            color: #7f8699;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              margin-left: 5px;
            }
          }

          .entry {
            width: 95%;
            margin: 0 auto;

            img {
              transform: translate(0%, -32%);
            }

            input {
              background-color: #fff;
              border: 1px solid #969393;
              box-shadow: none;

              &:hover {
                border: 1px solid green;
              }
            }
          }

          .otpScreen {
            display: flex;
            width: 95%;
            margin: 0 auto;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 10px;

            input {
              background-color: transparent;
              height: 50px;
              text-align: center;
              font-size: 16px;
              margin-right: 10px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
              border: 1px solid #eff0f5;
              padding: 10px 15px;
              border-radius: 8px;
              width: 50px !important;

              &:focus {
                outline: none;
                border: 1px solid var(--Main2);
                box-shadow: none;
              }
            }

            .varifyBtn {
              margin-top: 0;

              button {
                min-width: 150px;
                margin-right: 45px;
              }
            }

            .resend {
              font-size: 13px;
              font-weight: 400;
              color: #7f8699;
              display: flex;
              margin-top: 5px;

              span {
                color: #1ab192;
                text-decoration: underline;
                font-weight: 600;
                cursor: pointer;
                margin-left: 10px;
              }
            }
          }

          .buttonGroup {
            display: flex;
            width: 95%;
            margin: 0 auto;
            align-items: center;
            justify-content: center;

            button {
              min-width: 48%;
            }
          }

          .eye {
            transform: translate(-50%, 80%);
          }

          .changeBtn {
            width: 95%;
            margin: 0 auto;
            min-width: 95%;
            margin-bottom: 15px;
          }

          .desc {
            color: #666666;
            font-size: 13px;
            width: 95%;
            margin: 0 auto;
            margin-top: 5px;
            margin-bottom: 20px;
            word-spacing: 0.02px;
          }
        }

        // Customar Modal

        .slot {
          margin: 0;
          border-radius: 10px;
          overflow: hidden;
          background-color: #fff;
          // transform: translateY(-20px);

          .top {
            background-image: linear-gradient(var(--Main2),
                var(--Main3)) !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 14px;

            .downIcon {
              color: #fff;
              font-size: 18px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;

              &.disable {
                transform: rotate(180deg);
              }
            }

            span {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.1px;
              color: #fff;
            }
          }

          .bottom {
            &.disable {
              display: none;
            }

            .entry {
              padding: 10px 25px;
              background-color: #fff;
              font-size: 16px;
              font-weight: 400;
            }

            .entry:nth-child(even) {
              background-color: #eff7fe;
            }

            .content {
              // padding: 10px 16px;

              &.v2 {
                padding: 10px 16px 0 16px;

                .teamBox {
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  margin-bottom: 10px;
                }
              }

              .description {
                display: flex;
                align-items: center;
                gap: 12px;

                .fakeLogo {
                  min-height: 50px;
                  min-width: 50px;
                  border-radius: 50%;
                  background-image: linear-gradient(var(--Main2), var(--Main3));
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                img {
                  height: 50px;
                  width: 50px;
                  border-radius: 50%;
                }

                .customerDesc {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  // margin-top: 12px;

                  h3 {
                    font-size: 16px;
                    font-weight: 500;
                    margin: 0;
                    margin-bottom: 2px;
                    color: #000;
                  }

                  p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #0c9b7e;
                  }
                }
              }

              .service {
                margin-top: 8px;
                display: flex;
                gap: 30px;
                width: 100%;

                .serviceEntry {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  margin-bottom: 5px;
                  min-width: 126px;

                  img {
                    height: 24px;
                    width: 24px;

                    &.v2 {
                      height: 19px;
                      // width: 20px;
                    }
                  }

                  span {
                    font-weight: 400;
                    font-size: 14px;
                    color: #525c62;
                  }
                }
              }

              .by_services_flex {
                display: flex;
                justify-content: space-between;
              }

              .payStatus_channel {
                padding: 13px 0px;

                ul {
                  padding-left: 0px;
                  margin-bottom: 0px;
                  list-style: none;

                  li {
                    font-weight: 500;
                    font-size: 15px;
                    display: flex;
                    gap: 4px;
                    padding: 3px 0px;
                    margin-right: 10px;

                    .left_pack {
                      color: #1e8c76;
                    }

                    .right_pack {
                      color: #000;
                    }
                  }
                }
              }

              .priceField {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .price {
                  color: #7f8699;
                  font-size: 14px;
                  font-weight: 400;
                }

                .priceNum {
                  color: #0c9b7e;
                  font-weight: 700;
                  font-size: 16px;
                }
              }

              .cta {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0;
                gap: 10px;
                margin-bottom: 10px;

                button {
                  background-color: #eff0f5;
                  border: none;
                  padding: 8px 2px;
                  border-radius: 10px;
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 20%);
                  width: 100%;
                  color: #7f8699;
                  font-size: 12px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 7px;

                  span {
                    color: #1e8c76;
                    font-weight: 500;
                    font-size: 14px;
                  }

                  .phone {
                    color: #0c9b7e;
                    font-size: 16px;
                  }

                  img {
                    width: 16px;
                    height: 16px;
                  }

                  &.v2 {
                    background-color: #eff0f5;
                    padding: 8px 10px;

                    &.active {
                      background-image: linear-gradient(#65beac, #3e8575);
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }

        // sale profile
        .usdPercent {
          display: flex;
          background: #efeef3;
          padding: 6px;
          border-radius: 8px;
          justify-content: space-between;
          margin-top: 30px;
          align-items: center;
          padding: 6px 10px;

          :global(button) {
            border: transparent;
            background: linear-gradient(269.98deg,
                #65beac 0.02%,
                #3e8575 98.42%);
          }

          span {
            cursor: pointer;
            color: #7f8699;
            font-size: 20px;
            font-weight: 400;

            &.active {
              background: linear-gradient(269.98deg,
                  #65beac 0.02%,
                  #3e8575 98.42%);
              color: #fff;
              padding: 0 7px;
              border-radius: 5px;
            }
          }
        }
      }

      :global(.modal-footer) {
        padding: 8px 12px;
        justify-content: center;

        :global(.btn) {
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          border: none;
          min-width: 150px;
          padding: 10px 15px;
          border-radius: 10px;

          &.actionBtn {
            background-image: linear-gradient(var(--Main2), var(--Main3));
            color: #fff;

            &.disabled {
              opacity: 0.8;
            }
          }

          &.cancelBtn {
            color: #7f8699;
            background-color: #eff0f5;
          }
        }
      }
    }
  }

  &.redesign_modalCts {
    :global(.modal-dialog) {
      :global(.modal-content) {
        border-radius: 10px;
        background: #fbfbfb;

        :global(.modal-header) {
          border: none;
          background: #fbfbfb;

          :global(.modal-title) {
            font-size: 25px;
            font-weight: 500;
          }

          .closeIcon {
            color: #3E8575;
            position: absolute;
            right: -18px;
            top: -16px;
            font-size: 20px;
            background-image: none;
            box-shadow: 0px 3px 15px 0px #1D211E1A;
            background: #fff;
          }
        }
      }
    }
  }

  &.workingModal_width {
    :global(.modal-dialog) {
      min-width: 640px;
    }
  }
}

.modal_para {
  font-size: 14px;
}

.backSignUp_box {
  position: relative;
  left: 0px;
  margin-bottom: 4px;

  .backBtn {
    // position: absolute;
    top: -29px;
    left: -30px;
    border: 1px solid #56a897;
    border-radius: 17px;
    padding: 4px;
    background-color: #84f16914;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    :global(svg) {
      font-size: 25px;
      color: #56a897;
    }
  }
}

.period {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0;

  &.reVersion {
    justify-content: center;
    gap: 90px;
  }

  .left,
  .right {
    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #7f8699;
      margin: 0;
      margin-bottom: 3px;
      text-align: center;
    }

    p {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .middle {
    color: #eff0f5;
  }
}

.verfiyMailPassword {
  :global(.btn) {
    padding-left: 0px;
    font-size: 17px;
    padding-bottom: 2px;
    color: #4d9c8b;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      border-color: transparent;
    }
  }
}

.by_services_imgBox {
  display: flex;
  flex-direction: column;

  .labels {
    font-size: 14px;
    font-weight: 500;
  }

  .by_services_main {
    display: flex;
    margin-top: 3px;
    align-items: center;
  }

  .img_text {
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;
    background-image: linear-gradient(var(--Main2), var(--Main3));
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .details {
    margin-left: 9px;

    h3 {
      font-size: 14px;
      margin-bottom: 2px;
      font-weight: 400;
      color: #65beac;
    }

    p {
      margin-bottom: 0;
      line-height: 16px;
      font-size: 13px;
    }
  }
}

.bookingModalHead_imgBox {
  .description {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;

    .fakeLogo {
      min-height: 50px;
      min-width: 50px;
      border-radius: 50%;
      // background-image: linear-gradient(#c7c7c7, #676767);
      background-image: linear-gradient(#2d294d, #676767);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }

    .customerDesc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // margin-top: 12px;

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 2px;
        color: #000000;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #0c9b7e;
      }
    }
  }
}

.viewHistoryHead_imgBox {
  .person_infos {
    .left {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: center;

      .imageBox {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-image: linear-gradient(#2d294d, #676767);

        span {
          display: block;
        }
      }

      .content {
        h3 {
          font-weight: 600;
          font-size: 16px;
          color: rgb(255, 255, 255);
          margin: 0;
          margin-bottom: 5px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          margin: 0;
        }
      }
    }
  }

  .service {
    margin-top: 8px;

    .serviceEntry {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 5px;

      img {
        height: 24px;
        width: 24px;

        &.v2 {
          height: 19px;
          // width: 20px;
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: #525c62;
      }
    }
  }
}

.by_services_ClrBorder {
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  padding: 5px 0px;
  padding-left: 10px;
  margin-top: 4px;
}

.card_phoneMain {
  margin-top: 14px;
  padding-left: 10px;
}

.disablePhoneInput {
  :global(.PhoneInput) {
    :global(.PhoneInputInput) {
      font-weight: 400;
      font-size: 14px;
      color: #525c62;
      background-color: transparent;
    }

    :global(.PhoneInputCountryIcon--border) {
      :global(.PhoneInputCountryIconImg) {
        width: 100% !important;
        height: 100% !important;
      }
    }

    :global(.PhoneInputCountrySelectArrow) {
      display: none;
    }

    :global(.PhoneInputCountrySelect) {
      &:disabled {
        opacity: 0 !important;
      }
    }
  }
}

.servicePhoneInput {
  :global(.PhoneInput) {
    :global(.PhoneInputInput) {
      border: none !important;
      font-weight: 400;
      font-size: 14px;
      color: #525c62;
      background-color: transparent;
    }

    :global(.PhoneInputCountryIcon--border) {
      :global(.PhoneInputCountryIconImg) {
        width: 100% !important;
        height: 100% !important;
      }
    }

    :global(.PhoneInputCountrySelectArrow) {
      display: none;
    }

    :global(.PhoneInputCountrySelect) {
      &:disabled {
        opacity: 0 !important;
      }
    }
  }
}

.selected {
  background-color: #e6f7ff;
  /* Change to the desired background color for selected items */
}

.ModalHeaderBG {
  :global(.modal-dialog) {
    :global(.modal-content) {
      :global(.modal-header) {
        background-image: none !important;
        border-bottom: none;

        :global(.modal-title) {
          font-weight: 600;
          text-align: left;
          width: 100%;
          font-size: 22px;
          gap: 20px;
          display: flex;
          align-items: center;
          background: #3e8575;
          background: -webkit-linear-gradient(to right,
              #3e8575 50%,
              #65beac 50%);
          background: -moz-linear-gradient(to right, #3e8575 50%, #65beac 50%);
          background: linear-gradient(to right, #3e8575 50%, #65beac 50%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .title {
            display: flex;
            gap: 10px;
            align-items: center;
          }

          .editIcon {
            cursor: pointer;
          }
        }
      }

      :global(.modal-body) {
        .searchBox {
          position: relative;

          :global(.form-control) {
            background-color: #e9f6f1;
            border-color: #e9f6f1;
            height: 55px;
            border-radius: 8px;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 12px 46px;
            color: #1ab192;
            font-weight: 400;
            font-size: 16px;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            &::placeholder {
              color: #1ab192;
            }
          }

          svg {
            position: absolute;
            top: 50%;
            left: 5px;
            color: #1ab192;
            transform: translate(50%, -50%);
            font-size: 22px;
          }
        }

        .buttonBox {
          margin: 0;
          padding: 0;
          border: 1px solid #b8dcd8;
          border-radius: 10px;
          padding: 2px;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          // gap: 30px;
          margin-top: 10px;

          li {
            button {
              border-radius: 8px;
              height: 39px;
              width: 133px;
              color: #509f8e;
              border: none;
              background: transparent;

              &.active {
                background-image: linear-gradient(#65beac, #3e8575);
                color: #fff;
              }
            }
          }
        }

        .add {
          font-size: 14px;
          font-weight: 400;
          color: #7f8699;
          border: none !important;
          width: 100%;
          text-align: center;
          margin-top: 12px;

          a {
            text-decoration: underline;
            color: #65beac;
          }
        }

        .makeupHeading {
          margin-top: 26px;

          .titleMakeup {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 8px;
            border-bottom: 1px solid #eff0f5;
            margin-bottom: 20px;

            .heading {
              .serviceHeading {
                color: red !important;
              }
            }

            h2 {
              margin-bottom: 0px;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              color: #132326;

              span {
                color: #7f8699;
                font-weight: 400;
                font-size: 16px;
                margin-left: 4px;
              }
            }

            svg {
              font-size: 20px;
              color: #7f8699;
            }
          }

          .headingBox {
            height: 62px;
            display: flex;
            align-items: center;
            background-color: #eff0f5;
            border-radius: 8px;
            padding: 20px 18px;
            margin-top: 10px;
            justify-content: space-between;

            h3 {
              font-size: 18px;
              font-weight: 700;
              line-height: 28px;
              margin-bottom: 0px;
              color: #132326;
            }

            .rightButton {
              display: flex;
              align-items: center;
              gap: 8px;

              .plusButton {
                box-shadow: 0px 1px 3px 0px #00000040;
                background-color: #eff0f5;
                padding: 7px;
                height: 40px;
                display: flex;
                align-items: center;
                min-width: 112px;
                justify-content: space-between;
                color: #7f8699;
                border-radius: 8px;

                button {
                  border: none;
                  font-size: 28px;
                  color: #7f8699;
                  background: transparent;
                  padding: 10px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              .spanValue {
                box-shadow: 0px 1px 3px 0px #00000040;
                background-color: #eff0f5;
                padding: 7px;
                height: 40px;
                display: flex;
                align-items: center;
                min-width: 45px;
                justify-content: center;
                color: #7f8699;
                border-radius: 8px;
              }
            }
          }
        }

        .bundleList {
          margin-bottom: 10px;

          .bundleItem {
            background: #eff0f5;
            background-color: #eff0f5;
            padding: 12px 20px;
            border-radius: 8px;
            margin-bottom: 10px;
            width: 100%;

            .bundleDesc {
              display: flex;
              align-items: center;
              justify-content: space-between;
              align-items: center;

              .bundleName {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0;
              }

              .bundlePrice {
                color: #1e8c76;
                font-weight: 500;
                font-size: 18px;
              }
            }

            .promotion_item {
              margin-top: 10px;

              .options {
                select {
                  background-color: #fff;
                  color: var(--Main2);
                }
              }
            }
          }
        }

        .selectedHeading {
          font-size: 16px;
          font-weight: 600;
          margin-top: 15px;
          margin-bottom: 0;
        }

        .teamBox {
          background-color: #eff0f5;
          padding: 10px 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 10px;
          box-shadow: 1px 2px 3px rgba(6, 46, 36, 0.1019607843);
          border-radius: 12px;
          position: relative;
          width: 100%;
          margin-top: 14px;

          .left {
            background-image: linear-gradient(var(--Main2), var(--Main3));
            max-height: 45px;
            max-width: 45px;
            padding: 12px 14px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              color: #fff;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .memberName {
              font-size: 14px;
              font-weight: 600;
              line-height: 17px;
              margin: 0;
              margin-bottom: 3px;
            }

            .priceDesc {
              color: rgba(0, 0, 0, 0.687);
              width: 100%;
              padding: 0;
              margin: 0;
              font-size: 13px;
              font-weight: 600;
            }
          }
        }

        .saleDelete {
          margin-top: 40px;
          cursor: pointer;
        }

        .bookingHeading {
          margin-top: 0;
          padding: 0;

          .titleMakeup {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 8px;
            border-bottom: 1px solid #eff0f5;
            margin-bottom: 20px;

            h2 {
              margin-bottom: 0px;
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              color: #132326;

              span {
                color: #7f8699;
                font-weight: 400;
                font-size: 16px;
                margin-left: 4px;
              }
            }

            svg {
              font-size: 20px;
              color: #7f8699;
            }
          }

          .headingBox {
            height: 62px;
            display: flex;
            align-items: center;
            background-color: #eff0f5;
            border-radius: 8px;
            padding: 20px 18px;
            margin-top: 10px;
            justify-content: space-between;

            h3 {
              font-size: 18px;
              font-weight: 700;
              line-height: 28px;
              margin-bottom: 0px;
              color: #132326;
            }
          }
        }

        .dateBox {
          label {
            width: 100%;
          }

          input,
          select {
            text-align: center;
            position: relative;
          }

          .scheduleIcon {
            position: absolute;
            top: 10px;
            left: 12px;
          }

          .clockIcon {
            position: absolute;
            top: 13px;
            left: 12px;
            height: 18px;
            width: 18px;
          }
        }

        .addClient {
          display: flex;
          border: 2px solid #b8dcd8;
          padding: 20px;
          border-radius: 20px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &.noBorder {
            border: none;
            padding: 0;
            margin-bottom: 18px;
          }

          .left {
            display: flex;
            align-items: center;
            gap: 20px;

            .userInfo {

              h4,
              h3 {
                font-weight: 400;
                font-size: 16px;
                color: #7f8699;
                margin: 0;
                margin-bottom: 5px;
              }

              h3 {
                color: #132326;
              }

              p {
                font-weight: 600;
                font-size: 16px;
                color: #0c9b7e;
                margin: 0;
              }
            }
          }

          .right {
            img {
              cursor: pointer;
            }
          }
        }

        .selectedClient {
          background-color: #eff0f5;
          padding: 20px;
          border-radius: 8px;
          margin: 15px 0;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              display: flex;
              gap: 15px;
              align-items: center;

              .userInfo {
                h4 {
                  color: #132326;
                  font-weight: 600;
                  font-size: 16px;
                  margin: 0;
                  margin-top: 5px;
                  margin-bottom: 3px;
                }

                p {
                  color: #1e8c76;
                  margin: 0;
                  margin-bottom: 6px;
                  font-weight: 400;
                  font-size: 14px;
                  margin: 0;
                }
              }
            }

            .right {
              a {
                color: #7f8699;
                font-weight: 500;
                font-size: 16px;
              }
            }
          }

          .packInfo {
            width: 100%;
            background-color: #ffffffec;
            padding: 12px;
            border-radius: 8px;
            margin-top: 18px;

            h4 {
              font-size: 13px;
              margin: 0;
              margin-top: 10px;
            }

            h5 {
              font-size: 13px;
              margin: 0;
              background: linear-gradient(to right, #3e8575 50%, #65beac 50%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              width: 400px;

              li {
                font-size: 12px;
              }
            }
          }

          .bottom {
            .tags {
              padding: 5px 15px 5px 15px;
              border-radius: 20px;
              background-color: rgba(184, 220, 216, 0.454);
              font-weight: 400;
              font-size: 16px;
              color: #1e8c76;
              margin-top: 14px;
              display: inline-block;
            }
          }
        }

        .selectedServices {
          background-color: #eff0f5;
          padding: 20px;
          border-radius: 8px;
          margin-bottom: 15px;

          .top {
            display: flex;
            justify-content: space-between;

            .left {
              .makup {
                font-weight: 700;
                font-size: 16px;
                margin: 0;
                margin-bottom: 4px;
              }

              .makeupTime {
                color: #7f8699;
                font-weight: 400;
                font-size: 14px;
              }
            }

            .middle {
              width: 91.79px;

              .cancel {
                color: #ff4848;
                font-weight: 500;
                font-size: 16px;
                margin: 0;
                margin-bottom: 4px;
                text-decoration: line-through;
              }

              .usd {
                color: #1e8c76;
                font-weight: 500;
                font-size: 16px;
              }
            }

            .right {}
          }

          .bottom {
            .amnitiesBox {
              padding: 10px 15px;
              font-size: 14px;
              border-radius: 5px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
              background-color: #fff;
              margin-bottom: 7px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 5px;
              color: #65beac;
            }

            .options {
              select {
                background-color: #fff;
                color: var(--Main2);
              }
            }

            .packRadeem {
              margin-top: 10px;

              .checkPlace {
                padding-left: 30px;

                .check {
                  top: 3px;
                }

                .text {
                  font-size: 14px;
                  color: #7f8699;
                }
              }
            }
          }
        }

        .addTeamBtn {
          display: flex;
          gap: 10px;
          min-width: 100%;
          height: 50px;
          margin-top: 18px;
          background-color: #1ab192;
          background-image: none;
          border-radius: 20px;

          &.rebookVersion {
            min-width: 150px !important;
          }
        }

        .coupon {
          p {
            color: #1e8c76;
            cursor: pointer;
          }

          .couponBox {
            .couponInput {
              display: flex;
              width: 50%;
              gap: 10px;

              input {
                background-color: #eff0f5;
                border: 1px solid #eff0f5;
                padding: 10px 15px;
                font-size: 14px;
                border-radius: 8px;
                height: 45px;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
              }

              button {
                font-weight: 600;
                padding: 0px 15px;
                font-size: 14px;
                border-radius: 8px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color: #fff;
                background-image: linear-gradient(var(--Main2), var(--Main3));
                border: none;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1490196078);
              }
            }
          }
        }

        .total {
          border: 1px solid #e3edf0;
          position: relative;
          margin-top: 40px;
          margin-bottom: 10px;

          p {
            position: absolute;
            top: 0px;
            transform: translateY(-50%);
            right: 0;
            background: #fff;
            width: 262px;
            text-align: end;
            font-weight: 600;
            font-size: 20px;
            color: #1ab192;

            span {
              font-weight: 400;
              font-size: 20px;
              color: #7f8699;
            }
          }
        }

        // profile popup

        .profileInfo {
          .top {
            display: flex;
            gap: 24px;

            .left {
              position: relative;
              height: 150px;
              width: 150px;

              .userFrame {
                height: 100%;
                width: 100%;
              }

              .upload {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 31px;
                width: 31px;
                transform: translate(0%, 10%);
                background-color: #fff;
                padding: 6px;
                border-radius: 10px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.251);
                cursor: pointer;
              }

              input[type="file"] {
                display: none;
              }
            }

            .right {
              h2 {
                font-weight: 600;
                font-size: 18px;
                color: #132326;
                display: flex;
                gap: 8px;
                align-items: center;
                margin: 0;
                margin-bottom: 2px;
              }

              .profileDate {
                color: #7f8699;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 20px;
              }

              .basic {
                font-weight: 400;
                font-size: 16px;
                color: #132326;
                margin: 0;
                display: flex;
                // align-items: center;
                gap: 10px;

                .icon {
                  height: 20px;
                  width: 20px;
                  color: #1ab192;
                }
              }
            }
          }

          .bottom {
            margin-top: 18px;
            display: flex;
            gap: 12px;

            .tags {
              padding: 5px 15px 5px 15px;
              border-radius: 8px;
              background-color: #eff0f5;
              font-weight: 400;
              font-size: 16px;
              color: #1e8c76;
            }
          }
        }

        .bundleScreen {
          .bundleDesc {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              color: #1e8c76;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }

        .appointmentCard {
          margin-bottom: 20px;
          position: relative;
          display: flex;
          border-radius: 10px;
          width: 100%;
          overflow: hidden;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.18);

          .completeTag {
            position: absolute;
            top: 0;
            right: 0;
            background-image: linear-gradient(#65beac, #3e8575);
            padding: 7px 25px;
            border-radius: 0 0 0 10px;

            span {
              color: #fff;
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0.02px;
            }
          }

          .left {
            background-image: linear-gradient(#65beac, #3e8575);
            width: 16px;
          }

          .right {
            padding: 25px;
            background-color: #eff0f5;
            width: calc(100% - 16px);

            .top {
              h5 {
                font-weight: 700;
                font-size: 18px;
                color: #132326;
                margin: 0;
                margin-bottom: 5px;
              }

              p {
                color: #1e8c76;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
              }
            }

            .bottomInfo {
              padding: 15px 25px;
              background-color: #fff;
              border-radius: 10px;
              margin-top: 13px;

              .entry {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5 {
                  font-weight: 700;
                  font-size: 16px;
                  margin: 0;
                  margin-bottom: 8px;
                  color: black;
                  letter-spacing: 0.02px;
                }

                p {
                  color: #7f8699;
                  font-size: 14px;
                  font-weight: 400;
                  margin: 0;
                }

                .cancel {
                  font-weight: 500;
                  font-size: 16px;
                  color: #ff4848;
                  margin: 0;
                  text-decoration: line-through;
                }

                .ok {
                  font-weight: 700;
                  font-size: 16px;
                  color: #1e8c76;
                }
              }
            }
          }
        }

        .packageAction {
          display: flex;
          align-items: center;
          gap: 13px;
          margin-top: 20px;
          margin-bottom: 10px;

          .packageName {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
          }

          .deleteItem {
            margin-bottom: 3px;
            cursor: pointer;

            &.packageVersion {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.rescheduleBooking_pop {
  .selectedServices {
    background-color: #eff0f5;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;

    .top {
      display: flex;
      justify-content: space-between;

      .left {
        .makup {
          font-weight: 700;
          font-size: 16px;
          margin: 0;
          margin-bottom: 4px;
        }

        .makeupTime {
          color: #7f8699;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .middle {
        width: 91.79px;

        .cancel {
          color: #ff4848;
          font-weight: 500;
          font-size: 16px;
          margin: 0;
          margin-bottom: 4px;
          text-decoration: line-through;
        }

        .usd {
          color: #1e8c76;
          font-weight: 500;
          font-size: 16px;
          text-wrap: nowrap;
          margin-bottom: 3px;
        }
      }
    }

    .bottom {
      .amnitiesBox {
        padding: 10px 15px;
        font-size: 14px;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        background-color: #fff;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        color: #65beac;
      }

      .options {
        select {
          background-color: #fff;
          color: var(--Main2);
        }
      }

      .packRadeem {
        margin-top: 10px;

        .checkPlace {
          padding-left: 30px;

          .check {
            top: 3px;
          }

          .text {
            font-size: 14px;
            color: #7f8699;
          }
        }
      }
    }
  }

  .addClient {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eff0f5;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 16px;

    .left {
      display: flex;
      align-items: center;
      gap: 12px;

      img {
        width: 74px;
      }

      .userInfo {
        text-align: left;

        h4,
        h3 {
          font-weight: 600;
          font-size: 16px;
          color: #0e0e0e;
          margin: 0;
          margin-bottom: 5px;
        }

        h3 {
          color: #0e0e0e;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          color: #0c9b7e;
          margin-bottom: 1px;
        }
      }
    }

    .right {
      img {
        cursor: pointer;
      }
    }
  }
}

.serviceListCard {
  &:global(.accordion) {
    :global(.accordion-item) {
      border: none;

      :global(.accordion-header) {
        :global(.accordion-button) {
          padding: 14px 0px;
          font-size: 17px;
          font-weight: 600;
          border-bottom: 1px solid #d1d1d1;
          border-radius: 0px;
          background: transparent;
          color: #000;

          &:global(.collapsed) {
            &::after {
              content: "+";
              transform: rotate(0);
            }
          }

          &::after {
            display: flex;
            align-items: center;
            font-size: 20px;
            content: "-";
            background-image: none !important;
            transform: rotate(0);
            transition: content 3s;
          }

          &:focus {
            box-shadow: none;
            background: transparent;
          }

          span {
            color: #7f8699;
            font-size: 14px;
            margin-left: 5px;
            font-weight: 400;
          }
        }
      }

      :global(.accordion-collapse) {
        :global(.accordion-body) {
          padding: 0px 0px 0px 0px;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              display: flex;
              width: 100%;
              background: #eff0f5;
              border-radius: 10px;
              padding: 15px 15px;
              margin-bottom: 10px;

              .bodyLeft {
                width: 50%;

                .list {
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  li {
                    padding: 0;
                    margin: 0px;
                    color: #7f8699;
                    font-size: 14px;
                  }
                }

                h5 {
                  margin-bottom: 4px;
                  font-size: 16px;
                }

                p {
                  margin: 0px;
                  color: #7f8699;
                  font-size: 14px;
                }
              }

              .bodyRight {
                display: flex;
                justify-content: right;
                width: 50%;
                align-items: center;

                &.v2 {
                  gap: 10px;

                  .price {
                    display: flex;
                    flex-direction: column;

                    .correct {
                      font-weight: 700;
                      font-size: 16px;
                      color: #1e8c76;
                    }

                    .cancel {
                      color: #ff4848;
                      text-decoration: line-through;
                      font-weight: 500;
                    }
                  }
                }

                .btnGroup {
                  display: flex;
                  box-shadow: 0 0 2px #b0b0b0;
                  border-radius: 5px;
                  align-items: center;

                  :global(.btn) {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    color: #7f8699;
                    border: none;
                  }
                }

                .signleBtn {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 18px;
                  color: #7f8699;
                  border: none;
                  box-shadow: 0 0 2px #b0b0b0;
                  border-radius: 5px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .catHeader {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }

  .insideAccordion {
    padding: 0 15px;

    :global(.accordion) {
      :global(.accordion-item) {
        :global(.accordion-header) {
          :global(.accordion-button) {
            h5 {
              font-size: 16px;
            }
          }
        }

        :global(.accordion-collapse) {
          :global(.accordion-body) {
            padding: 0px;
          }
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        width: 100%;
        background: #eff0f5;
        border-radius: 10px;
        padding: 15px 15px;
        margin-bottom: 10px;
        margin-top: 14px;

        .bodyLeft {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .category_selected {
            display: flex;
            gap: 10px;

            h5 {
              font-size: 15px;
              color: #000;
              margin: 0px;

              &.serviceName {
                min-width: 145px;
                max-width: 145px;
                font-weight: 600;
              }
            }
          }

          .price_time {
            display: flex;
            gap: 10px;
            margin-right: 14px;
            text-align: end;

            h5 {
              font-size: 15px;
              color: #000;
              margin-bottom: 2px;
              min-width: 89px;
            }
          }

          .list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              padding: 0;
              margin: 0px;
              color: #7f8699;
              font-size: 14px;
            }
          }

          h5 {
            margin-bottom: 4px;
            font-size: 16px;
          }

          p {
            margin: 0px;
            color: #7f8699;
            font-size: 14px;
          }
        }

        .bodyRight {
          display: flex;
          justify-content: right;
          width: max-content;
          align-items: center;

          &.v2 {
            gap: 10px;

            .price {
              display: flex;
              flex-direction: column;

              .correct {
                font-weight: 700;
                font-size: 16px;
                color: #1e8c76;
              }

              .cancel {
                color: #ff4848;
                text-decoration: line-through;
                font-weight: 500;
              }
            }
          }

          .btnGroup {
            display: flex;
            box-shadow: 0 0 2px #b0b0b0;
            border-radius: 5px;
            align-items: center;

            :global(.btn) {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              color: #7f8699;
              border: none;
            }
          }

          .signleBtn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: #7f8699;
            border: none;
            box-shadow: 0 0 2px #b0b0b0;
            border-radius: 5px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.searchForm {
  box-shadow: 0px 4px 40px 0px #70707030;
  border: none;
  font-size: 14px;
  margin-bottom: 15px;
  padding: 10px 15px;
  border: 1px solid #fff;

  &:focus {
    box-shadow: 0px 4px 40px 0px #70707030;
    border: 1px solid var(--Main);
  }
}

.PriceList {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 15px;
      margin-bottom: 15px;

      .formBox {
        display: flex;
        background: #eff0f5;
        width: 100%;
        padding: 8px 0px;
        border-bottom: 20px;
        align-items: center;
        border-radius: 10px;

        :global(.form-control) {
          width: 50%;
          background-color: transparent;
          border: none;
          font-size: 14px;
          text-align: center;
          font-weight: 500;

          &:focus {
            box-shadow: none;
          }
        }

        :global(.form-select) {
          width: 50%;
          background-color: transparent;
          border: none;
          font-size: 14px;
          font-weight: 500;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .boxWidth {
        width: 40%;
        padding: 0px 10px;
      }

      .boxList1 {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          font-size: 16px;
          color: #181818;
          margin: 0px;
        }
      }

      .boxList2 {
        h6 {
          font-size: 16px;
          color: #7f8699;
        }
      }

      .boxList3 {
        h6 {
          font-size: 16px;
          color: #7f8699;
        }
      }
    }
  }
}

.formSign {
  .formSignGroup {
    // gap: 16px;

    .btnGray {
      background: #7C7C7C;
      background-image: none !important;
      box-shadow: none !important;
      color: #fff;
      border-radius: 9px !important;
      min-width: auto;

      &.btnGrayLight {
        background: #F1F1F1;
        color: #7f8699 !important;
      }
    }
  }

  .cancelGreenBtn {
    text-align: center;
    margin-top: 9px;

    :global(.btn) {
      color: #3E8575;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;

      &:active {
        border-color: transparent;
      }
    }
  }
}

:global(.priceError) {
  border-bottom: 1px solid red !important;
  padding: 7px 0px !important;
  border: 1px solid red !important;
}

.paymentTab {
  :global(.nav-pills) {
    margin-bottom: 15px;

    :global(.nav-item) {
      :global(.nav-link) {
        background-color: #fff;
        border: 2px solid var(--Main);
        color: #222;
        min-width: 150px;
        text-align: center;
        margin-right: 15px;

        &:global(.active) {
          background-color: var(--Main);
        }
      }
    }
  }
}

.logoutPopup {
  h3 {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    margin-bottom: 25px;

    &.completedConfirmation {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 25px;
    }

    span {
      font-weight: 500;
    }
  }

  .btnsAlignments {
    display: flex;
    justify-content: center;

    :global(.btn) {
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      border: none;
      min-width: 150px;
      padding: 10px 15px;
      border-radius: 9px;
      margin: 0px 5px;

      &.actionBtn {
        background-image: linear-gradient(var(--Main2), var(--Main3));
        color: #fff;
        background: #7C7C7C;
        background-image: none !important;
        box-shadow: none !important;
        // border-radius: 9px !important;
      }

      &.cancelBtn {
        color: #7f8699;
        background-color: #eff0f5;
      }
    }
  }
}

.serviceCategory {
  border: 1px solid var(--Main3);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 13px;
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;

  &.active {
    border: 2px solid var(--Main2);
    box-shadow: none;
    padding: 18px 20px;

    &::after {
      content: "";
      position: absolute;
      height: 24px;
      width: 24px;
      top: 0;
      right: 0;
      background-image: url("../../../assets/images/Tick.svg");
      transform: translate(40%, -40%);
    }
  }

  img {
    width: 24px;
    height: 24px;

    &.select {
      filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg) brightness(87%) contrast(92%);
    }
  }

  span {
    color: #7f8699;

    &.select {
      color: var(--Main3);
    }
  }
}

.bookingHeading {}

:global(.MuiSwitch-root) {
  :global(.MuiButtonBase-root) {
    color: var(--Main2) !important;
  }

  :global(.Mui-checked + .MuiSwitch-track) {
    background: var(--Main2) !important;
  }
}

.add_card_detailMain {
  :global(.modal-dialog) {
    :global(.modal-content) {
      border-radius: 24px;
      border: none;
      overflow: hidden;
      background-color: #eff0f5;

      :global(.modal-header) {
        justify-content: center;
        background: linear-gradient(180.35deg, #65beac 0.27%, #3e8575 98.11%);
        padding: 9px 0;
        color: #fff;

        :global(.modal-title) {
          font-size: 20px;
        }
      }

      :global(.modal-body) {
        padding: 15px 32px;

        .checkPopup {
          .info_blow {
            color: #7f8699;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
          }

          label {
            font-size: 14px;
            color: #000;
            font-weight: 500;
          }

          .input_selectForm {
            position: relative;

            img {
              position: absolute;
              top: 50%;
              transform: translate(0%, -50%);
              left: 13px;
            }

            :global(.form-select) {
              border: none;
              padding-left: 48px;
              line-height: 28px;
              box-shadow: none;
            }
          }

          .inputForm {
            label {
              font-size: 14px;
              color: #000;
              font-weight: 500;
            }

            .inputBox {
              :global(.form-control) {
                border: none;
                line-height: 28px;
                box-shadow: none;
                padding-left: 9px;
              }
            }
          }
        }

        .editcard_pop {
          .editcard_main {
            .detailingTitle {
              font-size: 14px;
              color: #7f8699;
              margin-bottom: 12px;
            }

            :global(.card-callMain) {
              border-bottom: 2px solid #fff;
              padding-bottom: 10px;
              padding-top: 11px;
            }

            :global(.card-callMain) {
              &:last-child {
                border: none;
              }
            }

            .cardAll_left {
              h4 {
                font-size: 16px;
                font-weight: 600;
                color: #343536;
                margin-bottom: 4px;

                img {
                  width: 29px;
                  margin-right: 6px;
                }
              }

              h5 {
                font-size: 16px;
                font-weight: 400;
                color: #343536;
                margin-bottom: 2px;

                span {
                  color: #7f8699;
                }
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: #7f8699;
                margin-bottom: 0px;
              }

              .expired {
                color: #ff2823;
                background: #ff282333;
                width: max-content;
                border-radius: 10px;
                font-size: 14px;
                padding: 4px 14px;
                margin-top: 2px;
                font-weight: 500;
              }
            }

            .cardAll_center {
              display: flex;
              justify-content: center;
            }

            .cardAll_right {
              text-align: center;

              :global(.btn) {
                padding: 0;

                :global(svg) {
                  font-size: 20px;
                  color: #ff4848;
                }
              }
            }
          }
        }
      }

      :global(.modal-footer) {
        border: none;
        padding: 0;

        .btnGroup {
          width: 100%;
          padding: 0px 27px 27px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          gap: 10px;

          .actionBtn {
            width: 100%;
            color: #fff;
            padding: 10px;
            border-radius: 10px;
            border: transparent;
          }

          .cancel {
            background-color: #7f8699;
          }

          .add {
            background-image: linear-gradient(#65beac, #3e8575);
          }
        }
      }
    }
  }

  &.add_card_detailMain {
    :global(.modal-dialog) {
      :global(.modal-content) {
        :global(.modal-header) {
          :global(.modal-title) {
            width: 100%;
            text-align: center;
          }

          :global(.btn-close) {
            background-color: #fff;
            opacity: 1;
            position: relative;
            border-radius: 30px;
            font-size: 11px;
            right: 3px;
          }
        }

        :global(.modal-body) {
          height: calc(100vh - 120px);
          overflow-y: auto;
        }
      }
    }
  }

  &.terms_Popup {
    :global(.modal-dialog) {
      :global(.modal-content) {
        :global(.modal-header) {
          :global(.btn-close) {
            right: 17px;
          }
        }
      }
    }
  }
}

.contact_detailMain {
  :global(.modal-dialog) {
    :global(.modal-content) {
      :global(.modal-header) {
        :global(.modal-title) {
          width: 100%;
          text-align: center;
        }

        :global(.btn-close) {
          margin-right: 8px;
          background-color: #fff;
          border-radius: 50%;
          opacity: 0.9;
          font-size: 14px;
        }
      }
    }
  }
}

.chat_imgShowPop {
  :global(.modal-content) {
    // background-color: transparent;
    // border: none;

    :global(.modal-header) {
      // border: none;
      padding-bottom: 8px;

      .download_btn {
        :global(.btn) {
          margin-right: 4px;

          :global(svg) {
            font-size: 23px;
          }
        }
      }

      :global(.modal-title) {
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
      }

      :global(.btn-close) {
        background-color: #fff;
        opacity: 1;
        box-shadow: none;
        border-radius: 50%;
      }
    }

    :global(.modal-body) {
      background-color: #ffffff;
      opacity: 1;
      border-radius: 18px;
      padding: 13px;
    }
  }

  .imgBox {
    text-align: center;

    .img {
      // width: fit-content;
      // height: 435px;
      // border-radius: 7px;
      width: 100%;
      height: 435px;
      border-radius: 7px;
      object-fit: contain;
    }
  }
}

.profile_serviceDiscount_coupon {
  // .discount_charges_accordian {
  //   :global(.accordion-button) {
  //     padding: 14px 13px !important;

  //     &:focus {
  //       box-shadow: none !important;
  //     }

  //     // &:not(.collapsed) {
  //     //   color: #fff;
  //     //   background: linear-gradient(var(--Main2), var(--Main3));

  //     // }
  //   }
  // }
  .discount_charges_accordian {
    margin-top: 26px;

    :global(.accordion) {
      :global(.accordion-item) {
        border: var(--bs-accordion-border-width) solid #bfbfbf;
      }

      :global(.accordion-button) {
        padding: 19px 21px !important;

        &:not(.collapsed) {
          background-color: #c7fcef4a;
          color: #285d51;

          &::after {
            filter: invert(47%) sepia(13%) saturate(1305%) hue-rotate(116deg) brightness(94%) contrast(94%);
          }
        }

        &:global(.collapsed) {
          background-color: transparent;
          color: #212529;

          &::after {
            filter: invert(0%) sepia(94%) saturate(7500%) hue-rotate(64deg) brightness(95%) contrast(102%);
          }
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    :global(.accordion-item:first-of-type) {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }

    :global(.accordion-item:first-of-type) {
      :global(.accordion-button) {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
      }
    }

    :global(.accordion-item:last-of-type) {
      :global(.accordion-button.collapsed) {
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }

    :global(.accordion-item:last-of-type) {
      border-bottom-right-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }
  }

  .new_appoint_discount_box {
    .new_appoint_discountDiv {
      margin-bottom: 4px;

      h4 {
        font-size: 18px;
        font-weight: 600;
        border-bottom: 3px solid #eff0f5;
        padding-bottom: 8px;
        padding-top: 10px;
        margin-bottom: 2px;
      }

      .input_left,
      .right_left {
        label {
          color: #181818;
          font-weight: 600;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 4px;
        }
      }

      .input_left {

        :global(.form-control),
        :global(.form-select) {
          background-color: #eff0f5;
          border: 1px solid #eff0f5;
          font-size: 15px;
          border-radius: 6px;
          height: 38px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          color: #0e0e0e;

          &:focus {
            box-shadow: none;
            background-color: #fff;
            border: 1px solid var(--Main2);
          }
        }
      }

      .right_left {
        :global(.form-control) {
          background-color: #eff0f5;
          border: 1px solid #eff0f5;
          font-size: 15px;
          border-radius: 6px;
          height: 38px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          color: #0e0e0e;

          &:focus {
            box-shadow: none;
            background-color: #fff;
            border: 1px solid var(--Main2);
          }
        }
      }

      .line_withTotal_amt {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .line {
          width: 100%;
          border-top: 2px solid #e3edf0;
        }

        .total_amt {
          text-wrap: nowrap;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          color: #1ab192;
          margin-left: 12px;

          .total_time {
            color: #132326;
          }
        }
      }
    }
  }
}

.date_timeBox_modal {
  position: relative;
  z-index: 1;
  // background-color: #eff0f5;
  // padding: 20px;
  // border-radius: 8px;
  // margin-bottom: 15px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 18px;
  }

  :global(.react-calendar) {
    background-color: transparent;
    border: none;

    :global(button) {

      &:global(:hover),
      &:global(:focus) {
        background-color: transparent !important;
      }
    }

    :global(.react-calendar__navigation) {

      :global(.react-calendar__navigation__prev2-button),
      :global(.react-calendar__navigation__next2-button) {
        display: none;
      }

      :global(.react-calendar__navigation__prev-button),
      :global(.react-calendar__navigation__next-button) {
        color: #7f8699;
        font-size: 22px;
      }

      :global(.react-calendar__navigation__label) {
        :global(.react-calendar__navigation__label__labelText) {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          color: #3c746d;
        }
      }
    }

    :global(.react-calendar__viewContainer) {
      :global(.react-calendar__tile--hasActive) {
        background-color: #3d9684;

        &:hover {
          background-color: #3d9684 !important;
        }
      }

      :global(.react-calendar__tile--now) {
        background-color: #67beac !important;
      }

      :global(.react-calendar__month-view) {
        :global(.react-calendar__month-view__weekdays) {
          justify-content: center;

          :global(.react-calendar__month-view__weekdays__weekday) {
            background-color: #e3edf0;
            color: #1e8c76;
            padding: 8px 2px !important;
            margin: 2px !important;
            margin-bottom: 4px !important;
            min-width: 2px !important;
            max-width: 40px;
            border-radius: 6.5px;

            :global(abbr) {
              text-decoration: none;
            }
          }
        }

        :global(.react-calendar__month-view__days) {
          justify-content: center;

          :global(.react-calendar__tile) {
            font: inherit;
            font-size: 13px;
            padding: 8px 2px !important;
            margin: 2px !important;
            min-width: 2px !important;
            max-width: 40px;
            border-radius: 6.5px;
            border: 1px solid transparent;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
            background-color: #eff0f5;

            &:hover {
              background-color: #eff0f5 !important;
              border: 1px solid #1ab192;
            }

            :global(abbr) {
              color: #3c746d;
              font-weight: 600;
              font-size: 14px;
            }
          }

          :global(.react-calendar__tile--active) {
            background-color: #e3edf0 !important;
            border: 1px solid #1ab192;
            color: #1ab192;
          }
        }
      }
    }
  }
}

.avail_time {
  h4 {
    font-size: 18px;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  .slotContainer {
    max-height: 115px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0px 12px;

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--Main);
      border-radius: 50px;
    }
  }

  :global(.btn) {
    width: 93%;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 6px 8px;
    margin-top: 1px;

    &:hover {
      border: 1px solid #1ab192;
      color: #1e8c76;
      background-color: #e9f6f1;
    }
  }

  :global(.active) {
    border: 1px solid #1ab192;
    color: #1e8c76;
    background-color: #e9f6f1;
  }
}

.signUpButton {
  :global(.btn) {
    position: relative;
    width: 100%;
    padding: 12px 29px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 2%;
    font-weight: 600;
    border-radius: 10px;
    background-image: linear-gradient(#65beac, #3e8575);
    margin-top: 20px;
    border: none;
    box-shadow: 0px 1.9462361336px 19.999994278px 0px rgba(51, 37, 10, 0.16);
    letter-spacing: 1px;

    &:active {
      color: #fff;
    }
  }

  :global(.backBtn) {
    color: #7f8699;
    background: transparent;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.149);
  }
}

// promotion view Start
.header {
  .headerList {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 12px;

    li {
      min-width: 103px;
    }

    li {
      &.services {
        min-width: 262px;

        .services_labeling {
          color: #000000;
          font-weight: 600;
          font-size: 13px;
        }
      }

      &.duration {
        min-width: 75px;
      }

      .netDis_prices {
        font-size: 13px;
        color: #000;
        font-weight: 600;
        line-height: 16px;

        .inputBox {
          margin-top: 9px;
          max-width: 105px;
          margin-right: 12px;

          input {
            height: 38px;
            width: 84px;
            padding: 3px 9px;
          }
        }
      }
    }
  }
}

.branchHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.108);
}

.branchList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.branches {
  margin-bottom: 12px;

  .dateBox {
    label {
      span {
        top: -9px;
      }
    }
  }

  .branchBox {
    background-color: #eff0f5;
    border: 1px solid #eff0f5;
    padding: 0px 13px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    h5 {
      font-size: 14px;
      margin: 0;
    }
  }
}

.insideAccordian_promtion {
  :global(.accordion-button) {
    padding: 10px 0px !important;
    padding-left: 25px !important;
  }
}

.service_addAccordion {
  .catBox {
    display: flex;
    align-items: center;

    h5 {
      font-size: 16px;
      min-width: 548px;
      margin: 0;

      &.v2 {
        min-width: 527px;
      }
    }

    .inputBox {
      margin-top: 11px;
      max-width: 105px;
      margin-right: 12px;
      margin-bottom: 12px;

      input {
        height: 38px;
        width: 84px;
        padding: 3px 9px;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    .itemContainer {
      display: flex;
      align-items: center;
      // gap: 33px;

      li {
        display: flex;
        width: 100%;
        background: #eff0f5;
        border-radius: 7px;
        padding: 11px 15px;
        margin-bottom: 5px;
        margin-top: 4px;
        width: 703px;
        margin-right: 16px;

        .bodyLeft {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .category_selected {
            min-width: 231px;
            padding-right: 13px;

            h5 {
              font-size: 15px;
              color: #000;
              margin-bottom: 0px;
            }
          }

          .price_time {
            display: flex;
            gap: 16px;
            margin-right: 3px;
            // text-align: end;

            h5 {
              font-size: 14px;
              color: #000;
              margin-bottom: 0px;
              min-width: 85px;
            }
          }

          .list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              padding: 0;
              margin: 0px;
              color: #7f8699;
              font-size: 14px;
            }
          }

          h5 {
            margin-bottom: 4px;
            font-size: 16px;
          }

          p {
            margin: 0px;
            color: #7f8699;
            font-size: 14px;
          }
        }
      }

      .inputBox {
        margin-top: 15px;
        max-width: 105px;
        margin-left: 18px;

        input {
          height: 38px;
          width: 84px;
          padding: 3px 9px;
        }
      }

      .actionButton {
        img {
          cursor: pointer;
        }
      }
    }
  }

  // min-width: 300px;
  :global(.accordion) {
    :global(.accordion-item) {
      border: none;

      :global(.accordion-header) {
        // display: inline-flex;
        border-bottom: 1px solid gainsboro;
        border-top: none;

        :global(.accordion-button) {
          box-shadow: none;
          background-color: #fff;
          padding: 12px 0px;
          padding-left: 25px;

          &:global(.collapsed) {
            &::after {
              content: "+";
              transform: rotate(0);
            }
          }

          &::after {
            position: absolute;
            left: -1px;
            display: flex;
            align-items: center;
            font-size: 20px;
            content: "-";
            background-image: none !important;
            transform: rotate(0);
            transition: content 3s;
          }

          :global(.accordion) {
            :global(.accordion-item) {
              border: none;

              :global(.accordion-header) {
                // display: inline-flex;
                border-bottom: 1px solid gainsboro;

                :global(.accordion-button) {
                  box-shadow: none;
                  background-color: #fff;
                  padding-left: 0;

                  &:global(.collapsed) {
                    &::after {
                      content: "+";
                      transform: rotate(0);
                    }
                  }

                  &::after {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    content: "-";
                    background-image: none !important;
                    transform: rotate(0);
                    transition: content 3s;
                  }

                  :global(.accordion) {
                    :global(.accordion-item) {
                      border: none;

                      :global(.accordion-header) {
                        // display: inline-flex;
                        border-bottom: 1px solid gainsboro;

                        :global(.accordion-button) {
                          box-shadow: none;
                          background-color: #fff;
                          padding-left: 0;

                          &:global(.collapsed) {
                            &::after {
                              content: "+";
                              transform: rotate(0);
                            }
                          }

                          &::after {
                            display: flex;
                            align-items: center;
                            font-size: 20px;
                            content: "-";
                            background-image: none !important;
                            transform: rotate(0);
                            transition: content 3s;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      :global(.accordion-body) {
        padding: 0 20px;

        :global(.accordion-header) {
          // display: inline-flex;
          border-top: none;
          border-bottom: none;

          :global(.accordion-button) {
            box-shadow: none;
            background-color: #fff;
            padding-left: 0;

            &:global(.collapsed) {
              &::after {
                content: "+";
                transform: rotate(0);
              }
            }

            &::after {
              display: flex;
              align-items: center;
              font-size: 20px;
              content: "-";
              background-image: none !important;
              transform: rotate(0);
              transition: content 3s;
            }
          }
        }

        :global(.accordion) {
          :global(.accordion-item) {
            :global(.accordion-header) {
              :global(.accordion-button) {
                padding: 0;
                padding-left: 25px;

                &:global(.collapsed) {
                  &::after {
                    content: "+";
                    transform: rotate(0);
                  }
                }

                &::after {
                  position: absolute;
                  left: -1px;
                  display: flex;
                  align-items: center;
                  font-size: 20px;
                  content: "-";
                  background-image: none !important;
                  transform: rotate(0);
                  transition: content 3s;
                }
              }
            }

            :global(.accordion-body) {
              padding: 0 0px;
            }
          }
        }
      }
    }
  }
}

.addButton {
  min-width: 120px;

  span {
    padding-left: 10px;
  }
}

.onlyView_inputBox {

  :global(.form-control),
  :global(.form-select) {
    box-shadow: none !important;
    background-color: #f7f7f7 !important;
    border: none !important;
    background-image: none !important;
  }
}

.newBundle_Main {
  margin-top: 13px;
  margin-bottom: 22px;

  h5 {
    font-size: 19px;
  }

  .boxes {
    .newBundleServices {
      background-color: #eff0f5;
      padding: 14px;
      border-radius: 8px;
      margin-bottom: 12px;

      .tops {
        .specialy {
          font-weight: 700;
          font-size: 16px;
          margin: 0;
          margin-bottom: 4px;
        }

        .times {
          color: #7f8699;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

// promotion view End

@media (max-width: 576px) {
  .appointmentSection {
    .booked {
      .top {
        flex-direction: column !important;
        align-items: flex-start !important;
        margin-bottom: 3px !important;
      }

      .bottom {
        p {
          font-size: 15px !important;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .selectedClient {
    .top {
      position: relative;

      .right {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .otpScreen {
    display: flex;
    width: 95%;
    flex-direction: column;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;

    input {
      background-color: transparent;
      height: 50px;
      text-align: center;
      font-size: 16px;
      margin-right: 10px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      border: 1px solid #eff0f5;
      padding: 10px 15px;
      border-radius: 8px;
      width: 50px !important;

      &:focus {
        outline: none;
        border: 1px solid var(--Main2);
        box-shadow: none;
      }
    }

    .varifyBtn {
      margin-top: 0;

      button {
        min-width: 150px;
        margin-right: 45px;
      }
    }

    .resend {
      font-size: 13px;
      font-weight: 400;
      color: #7f8699;
      display: flex;
      margin-top: 5px;

      span {
        color: #1ab192;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: 576px) {
  .modalSm {
    :global(.modal-dialog) {
      max-width: 680px;
    }
  }
}

@media (min-width: 466px) {
  .modalSm {
    :global(.modal-dialog) {
      max-width: 680px;
    }
  }
}

@media (min-width: 576px) {
  .contact_detailMain {
    :global(.modal-dialog) {
      max-width: 367px;
    }
  }
}