//index style
.top {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex: 2;

  p {
    margin: 0;
  }

  .hours {
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    margin-bottom: 5px;
  }

  .number {
    font-size: 14px;
    font-weight: 700;
    color: #0C9B7E;


  }
}

.number_select_drop {
  :global(.form-select) {
    height: 40px;
    text-align: center;
    font-size: 13px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #c7c7c7;

    &:focus {
      box-shadow: none;
    }
  }
}

.calendyFlex {
  flex: 1;

  .buttonSwitch {
    margin: 0;

    ul {
      display: flex !important;
      align-items: center !important;

      li:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.dateBox {
  background-color: #fff;
  padding: 12px 30px 12px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;

  img {
    margin-left: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
  }
}

.slot {
  margin: 15px 0;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;

  .top {
    background-image: linear-gradient(var(--Main2), var(--Main3)) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;

    .downIcon {
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.disable {
        transform: rotate(180deg);
      }
    }

    span {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.1px;
      color: #fff;
    }
  }

  .bottom {
    &.disable {
      display: none;
    }

    .entry {
      padding: 10px 25px;
      background-color: #fff;
      font-size: 16px;
      font-weight: 400;
    }

    .entry:nth-child(even) {
      background-color: #eff7fe;
    }

    .content {
      padding: 10px 16px;

      .description {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;

        }

        .customerDesc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 12px;

          h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: #0C9B7E;
          }
        }
      }

      .service {
        margin-top: 8px;

        .serviceEntry {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 5px;

          img {
            height: 24px;
            width: 24px;

            &.v2 {
              height: 19px;
              // width: 20px;
            }
          }

          span {
            font-weight: 400;
            font-size: 14px;
            color: #525C62;
          }
        }
      }

      .period {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 15px 0;

        .left,
        .right {
          h3 {
            font-size: 14px;
            font-weight: 400;
            color: #7F8699;
            margin: 0;
            margin-bottom: 3px;
            text-align: center;
          }

          p {
            font-size: 16px;
            font-weight: 600;
          }
        }

        .middle {
          color: #EFF0F5;
        }

      }

      .priceField {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
          color: #7F8699;
          font-size: 14px;
          font-weight: 400;
        }

        .priceNum {
          color: #0C9B7E;
          font-weight: 700;
          font-size: 16px;
        }
      }

      .cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        gap: 10px;
        margin-bottom: 10px;

        button {
          background-color: #E8F9F7;
          border: none;
          padding: 8px 2px;
          border-radius: 10px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 20%);
          width: 100%;
          color: #7F8699;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          .phone {
            color: #0C9B7E;
            font-size: 16px;
          }

          img {
            width: 16px;
            height: 16px;
          }

          &.v2 {
            background-color: #EFF0F5;
            padding: 8px 10px;
          }

        }
      }
    }
  }
}


//calendar style
.calendarContainer {
  overflow-x: scroll;
}

.dayCell {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  height: 100%;


  /* Ensure the cell occupies full height */
  div {
    color: #525C62;
    font-weight: 400;
  }
}

.dayButtonContainer {
  margin-top: auto;
  cursor: pointer;
  /* Push the container to the bottom */
}


:global(.fc-daygrid-event-harness) {

  a {
    border: none;

    :global(.fc-event-main) {
      :global(.fc-event-main-frame) {

        :global(.fc-event-title-container) {
          background-color: #EFF0F5;
          color: #4C89E4;

          border-left: 3px solid #4C89E4;
        }
      }
    }
  }
}




:global(.fc-daygrid-day-top) {
  height: 100%;

  :global(.fc-daygrid-day-number) {
    text-decoration: none !important;
  }
}

:global(.fc-daygrid-day-events) {
  display: none;
}

:global(.fc-view-harness) {
  background-color: #fff !important;
}

:global(.fc-col-header-cell-cushion) {
  font-weight: bold;
  text-decoration: none;
  color: #525c62;
  font-size: 14px;
  font-weight: 400;
}

:global(.fc-toolbar-chunk) {
  h2 {
    font-size: 20px !important;
    color: #525C62;
  }

  :global(.fc-today-button) {
    background-image: linear-gradient(var(--Main2), var(--Main3)) !important;
    border: none;
    display: none;
  }

  :global(.fc-button-group) {
    :global(.fc-button-primary) {
      background-image: linear-gradient(var(--Main2), var(--Main3)) !important;
      border: none;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    :global(.fc-button-primary):first-child {
      border-right: 2px solid rgba(255, 255, 255, 0.447);
    }

  }
}

:global(.fc-daygrid-day-frame) {
  border: 2px solid #eff0f5;
  position: relative;
  height: 100%;
  // &:after{
  //   content: "";
  //   position: absolute;
  //   height: 30px;
  //   width: 30px;
  //   background-repeat: no-repeat;
  //   bottom: 5%;
  //   right: 5%;
  //   z-index: 100;
  //   background-image: url("../../../assets/images/plusFill.svg");
  // }
}

:global(.fc) {
  :global(.fc-button:disabled) {
    opacity: 1 !important;
  }

}

:global(.rbc-event) {
  background-color: transparent !important;
  min-height: 100px;
  padding: 0;

  :global(.rbc-event-content) {
    .box {
      display: flex;
      flex-direction: column;

      h1 {
        margin: 0;
        font-size: 14px;
        background-color: #70e0d300;
        text-align: center;
        padding: 2px 0;
        color: #1b8769;

        &.unavailable {
          background-color: #bbbcc100;
        }
      }

      p {
        position: relative;

        img {
          width: 14px;
          position: absolute;
          right: 2px;
          margin-top: 2px;
        }
      }
    }
  }

}

// sourav

.changeDateBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  .btnBox {
    margin: 0 0 0 auto;

    button {
      background: none;
      background-image: none;
      background-image: linear-gradient(var(--Main2), var(--Main3)) !important;
      border: none;
      color: #fff;
      padding: 4px 14px 5px 14px;


      &.leftBtn {
        border-radius: 8px 0 0 8px;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);

      }

      &.rightBtn {
        border-radius: 0 8px 8px 0;
        border-left: 1px solid #d8d5d5;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
      }

      &.today {
        margin-right: 8px;
        padding: 6px 14px;
        text-align: center;
        border-radius: 8px;
        font-size: 13px;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);


      }
    }
  }

  h5 {
    width: 100%;
    color: #525c62;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }



  label {
    width: 100%;
  }

  .dobInput {
    text-align: center;
    background-color: #fff;

    &::-webkit-calendar-picker-indicator {
      // display: none;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(48%, -52%);
      z-index: 10;
      opacity: 0;
      width: 20px;
    }
  }

  .scheduleIcon,
  .locationIcon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50%, -65%);
    z-index: 1;
  }

  .addressInput {
    padding-left: 40px;
  }

  .locationIcon {
    transform: translate(48%, 160%);
  }

}

.view_Slot_Div {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      margin-bottom: 10px;

      .view_Slot_day {
        font-size: 20px;
        font-weight: 600;
        display: flex;

        .today {
          display: inline-flex;
          padding: 5px 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background: #0C9B7E;
          font-size: 14px;
          border-radius: 5px;
          color: #fff;
          font-weight: 400;
          margin-left: 10px;
        }
      }

      .viewSelectBtn {
        select {
          background-color: #eff0f5;
          border: none;
          color: #0C9B7E;

          &:focus {
            box-shadow: none;
          }
        }

        :global(.form-select) {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke='rgb(50, 116, 64)' stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
        }

      }
    }
  }
}

.emptyBooking {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    font-size: 16px;
  }
}

:global(.date_withClose_width) {
  :global(.react-datepicker-wrapper) {
    width: 100%;

    :global(.react-datepicker__input-container) {
      input {
        width: 100%;
      }

      :global(.react-datepicker__close-icon) {
        &::after {
          background-color: #0C9B7E;
        }
      }
    }
  }
}

:global(.react-datepicker-wrapper) {
  margin-bottom: 15px;



  :global(.react-datepicker__input-container) {
    input {
      padding: 7px 14px;
      border: none;
      border-radius: 4px;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.detailSlot_Box {
  margin-bottom: 20px;

  .slotTime {
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    border-left: 13px solid #1AB192;
    border-radius: 10px;
    padding-left: 12px;
    box-shadow: 0px 0px 20px 0px #0000001A;
    margin-bottom: 10px;
    min-width: 150px;


    h4 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }

    .slotCategory {
      font-size: 14px;
      margin: 0;
      min-width: 145px;
      max-width: 145px;
    }

    .slotIMg {
      display: flex;
      align-items: center;

      .imgperson {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 12px;
      }

      .name {
        h5 {
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        .call_mail {
          display: flex;
          align-items: center;
          gap: 8px;

          :global(a) {
            background-color: #EFF0F5;
            padding: 8px;
            border-radius: 50%;
            display: flex;

            img {
              height: 18px;
              width: 18px;
            }
          }
        }
      }

    }

    .slotPrice {
      display: flex;
      align-items: center;
      min-width: 145px;
      max-width: 145px;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }

      span {
        padding: 0px 23px;
        font-size: 26px;
        color: #EFF0F5;
      }
    }

    .slotAccecpt {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      line-height: 75px;
      background: #EFF0F5;
      border-radius: 0px 10px 10px 0px;

      li {
        button {
          background: transparent;
          border: none;
          border-right: 2px solid #ffffff;
          border-radius: 0;
          padding: 2px 18px;

          img {
            height: 16px;
            width: 16px;
          }
        }

        &:last-child {
          button {
            border-right: none;

            img {
              filter: hue-rotate(188deg);
            }
          }
        }
      }
    }

    .dotDropdown {
      :global(.dropdown) {
        :global(.btn) {
          background-color: transparent;
          border: none;

          &::after {
            display: none;
          }
        }

        .dotDropInside {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }

  }
}

:global(.filterDropdown) {
  padding: 2px 7px;
  margin: 0 9px;
  border-radius: 10px;
  background-color: #EFF0F5 !important;

  :global(.show) {
    :global(.dropdown-menu) {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 30%);
      border-radius: 10px;
      margin-top: 5px;
      padding: 8px 0px !important;

      a {
        font-size: 14px;
        font-weight: 400;
        color: #132326;
        margin-bottom: 5px;

        &:hover,
        &:active {

          background-color: var(--Main2) !important;
          color: #fff !important;
          // border-radius: 7px;
        }
      }
    }
  }
}

:global(.filterDropdown) {
  :global(.dropdown) {
    :global(.btn) {
      background-color: transparent;
      border: none;
      padding: 0;


      &::after {
        display: none;
      }
    }

    .dotDropInside {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}

// monthly calendar
.monthlyCalendyBox {
  :global(.rbc-month-view) {
    background-color: #fff;

    :global(.rbc-row) {

      :global(.rbc-date-cell) {

        &:first-child {
          border-left: none;
        }

      }

    }


    :global(.rbc-date-cell) {
      background-color: #f0f8ff;
      border-left: 1px solid #dddddd;


      :global(.rbc-button-link) {}
    }
  }
}


@media (max-width: 1199px) {
  .detailSlot_Box {
    overflow: scroll;

    .slotTime {
      min-width: 1070px;

      h4 {
        min-width: 158px;
      }

      .slotCategory {
        min-width: 150px;
      }

      .slotIMg {
        min-width: 228px;
      }

      .slotPrice {
        min-width: 190px;
      }

      .slotAccecpt {
        min-width: 161px;
      }
    }
  }

  :global(.rbc-event) {
    :global(.rbc-event-content) {
      .box {

        h1 {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 767px) {

  // .section{
  //   padding: 20px 3px !important;
  // }
  .changeDateBox {
    margin-left: 0;
    margin-top: 0;
  }

  .buttonSwitch {
    ul {
      display: flex !important;
      align-items: center !important;

    }
  }
}

@media (max-width:900px) {
  .topTitle {
    flex-direction: column !important;
  }
}

@media (max-width: 1000px) {
  .top {
    flex: 1;
  }
}

@media (max-width: 480px) {
  .calendyFlex {
    justify-content: center !important;
  }
}