.loginSection {
  // height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  position: relative;

  .emailName_main {
    text-align: end;
    position: absolute;
    // top: 30px;
    top: 14px;
    right: 20px;
    // margin-top: -40px;
    z-index: 99;

    .name_box {
      display: inline-flex;
      background-color: #fff;
      border-radius: 8px;
      align-items: center;
      flex-direction: column;
      box-shadow: 0px 5px 40px 0px #3E857526;

      .mail_name {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 11px;
      }

      .leftSide {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #3E8575;
        padding: 10px;
        font-weight: 700;
        font-size: 18px;
        color: #fff;
      }

      .rightSide {
        text-align: left;

        .namePerson {
          color: #1D211E;
          font-size: 14px;
        }

        .mailPerson {
          color: #A3A3A3;
          font-size: 12px;
          letter-spacing: .01px;
        }
      }

      .logout_btn {
        width: 100%;
        text-align: left;
        border-top: 1px solid gainsboro;
        padding: 10px 11px;
        font-size: 14px;

        button {
          border: none;
          background-color: transparent;
          display: inline-flex;
          gap: 5px;

          img {
            margin-right: 4px;
          }
        }
      }

    }
  }

  .signUpBgClr {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #F4FAF6;
    height: 100%;
  }

  .title {
    text-align: left;
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 9px;

    &.v2 {
      margin: 0;
    }

    .stepsNo {
      font-size: 17px;
      color: #505050;
      margin-bottom: 2px;
    }
  }

  .desc {
    text-align: left;
    font-size: 15px;
    margin-bottom: 25px;
    color: #7f8699;
    margin-top: 4px;
  }

  .desc2 {
    text-align: left;
    font-size: 13px;
    color: #7f8699;
    margin-top: 4px;
    margin-bottom: 14px;
  }

  span {
    color: var(--Main2);
    text-align: center;
    display: block;
  }

  .loginBg {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: none;
  }

  .customer_typeSelect {
    :global(.form-control) {
      border: 1px solid #42ad95;
      // text-align: center;
      padding: 10px 22px;
      font-size: 17px !important;
      color: #3E8575;
      border-radius: 10px;
    }
  }

  .modalContent {
    padding: 60px 40px;
    padding-top: 30px;
    max-width: 1400px;
    margin: auto;
    // display: flex;
    // height: 100%;
    // justify-content: center;
    // align-items: center;

    .modalCenter {
      position: relative;
      z-index: 1;

      .formBody {
        background: #fff;
        width: 620px;
        margin: 0 auto;
        padding: 50px 42px;
        padding-top: 40px;
        border-radius: 10px;
        // box-shadow: 0 0 20px #00ffff94;
        box-shadow: 0px 5px 40px 0px #3E857526;
        z-index: 1;
        display: flex;
        align-items: center;
        min-height: calc(100% - var(--bs-modal-margin) * 2);
        position: relative;
        overflow-x: hidden;

        .top {
          position: absolute;
          top: 0px;
          left: 0;
          height: 10px;
          background-image: linear-gradient(#65beac, #3e8575);
          transition: all 0.3s ease-in-out;

          &.first {
            width: 0%;
          }

          &.second {
            width: 15%;
          }

          &.third {
            width: 30%;
          }

          &.fourth {
            width: 50%;
          }

          &.fifth {
            width: 70%;
          }

          &.sixth {
            width: 90%;
          }
        }

        // for email popup
        &.emailBody {
          width: 845px;
        }

        // for otp title

        .numberTitle {
          font-size: 13px;
          font-weight: 400;
          color: #7f8699;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            margin-left: 5px;
          }
        }

        .otpScreen {
          display: flex;
          width: 100%;
          margin: 0 auto;
          justify-content: center;
          align-items: flex-start;
          margin-top: 10px;
          gap: 15px;
          margin-bottom: 20px;

          input {
            background-color: transparent;
            text-align: center;
            font-size: 16px;
            margin-right: 18px;
            box-shadow: 0 0px 1px rgba(0, 0, 0, 0.25);
            border: 1px solid #d9d9d9;
            // padding: 10px 40px;
            border-radius: 8px;
            background-color: transparent;
            font-size: 16px;
            min-width: 60px;
            height: 50px;


            &:focus {
              outline: none;
              border: 1px solid var(--Main2);
              box-shadow: none;
            }

          }

          .varifyBtn {
            margin-top: 0;

            button {
              min-width: 150px;
              margin-right: 45px;
            }
          }

          .resend {
            font-size: 13px;
            font-weight: 400;
            width: 56%;
            margin: 0 auto;
            color: #7f8699;
            display: flex;
            margin-top: 5px;
            margin-bottom: 10px;

            span {
              color: #1ab192;
              text-decoration: underline;
              font-weight: 600;
              cursor: pointer;
              margin-left: 10px;

            }
          }
        }

        .resend {
          font-size: 13px;
          font-weight: 400;
          width: 57%;
          margin: 0 auto;
          color: #7f8699;
          display: flex;
          margin-top: 5px;
          margin-bottom: 10px;

          span {
            color: #1ab192;
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
            margin-left: 10px;

          }
        }

        :global(.inputDiv) {
          background-color: transparent;
          height: 50px;
          text-align: center;
          font-size: 16px;
        }

        ::-webkit-inner-spin-button {
          display: none;
        }

        .facebookSignup {
          background: #1a77f2;
          border: 1px solid #1a77f2;
          color: #fff;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          position: relative;
          text-align: left;
          padding-left: 45px;
          font-weight: 500;
          margin-top: 10px;

          img {
            height: 22px;
            position: absolute;
            left: 20px;
          }
        }

        .googleSignup {
          background: #e9f6f1;
          color: #132326;
          border: 1px solid #65beac;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          position: relative;
          text-align: left;
          padding-left: 55px;
          font-weight: 500;
          margin-top: 10px;

          img {
            height: 22px;
            position: absolute;
            left: 20px;
          }
        }

        .alreadyBox {
          color: #3E8575;
          text-align: center;
          margin-top: 20px;
          font-weight: 500;
          display: flex;
          justify-content: center;

          span {
            color: #000000;
            margin-right: 4px;
            letter-spacing: 0.1px;
            margin-right: 4px;
            font-weight: 400;
          }
        }

        .serviceCategory {
          border: 1px solid #DCDCDC;
          padding: 11px 8px;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          position: relative;
          cursor: pointer;

          span {
            font-size: 14px;
            color: #959595 !important;
          }

          .iconBox {
            background-image: linear-gradient(#65beac, #3e8575);
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .fakeLogo {
              color: #fff !important;
              text-transform: uppercase;
              font-size: 14px;
              text-align: left;
            }
          }

          &.active {
            border: 1px solid var(--Main3);
            // outline: 1px solid var(--Main2);
            background-color: var(--Main3);
            box-shadow: none;
            padding: 11px 10px;

            span {
              color: #fff !important;
            }

            // &::after {
            //   content: "";
            //   position: absolute;
            //   height: 24px;
            //   width: 24px;
            //   top: 0;
            //   right: 0;
            //   background-image: url("../../../assets/images/Tick.svg");
            //   transform: translate(40%, -40%);
            // }
          }

          img {
            width: 24px;
            height: 24px;

            &.select {
              filter: invert(47%) sepia(45%) saturate(407%) hue-rotate(116deg) brightness(87%) contrast(92%);
            }
          }

          span {
            color: #7f8699;
            text-align: left;

            &.select {
              color: var(--Main3);
              text-align: left;
            }
          }
        }
      }

      .maleFemale_width {
        width: 735px;
      }

      .logoApply {
        text-align: center;
        // margin-bottom: 40px;
        margin-bottom: 68px;
      }

      .createAccount_box {
        display: flex;
        gap: 60px;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .anotherBody {
          width: 600px;
          margin: 0px;
        }

        .create_rightSide {
          max-width: 40%;

          .imgSide {
            margin-bottom: 14px;

            img {
              width: 200px;
            }
          }

          .contentBox {
            p {
              font-size: 22px;
              color: #1D211E;
              line-height: 26px;
              font-weight: 500;
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                font-size: 16px;
                font-weight: 400;
                color: #959595;
                line-height: 14px;
                margin-bottom: 11px;
                display: flex;
                gap: 7px;
                align-items: center;

                img {
                  width: 17px;
                }
              }
            }
          }
        }
      }

      .signUpPolicy_box {
        display: flex;
        justify-content: center;
        margin-top: 20px;


        span {
          color: #1D211E;

          a {
            color: #3E8575;
          }
        }
      }

      .otpBtn {
        margin-top: 25px;

        &.back {
          margin-top: 8px;
        }

        &.v2 {
          button {
            background-image: none !important;
            background-color: #eff0f5;
            color: #7f8699 !important;
          }
        }
      }

      .resend {
        font-size: 13px;
        font-weight: 400;
        width: 35%;
        margin: 0 auto;
        color: #7f8699;
        display: flex;
        margin-top: 5px;
        margin-bottom: 10px;

        span {
          color: #1ab192;
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
          margin-left: 10px;
        }
      }

      .enterInput {
        margin-top: 27px;
        padding-left: 15px !important;
      }

      .checkBtn {
        padding: 10px 13px;
        border-radius: 10px;
        background-color: #eff0f5;
        border: none;
        margin-left: 10px;

        .active {
          background-color: var(--Main3);
          color: #fff;
        }
      }
    }
  }
}

.backSignUp_box {
  position: relative;
  left: -32px;
  margin-bottom: 14px;

  .backBtn {
    // position: absolute;
    top: -29px;
    left: -30px;
    border: 1px solid #56a897;
    border-radius: 17px;
    padding: 4px;
    background-color: #84f16914;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    :global(svg) {
      font-size: 25px;
      color: #56a897;
    }
  }
}

.formSign {
  .formSignGroup {
    gap: 16px;

    .btnGray {
      background: #7C7C7C;
      background-image: none !important;
      box-shadow: none !important;
      color: #fff;
      border-radius: 4px !important;
      min-width: auto;

      &.btnGrayLight {
        background: #F1F1F1;
        background-image: none !important;
        color: #959595 !important;
        min-width: 27%;
        max-width: 27%;
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .loginSection {
    .loginBg {
      height: 100%;
    }

    .modalContent {
      padding: 30px 20px;

      .modalCenter {
        .formBody {
          width: 100%;
          padding: 20px 20px;
        }
      }
    }
  }

  .backSignUp_box {
    left: 0px;
  }
}

@media (max-width: 768px) {
  .numberTitle {
    flex-direction: column;

    span {
      margin-left: 0px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}