:root {

    --Main: #142B2F;
    --Main2: #65BEAC;
    --Main3: #3E8575;
    

    --Color: #1C1C1C;

    
    --BodyBackground:#f9f9f9;
}
