.switch {
    display: inline-block;

    input {
        display: none;

        &:checked {
            &~label {
                border-color: transparent;
                background-color: #B2DFD6;

                &::after {
                    translate: 26px 0;
                    background: #65BEAC;
                }
            }
        }

        // &:disabled {
        // 	& ~ label {
        // 		opacity: 0.5;
        // 		cursor: not-allowed;
        // 	}
        // }
    }

    label {
        display: block;
        width: 58px;
        height: 32px;
        padding: 2px;
        border-radius: 30px;
        border: 2px solid transparent;
        cursor: pointer;
        transition: 0.3s;
        background-color: gray;

        &::after {
            content: "";
            display: inherit;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background: #ffffff;
            transition: 0.3s;
        }
    }
}