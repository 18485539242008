.rollTable {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  // / Style the scrollbar track /
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }

  // / Style the scrollbar thumb /
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  // / Style the scrollbar on hover /
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  padding: 0px !important;
  z-index: -2;
  padding: 0;

  &::before {
    background-image: none !important;
  }

  .tableContainer {
    // overflow: scroll !important;
    max-height: calc(100dvh - 230px);
    min-height: calc(100dvh - 230px);

    .table {
      margin: 0;
      position: relative;

      .emptyBooking {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 400%);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  :global(.RollManagement_rollTable__VkZEv) {
    tr,
    th {
      vertical-align: top !important;
    }
  }

  :global() .table {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // background-color: #e3edf0;
      background-image: linear-gradient(#65beac, #3e8575);
      height: 20px;
      z-index: -1;
    }

    tr {
      td {
        // padding: 25px 40px 15px 40px;
        padding: 20px 6px 15px 6px;
        min-width: 100px;
        font-size: 14px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          font-weight: 400;
          font-size: 14px;
        }

        p {
          margin: 0;
        }

        .date {
          font-weight: 400;
          font-size: 17px;
        }

        .time {
          font-weight: 700;
          font-size: 14px;
        }

        .activeButton {
          color: #fff;
          font-weight: 500;
          font-size: 13px;
          background-image: linear-gradient(#65beac, #3e8575);
          border: none;
          padding: 8px;
          min-height: 35px;
          min-width: 110px;
          border-radius: 6px;

          &.inactive {
            background-image: none;
            background-color: #cecece;
            
          }
        }

        .teamContainer {
          display: flex;
          align-items: center;
          gap: 10px;

          .logoBox {
            height: 50px;
            min-width: 50px;
            max-width: 50px;
            border-radius: 50%;
            overflow: hidden;
            color: #fff;
            font-weight: 500;
            font-size: 13px;
            background-image: linear-gradient(#65beac, #3e8575);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .teamImage {
            height: 50px;
            min-width: 50px;
            max-width: 50px;
            border-radius: 50%;
            overflow: hidden;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          span {
            font-weight: 600;
            font-size: 14px;
          }
          .teamPerson{
            color: #0F9C7F;
          }

          .emp_id{
            display: block;
            font-weight: 400;
          }
        }

        .action {
          .contactBox {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 3px;

            .imageBox {
              background-color: #eff0f5;
              height: 35px;
              width: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }

            .tickImg{
              background-color: transparent;
              width: 28px;
              height: auto;
            }

            .content {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        .dotDropdown {
          position: absolute;

          :global(.dropdown) {
            position: absolute;
            top: 100%;

            :global(.dropdown-toggle) {
              .dots {
                position: absolute;
                top: 0px !important;
                cursor: pointer;
              }
            }

            :global(.btn) {
              background-color: transparent;
              border: none;

              &::after {
                display: none;
              }
            }

            .dotDropInside {
              display: flex;
              gap: 10px;
              align-items: center;
              margin: 0;
              font-size: 15px;
            }
          }

          .correctImage {
            height: 20px;
            width: 20px;
            opacity: 0.6;
          }
        }
      }

      th {
        margin-right: 20px;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        // background-color: #e3edf0;
        background-image: linear-gradient(#c9c9c9, #9f9f9f);
        min-width: 100px;
        // padding: 12px 40px;
        padding: 12px 6px;
        position: sticky;
        top: 0;
        z-index: 10;
      }
    }
  }
}
