.rollTable {
  // overflow: scroll;
  padding-left: 18px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;

  z-index: -2;

  .tableContainer {
    overflow: auto !important;
    max-height: calc(100dvh - 240px);
  }

  :global(.RollManagement_rollTable__VkZEv) {

    tr,
    th {
      vertical-align: top !important;
    }
  }

  :global() .table {
    position: relative;
    margin-bottom: 0px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #e3edf0;
      height: 20px;
      z-index: -1;
    }

    tr {
      td {
        padding: 10px 15px;
      }

      th {
        margin-right: 20px;
        color: #3c746d;
        font-weight: 600;
        font-size: 14px;
        background-color: #e3edf0;
        padding: 15px;
        min-width: 110px;
        padding: 15px 10px;
        position: sticky;
        top: 0;
        z-index: 10;

        &:first-child {
          min-width: 300px !important;
          padding: 15px;
        }
      }
    }

    .nested {
      td {
        &:first-child {
          padding-left: 35px;
        }
      }
    }

    .inside {
      td {
        &:first-child {
          padding-left: 50px;
        }

        .bold {
          font-weight: 500;
        }
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 700;
      margin-top: 12px;
    }
  }
}

.add_plusMinus {
  position: relative;

  .show_icons {
    position: absolute;
    top: 7px;
    left: 0;
    font-size: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: inline-block;
    color: #000;
    display: none;
  }

  .plusMinus_ShowHide {
    position: relative;

    &::before {
      position: absolute;
      left: 17px;
      z-index: 99;
      font-size: 19px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &.plus_Show {
      &::before {
        content: "+";
      }
    }
  
    &.minus_Show {
      &::before {
        content: "-";
      }
    }
  }

  

}

.tableShadowNone {
  position: relative;

  &::after {
    display: none;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 750px) {
  .rollTable {
    padding: 0px 0px 0px 10px !important;
  }
}