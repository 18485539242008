// data table style
.serviceCatList {
  list-style: none;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  background-color: #d7eaf0;
  border-radius: 7px;
  padding: 8px;
  max-height: 105px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
  }

  &::-moz-scrollbar {
    width: 5px;
    height: 4px;
  }

  &::-moz-scrollbar-thumb {
    background-color: black;
  }

  .listItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }

    .categoryName {
      color: #176757;
      background-color: #fff;
      padding: 3px 8px;
      border-radius: 5px;
      margin: 0 !important;
      font-size: 12px;

      .bold {
        font-weight: 700px;
        font-size: 13px;
      }

      &.v2 {
        color: #fff !important;
        background-image: linear-gradient(#1e8c76, #0c9b7e);
        // padding: 3px 10px;
        border-radius: 5px;
        margin: 0 auto;
        display: block;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.statusButton {
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  background-color: #00bc62;
  color: #fff;

  &.disactive {
    background-color: #ff4848;
  }
}

.addButton {
  min-width: 100% !important;
}

// add style

.deleteService {
  cursor: pointer;

  &.v2 {
    margin-top: 14px;
  }

  &.notDynamic {
    margin-top: 40px;
  }
}

.iconBox {
  margin: 10px 0 20px 0;
}

.locationSelect {
  display: block;
  // border-bottom: 2px solid #eff0f5;
  // padding: 5px 0;
  border-bottom: 2px solid #eff0f5;
  padding: 10px 0;
  padding-bottom: 0;

  .resetButton {
    color: #fff;
    background-image: linear-gradient(#65beac, #3e8575);
    border: none;
    border-radius: 10px;
    height: 40px;
    margin-bottom: 5px;

    &:active {
      color: #fff;
    }
  }

  &.v2 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selectAll {
      display: flex;
      align-items: center;
      gap: 9px;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        color: #1e8c76;
      }
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;
  }

  p {
    font-weight: 400;
    font-size: 14pxz;
    color: #808080;
    margin: 0;
  }
}

.displayTime {
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    color: #7f8699;
    margin-bottom: 0px;
    font-size: 14px;
  }

  .emptyLabel {
    height: 20px;
  }
}

.inputBackground {
  background: #eff0f5;
  padding: 12px 16px;
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;
}

.timeSelect {
  display: flex;
  gap: 10px;
  align-items: center;

  .workingHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .emptyLabel {
    height: 20px;
  }
}

.action {
  display: flex;
  align-items: center;
  height: 100%;

  &.v2 {
    height: 80%;
  }

  .btn {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;

    span {
      display: block;

      img {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
    }
  }
}

.dateBox {
  position: relative;

  label {
    width: 100%;
  }

  .dobInput {
    &::-webkit-calendar-picker-indicator {
      z-index: 10;
      // opacity: 0;
    }
  }

  .scheduleIcon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
}

.time {
  position: relative;

  .dateInput {
    // height: 42.33px;
  }

  img {
    position: absolute;
    top: 57%;
    left: 13px;
  }

  input,
  select {
    text-align: center;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    // display: none;
  }

  input[type="date"]::-moz-calendar-picker-indicator {
    // display: none;
  }

  input[type="date"]::-ms-calendar-picker-indicator {
    // display: none;
  }
}

.listCardBox {
  align-items: center;

  :global(.form-label) {
    font-size: 16px;
  }
}

.teamBox {
  background-color: #eff0f5;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  box-shadow: 1px 2px 3px rgba(6, 46, 36, 0.1019607843);
  border-radius: 12px;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  min-width: 235px;

  .left {
    background-image: linear-gradient(var(--Main2), var(--Main3));
    max-height: 35px;
    max-width: 35px;
    padding: 12px 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #fff;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .memberName {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      margin: 0;
      margin-bottom: 3px;
    }

    .priceDesc {
      color: rgba(0, 0, 0, 0.687);
      width: 100%;
      padding: 0;
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  margin-top: 20px;
  margin-bottom: 13px;
  flex-wrap: wrap;

  button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    background-image: linear-gradient(var(--Main2), var(--Main3));
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    min-width: 212px;
  }
}

.rollTypeBox {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18px;

  .rollType {
    padding: 13px 45px;
    border-radius: 10px;
    background-color: #eff0f5;
    box-shadow: 0 1px 3px 0 #00000040;

    &.active {
      border: 2px solid var(--Main2);
      box-shadow: none;

      span {
        color: #1e8c76;
      }
    }

    span {
      font-weight: 600;
      font-size: 16px;
      color: #7f8699;
      text-align: center;
    }
  }
}

// second tab

.promotionForm {
  background: #fff !important;
  border: 1px solid var(--Main3) !important;
  text-align: center;
  font-size: 14px !important;
  color: #1e8c76 !important;
  font-weight: 600 !important;
  box-shadow: none !important;

  &.error {
    outline: 1px solid red !important;
  }
}

.discount {
  display: flex;
  gap: 7px;

  .iconVersion {
    position: relative;

    .percent {
      position: absolute;
      top: 50%;
      transform: translate(-100%, 10%);
      right: 0;
    }
  }

  .error {
    input {
      border: 1px solid red;
      box-shadow: none;

      &:hover {
        border: 1px solid red;
      }
    }
  }

  .usdPercent {
    display: flex;
    background: #efeef3;
    border-radius: 8px;
    justify-content: space-between;
    // margin-top: 13px;
    align-items: center;
    // padding: 5px 10px;
    width: 70%;
    height: 45px;
    margin: 0;
    padding: 8px;

    span {
      cursor: pointer;
      color: #7f8699;
      font-size: 16px;
      font-weight: 400;
      width: 50%;
      text-align: center;
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        background: linear-gradient(269.98deg, #65beac 0.02%, #3e8575 98.42%);
        color: #fff;
        padding: 0 4px;
        border-radius: 5px;
      }
    }
  }
}

.add,
.substract {
  padding: 2px 9px;
  border-radius: 50%;
  background: #3e8575;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.statusBox {
  p {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .switch {
    margin: 0px;
    margin-top: 10px;
  }
}

.substract {
  background-color: red;
}

.border_bottomLine {
  border: 1px solid #eff0f5;
}

.checkSelect_main {
  display: flex;
  gap: 14px;
  margin-top: 13px;

  .checkBox {
    margin-top: 5px;
    width: 6%;
  }

  .content {
    width: 94%;

    h4 {
      // color: #1e8c76;
      font-size: 17px;
      // font-weight: 700;
    }

    p {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
    }

    :global(.form-label) {
      color: #181818;
      // font-weight: 600;
    }

    .promotionForm {
      background-color: #eff0f5 !important;
      border: 1px solid #eff0f5 !important;
    }
  }
}

// third tab
.editorBox {
  position: relative;
  margin-bottom: 31px;

  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  :global(.accordion) {
    :global(.accordion-item) {
      background-color: #eff0f5;
    }
  }
}

.editorContainer {
  // min-height: 200px;
  height: auto;
  border: 1px solid gainsboro;
  border-radius: 10px;
  overflow: hidden;

  :global(.quill) {
    // height: 150px;

    :global(.ql-toolbar) {
      background-color: #ffffff;
      padding: 10px 10px;
      font-size: 14px;
      height: 48px;
      border: none;
      border-bottom: 1px solid gainsboro;
      text-align: left;
      box-shadow: none;
    }

    :global(.ql-container) {
      background-color: #ffffff;
      border: none;
      padding: 0px 3px;
      font-size: 14px;
      box-shadow: none;
      min-height: 150px;
    }
  }
}

.header {
  .headerList {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 12px;

    li {
      min-width: 113px;
    }

    li {
      &.services {
        min-width: 343px;

        .services_labeling {
          color: #000000;
          font-weight: 600;
          font-size: 15px;
        }
      }

      &.duration {
        min-width: 105px;
      }

      .netDis_prices {
        font-size: 13px;
        color: #000;
        font-weight: 600;
        line-height: 16px;

        .inputBox {
          margin-top: 7px;
          max-width: 105px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          gap: 5px;

          &.finalPrice {
            :global(.form-control) {
              background: none;
              box-shadow: none;
              border: none;
              font-weight: 600;
            }
          }

          input {
            height: 22px;
            width: 36px;
            padding: 3px 9px;
            background: none;
            box-shadow: none;
            border: 1px solid #DCDCDC;
            border-radius: 9px;
            border: none;
            border-bottom: 1px solid #a4a1a1;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.branchHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.108);
}

.branchList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.branches {
  margin-bottom: 12px;

  .dateBox {
    label {
      span {
        top: -9px;
      }
    }
  }

  .branchBox {
    background-color: #eff0f5;
    border: 1px solid #eff0f5;
    padding: 0px 13px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    h5 {
      font-size: 14px;
      margin: 0;
    }
  }
}

.bundleScreen {
  margin-bottom: 12px;

  .discountScreen {
    display: flex;
    align-items: center;

    .descBox {
      max-width: 531px;
      min-width: 531px;
      display: flex;
      align-items: center;

      .bundleName {
        font-size: 16px;
        min-width: 320px;
        margin: 0;
        font-weight: 400;
      }

      .duration {
        font-size: 16px;
        min-width: 106px;
        max-width: 106px;
        margin: 0;
      }

      .price {
        font-size: 16px;
        color: #3e8575;
        margin: 0;
      }
    }

    .discountBox {
      display: flex;
      align-items: center;
      gap: 42px;

      .inputBox {
        margin-top: 7px;
        max-width: 105px;
        margin-right: 12px;
        margin-bottom: 9px;
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 13px;
        font-weight: 500;

        &.finalPrice {
          :global(.form-control) {
            text-align: center;
            background: none;
            box-shadow: none;
            border: none;
            font-weight: 600;
          }
        }

        input {
          height: 22px;
          width: 36px;
          padding: 3px 9px;
          background: none;
          box-shadow: none;
          border: 1px solid #DCDCDC;
          border-radius: 9px;
          border: none;
          border-bottom: 1px solid #a4a1a1;
          border-radius: 0;
        }
      }
    }
  }

  .actionButton {
    img {
      cursor: pointer;
    }
  }
}

.service_addAccordion {
  .catBox {
    display: flex;
    align-items: center;

    &.bundleVersion {
      padding: 10px 0;
    }

    h5 {
      font-size: 16px;
      min-width: 537px;
      margin: 0;
      font-weight: 400;

      &.v2 {
        min-width: 518px;
        font-size: 14px;
      }
    }

    .inputBox {
      margin-top: 3px;
      max-width: 105px;
      margin-right: 12px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 13px;
      color: #000;
      font-weight: 600;

      &.finalPrice {
        :global(.form-control) {
          background: none;
          box-shadow: none;
          border: none;
          font-weight: 600;
        }
      }

      input {
        height: 22px;
        width: 36px;
        padding: 3px 9px;
        background: none;
        box-shadow: none;
        border: 1px solid #DCDCDC;
        border-radius: 9px;
        border: none;
        border-bottom: 1px solid #a4a1a1;
        border-radius: 0;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    .itemContainer {
      display: flex;
      align-items: center;
      margin-left: 17px;
      // gap: 33px;

      &.bundle {
        margin-left: 0;

        li {
          margin-top: 10px;
        }
      }

      li {
        display: flex;
        width: 100%;
        background: #fbfbfb;
        border-radius: 7px;
        padding: 9px 15px;
        // margin-bottom: 10px;
        width: 514px;
        margin-right: 3px;

        .bodyLeft {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .category_selected {
            min-width: 304px;

            h5 {
              font-size: 15px;
              color: #000;
              margin-bottom: 0px;

              &.serviceName {
                min-width: 150px;
              }
            }
          }

          .price_time {
            display: flex;
            gap: 13px;
            margin-right: 8px;
            // text-align: end;

            h5 {
              font-size: 14px;
              color: #000;
              margin-bottom: 0px;
              min-width: 86px;
            }
          }

          .list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              padding: 0;
              margin: 0px;
              color: #7f8699;
              font-size: 14px;
            }
          }

          h5 {
            margin-bottom: 4px;
            font-size: 16px;
          }

          p {
            margin: 0px;
            color: #7f8699;
            font-size: 14px;
          }
        }
      }

      .inputBox {
        margin-top: 5px;
        max-width: 105px;
        margin-left: 9px;
        margin-bottom: 3px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 13px;
        color: #000;
        font-weight: 600;

        &.finalPrice {
          :global(.form-control) {
            text-align: center;
            background: none;
            box-shadow: none;
            border: none;
            font-weight: 600;
          }
        }

        input {
          height: 22px;
          width: 36px;
          padding: 3px 9px;
          background: none;
          box-shadow: none;
          border: 1px solid #DCDCDC;
          border-radius: 9px;
          border: none;
          border-bottom: 1px solid #a4a1a1;
          border-radius: 0;
        }
      }

      .actionButton {
        position: relative;

        img {
          cursor: pointer;
        }
      }
    }
  }

  // min-width: 300px;
  :global(.accordion) {
    :global(.accordion-item) {
      border: none;

      :global(.accordion-header) {
        // display: inline-flex;
        border-bottom: none;
        border-top: none;

        :global(.accordion-button) {
          box-shadow: none;
          background-color: #fff;
          padding: 0;
          padding-left: 25px;

          &:global(.collapsed) {
            &::after {
              content: "+";
              transform: rotate(0);
            }
          }

          &::after {
            position: absolute;
            left: -1px;
            display: flex;
            align-items: center;
            font-size: 20px;
            content: "-";
            background-image: none !important;
            transform: rotate(0);
            transition: content 3s;
          }

          :global(.accordion) {
            :global(.accordion-item) {
              border: none;

              :global(.accordion-header) {
                // display: inline-flex;
                border-bottom: 1px solid gainsboro;

                :global(.accordion-button) {
                  box-shadow: none;
                  background-color: #fff;
                  padding-left: 0;

                  &:global(.collapsed) {
                    &::after {
                      content: "+";
                      transform: rotate(0);
                    }
                  }

                  &::after {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    content: "-";
                    background-image: none !important;
                    transform: rotate(0);
                    transition: content 3s;
                  }

                  :global(.accordion) {
                    :global(.accordion-item) {
                      border: none;

                      :global(.accordion-header) {
                        // display: inline-flex;
                        border-bottom: 1px solid gainsboro;

                        :global(.accordion-button) {
                          box-shadow: none;
                          background-color: #fff;
                          padding-left: 0;

                          &:global(.collapsed) {
                            &::after {
                              content: "+";
                              transform: rotate(0);
                            }
                          }

                          &::after {
                            display: flex;
                            align-items: center;
                            font-size: 20px;
                            content: "-";
                            background-image: none !important;
                            transform: rotate(0);
                            transition: content 3s;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      :global(.accordion-body) {
        padding: 0 20px;

        :global(.accordion-header) {
          // display: inline-flex;
          border-top: none;
          border-bottom: none;

          :global(.accordion-button) {
            box-shadow: none;
            background-color: #fff;
            padding-left: 0;

            &:global(.collapsed) {
              &::after {
                content: "+";
                transform: rotate(0);
              }
            }

            &::after {
              display: flex;
              align-items: center;
              font-size: 20px;
              content: "-";
              background-image: none !important;
              transform: rotate(0);
              transition: content 3s;
            }
          }
        }

        :global(.accordion) {
          :global(.accordion-item) {
            :global(.accordion-header) {
              :global(.accordion-button) {
                padding: 0;
                padding-left: 25px;

                &:global(.collapsed) {
                  &::after {
                    content: "+";
                    transform: rotate(0);
                  }
                }

                &::after {
                  position: absolute;
                  left: -1px;
                  display: flex;
                  align-items: center;
                  font-size: 20px;
                  content: "-";
                  background-image: none !important;
                  transform: rotate(0);
                  transition: content 3s;
                }
              }
            }

            :global(.accordion-body) {
              padding: 0 0px;
            }
          }
        }
      }
    }
  }

  .discountBox {
    .inputBox {
      &.finalPrice {

        :global(.form-control) {
          box-shadow: none !important;
          background: none !important;

          border: none !important;
          font-weight: 500;

        }
      }
    }
  }
}

.addButton {
  min-width: 120px;

  span {
    padding-left: 10px;
  }
}

.galleryBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
  margin-bottom: 30px;

  .item {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    position: relative;
    border: 1px solid #dfdbdb;

    .deleteCross {
      position: absolute !important;
      top: -10px;
      right: -10px;
      cursor: pointer;
      border-radius: 50%;
      background-color: #EFEEF3;
      padding: 5px;
    }

    &.add {
      border: 3px solid var(--Main2);
      cursor: pointer;
    }

    .bgImage {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }

    label {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .add {
        height: 50px;
        width: 50px;
        margin: 0 auto;
        cursor: pointer;
      }

      input[type="file"] {
        display: none;
      }
    }
  }
}

.uploadImageBox {
  // border: 2px solid #eff0f5;
  background-color: #DCDCDC;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  height: 150px;
  width: 150px;

  span {
    background-color: #fff;
    box-shadow: 0px 3px 14px 0px rgba(29, 33, 30, 0.1019607843);
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -9px;
    right: -9px;
  }

  .cross {
    position: absolute;
    top: -16px;
    right: -16px;
    transform: translate(-10%, 10%);
  }

  .bgImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  label {
    .boxImage {
      width: 52.36px;
      height: 42.12px;
      cursor: pointer;
      fill: #777C88;
    }

    input {
      font-size: 4px;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}

.everyDay {
  margin-bottom: 20px;

  .everyDaySwith {
    display: flex;
    margin-bottom: 15px;
  }

  .everyDaySelector {
    width: 50%;

    .saveBtn {
      color: #fff;
      background-image: linear-gradient(var(--Main2), var(--Main3));
      height: 39px;
      border: none;
      min-width: 70px;
    }
  }

  p {
    color: #7f8699;
    margin-bottom: 0px;
  }

  // label {
  //   width: 150px;
  // }

  >div {
    margin: 0px;
    margin-right: 20px;
    // width: 200px;
  }

  :global(.MuiSwitch-root) {
    :global(.MuiButtonBase-root) {
      color: var(--Main2);
    }

    :global(.Mui-checked + .MuiSwitch-track) {
      background: var(--Main2);
    }
  }
}

.timeSelect {
  display: flex;
  gap: 10px;
  align-items: center;

  .workingHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .emptyLabel {
    height: 20px;
  }
}

.dateBox {
  position: relative;

  label {
    width: 100%;
  }

  .dobInput {
    &::-webkit-calendar-picker-indicator {
      z-index: 10;
      // opacity: 0;
    }
  }

  .scheduleIcon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
}

.plusBtn {
  background: #479382 !important;
  color: #fff !important;
  padding: 0px 0px;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  min-width: 50px;
  font-size: 12px;
}

.btn2Clr {
  display: flex;
  align-items: center;
  gap: 5px;

  a {
    color: inherit;
    text-decoration: none;
    padding-bottom: 1px;

    &:hover {
      text-decoration: underline;
    }
  }

  &.v2 {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  background: linear-gradient(180.35deg,
    #65beac 0.27%,
    #3e8575 98.11%) !important;
}

.everyDay {
  margin-bottom: 20px;

  .everyDaySwith {
    display: flex;
    margin-bottom: 15px;
  }

  .everyDaySelector {
    width: 50%;

    .saveBtn {
      color: #fff;
      background-image: linear-gradient(var(--Main2), var(--Main3));
      height: 39px;
      border: none;
      min-width: 70px;
    }
  }

  p {
    color: #7f8699;
    margin-bottom: 0px;
  }

  // label {
  //   width: 150px;
  // }

  >div {
    margin: 0px;
    margin-right: 20px;
    // width: 200px;
  }

  :global(.MuiSwitch-root) {
    :global(.MuiButtonBase-root) {
      color: var(--Main2);
    }

    :global(.Mui-checked + .MuiSwitch-track) {
      background: var(--Main2);
    }
  }
}

.timeCheckbox {
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }

  :global(.form-select) {
    margin-right: 15px;
  }
}

:global(.show) {
  display: block !important;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 666px) {
  .editorContainer {
    min-height: 222px;
    /* Initial minimum height */
    height: auto;

    :global(.quill) {
      height: 190px;
    }
  }
}

@media (max-width: 400px) {
  .editorContainer {
    margin-bottom: 23px;
  }
}

@media (max-width: 990px) {
  .editorContainer {
    min-height: 240px;
    /* Initial minimum height */
    height: auto;

    :global(.quill) {
      height: 170px;
    }
  }

  .teamBox {
    gap: 5px;
    min-width: 210px;

    .left {
      span {
        font-size: 13px;
      }
    }

    .right {
      .memberName {
        font-size: 13px;
      }

      .priceDesc {
        font-size: 11px;
      }
    }
  }

  .usdPercent {
    width: 80%;
    padding: 8px 5px;
    gap: 2px;

    span {
      cursor: pointer;
      color: #7f8699;
      font-size: 14px !important;
      font-weight: 400;
      // width: 50%;
      text-align: center;

      &.active {
        padding: 0 1px;
        width: 40px;
      }

      &.percentActive {
        width: 50px;
      }
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
  .ddfg {
    overflow-x: auto;
    overflow: hidden;

    // &::-webkit-scrollbar {
    //   width: 10px;
    // }

    // &::-webkit-scrollbar-track {
    //   background: #f1f1f1;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #e71b1b;
    // }

    // &::-webkit-scrollbar-thumb:hover {
    //   background: #555;
    // }
  }

  .header {
    .headerList {
      width: max-content;
    }
  }
}

@media (max-width: 1159px) {}