.dateBox {
  position: relative;

  label {
    width: 100%;
  }

  .dobInput {
    text-align: center;

    &::-webkit-calendar-picker-indicator {
      // display: none;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(65%, 200%);
      z-index: 10;
      opacity: 0;
    }
  }

  .scheduleIcon,
  .locationIcon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(50%, 130%);
    z-index: 1;
  }

  .addressInput {
    padding-left: 40px;
  }

  .locationIcon {
    transform: translate(48%, 160%);
  }
}

// edit client setting
.editButton {
  box-shadow: none;
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &.v2 {
    position: absolute;
    top: 30px;
    right: 20px;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

.editContainer {
  margin-bottom: 20px;

  p {
    color: #777C88;
    font-size: 14px;
  }
}

.stylistCard {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    margin-top: 5px;
  }

  .stylistCardBody {
    margin-left: 10px;

    h5 {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
    }

    .btnAction {
      background: #e3edf0;
      padding: 0px;
      width: 35px;
      height: 35px;
      border-radius: 50px;
      margin-right: 5px;
      margin-top: 5px;
      border: none !important;

      img {
        height: 16px;
        width: auto;
        border-radius: 0;
        margin: 0px;
      }
    }
  }
}

.serviceCard {
  margin: 0;
  font-size: 14px;
}

.timeCard {
  p {
    margin: 0px;
    font-size: 14px;
  }

  h6 {
    margin: 0;
    font-size: 14px;
  }
}

.bookingStatus {
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
}

.bookingTable {
  display: flex;
  // justify-content: space-between;
  align-items: baseline;
  background: #fff;
  // box-shadow: 0px 0px 4px 2px rgba(239, 236, 236, 0.7019607843);
  border: 1px solid #dcdcdc;
  padding: 14px 5px;
  border-radius: 6px;
  margin-top: 20px;
  // overflow-x: auto;
  flex-wrap: wrap;
  gap: 8px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(189, 189, 189);
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 5px;
  }

  .tableCell {
    padding: 0px 10px;
    font-size: 14px;
    // max-width: 190px;
    // min-width: 190px;

    &.branchCell {
      // max-width: 150px;
      // min-width: 150px;
    }

    &.serviceList {
      // max-width: 230px;
      // min-width: 230px;
    }

    &.teamList {
      // max-width: 230px;
      // min-width: 230px;
      font-weight: 600;
    }
  }

  b {
    font-weight: 500;
    font-size: 16px;
  }
}

.dropDownList {
  :global(.btn) {
    border: none;
    padding: 0px;
    background: transparent !important;
    color: #777C88 !important;
    font-size: 32px;

    &::after {
      display: none;
    }
  }

  :global(.dropdown-menu) {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        a {
          font-size: 14px;
          padding: 5px 15px;
        }
      }
    }
  }
}

.bookingFilter {
  border: 1px solid #dad4d4;
  border-radius: 12px;
}

.reviewCard {
  background: #e9f6f1;
  padding: 30px 30px;
  border-radius: 20px;
  margin-bottom: 14px;

  .reviewCardBody {
    display: flex;

    .teamProfile {
      width: 70px;
      height: 70px;
      border-radius: 10px;
      margin-right: 15px;
    }

    .fakeLogo {
      width: 70px;
      height: 70px;
      border-radius: 10px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(#65beac, #3e8575);

      span {
        color: #fff;
        font-size: 20px;
        text-transform: uppercase;
      }
    }

    .reviewCardBodyRight {
      .contentUl {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          margin-bottom: 3px;

          span {
            display: inline-block;
            width: 130px;
            color: #777C88;
          }
        }
      }

      .startUl {
        list-style: none;
        margin: 15px 0px 15px 0px;
        padding: 0;
        display: flex;
        line-height: 0px;

        li {
          font-size: 30px;
          cursor: pointer;
          margin-right: 5px;
          color: #3e8575;
        }
      }

      p {
        font-size: 14px;
      }

      .author {
        position: relative;
        padding: 5px 0px 5px 22px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 8px;
          height: 100%;
          background-image: linear-gradient(to right,
              var(--Main2),
              var(--Main3));
        }

        p {
          margin: 0px;
        }

        h5 {
          font-size: 16px;

          span {
            color: #0c9b7e;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.profileCardImg {
  min-width: 120px !important;
  max-width: 120px !important;
  height: 120px;
  position: relative;
  margin: 0 auto;

  img {
    min-width: 120px !important;
    max-width: 120px !important;
    height: 120px;
    object-fit: cover;
    border-radius: 15px;
  }

  .editBtn {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-image: linear-gradient(var(--Main2), var(--Main3));
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 8px;
  }
}

.profileBox {
  width: 102px;
  height: 102px;
  background: linear-gradient(#65BEAC, #3E8575);
  margin: 0;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    font-size: 30px;
  }
}

.profileCard {
  border: 1px solid #DCDCDC;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 12px;
  text-align: center;
  // height: 100%;

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 24px;
  }

  p {
    margin: 0px;
    font-size: 14px;
    color: #777C88;
    margin-top: 6px;

    span {
      color: #222;
      font-weight: 500;
    }
  }
}

.informationMembershipCard {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 25px 20px;
  background-color: #fff;

  .membershipBox {

    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 200px;
      width: 100%;
      background-image: linear-gradient(var(--Main2), var(--Main3));
      border-radius: 10px;
      color: #fff;
      padding: 25px 15px;

      .businessName {
        font-size: 18px;
        font-weight: 500;
        border: none;
      }

      .memberInfo {

        h4 {
          font-weight: 400;
          font-size: 17px;
          margin-bottom: 5px;
        }

        h6 {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 15px;
          color: var(--Main2);
        }
      }
    }

  }

  .membershipBody {
    .serviceBox {
      padding: 20px 20px 4px 20px;
      border: 0.9px solid #EFF0F5;
      border-left: 10px solid #777C88;
      background: #EFF0F5;
      width: 100%;
      border-radius: 10px;
      margin-bottom: 10px;

      .serviceName {
        font-weight: 600;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }

    .serviceList {
      margin-bottom: 0 !important;
      margin-top: 0;
      padding: 0;

      li {
        padding: 0;
        margin: 0;

        .serviceName {
          font-weight: 500;
        }

        .remain {
          margin-left: 4px;
        }
      }
    }

    .number {
      display: flex;
      align-items: center;
      gap: 5px;

      .value {
        margin-bottom: 8px;
      }
    }

    .packageName {
      font-size: 16px;
    }

    .memberName {
      font-size: 19px;

      span {
        font-weight: 400;
      }
    }

    .serviceHead {
      font-size: 18px;
    }

    .numberHeading {
      font-size: 16px;
    }
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid rgba(239, 240, 245, 1);
    margin-bottom: 0px;
    padding-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editIcon {
      color: #4f9e8d;
      font-size: 21px;
      padding: 0px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0px;
    font-size: 14px;


    li {
      display: flex;
      justify-content: space-between;
      padding: 6px 0px;
      text-align: right;
      margin-bottom: 0px;
      font-size: 14px;
      color: #000;
      font-weight: 400;

      span {
        color: #777C88;
        width: 50%;
        text-align: left;
      }
    }
  }

  .bottom_last_button {
    text-align: center;
    color: #23b597;
    font-size: 14px;
    font-weight: 600;
    border-top: 1px solid rgba(239, 240, 245, 1);
    padding-top: 13px;

    :global(.btn) {
      color: #23B597;
      font-weight: 500;
      padding: 0;
    }
  }
}

.informationCard {
  border: 1px solid #DCDCDC;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h5 {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 0px;
    padding-bottom: 15px;
    position: relative;

    .editIcon {
      position: absolute;
      right: 0;
      top: -2px;
      color: #4f9e8d;
      font-size: 21px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0px;
    font-size: 14px;

    li {
      display: flex;
      justify-content: space-between;
      padding: 5px 0px;
      text-align: right;
      color: #777C88;
      font-size: 14px;

      span {
        color: #222;
        width: 20%;
        text-align: left;
        font-weight: 500;
      }
    }
  }

  .deleteAccount {
    text-align: center;
    color: #3E8575;
    font-size: 16px;
    font-weight: 600;
    border-top: 1px solid #dad8d8;
    padding-top: 15px;
    cursor: pointer;
    margin-bottom: 0px;
  }
}

.infoCard {
  border: 1px solid #DCDCDC;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 4px;
  text-align: center;
  color: #777C88;
  margin-bottom: 13px;

  p {
    font-size: 14px;
    margin: 0;
    text-wrap: nowrap;
  }

  h5 {
    font-weight: 700;
    font-size: 34px;
    color: #222;

  }
}

.membershipImg {
  img {
    width: 100%;
  }
}

.membershipBody {
  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  h6 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  p {
    color: #777C88;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .cancelMembership {
    background-image: linear-gradient(var(--Main2), var(--Main3));
    color: #fff;
    border: none;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 60px;
  }
}

.titleBox {
  margin: 25px 0px 0px 0px;
  display: flex;
  align-items: center;

  .tagBox {
    background-image: linear-gradient(#65beac, #3e8575);
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 3px 8px;
    border-radius: 5px;
    margin-left: 5px;
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .bookingTable {
    overflow-x: auto;

    .tableCell {
      padding: 0px 15px;
      font-size: 14px;
      min-width: 230px;
    }

    b {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .actionBtn {
    margin-top: 20px;

    ul {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      li {
        margin-left: 0px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
        min-width: 150px;
      }
    }
  }

  .radioBtn {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 763px) {


  .membershipImg {
    margin-bottom: 19px;
  }

  .bookingTable {
    overflow-x: scroll;

    .tableCell {
      padding: 0px 15px;
      font-size: 14px;
      min-width: 200px;
    }

    b {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .reviewCardBody {
    flex-direction: column;

    .teamProfile {
      margin: 0 auto !important;
      margin-bottom: 20px !important;
      height: 100px !important;
      width: 100px !important;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}