.pageTitle {
  h5 {
    margin: 0px;
    font-size: 20px;
    color: #525c62;
  }
}
.btnGroup {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    background-color: #fff;
    border-radius: 12px;
    margin: 0;
    padding: 0;
    li {
      padding: 3px;
      margin: 0 !important;

      .btn {
        color: #509f8e;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;

        border: none;
        border-radius: 12px;
        padding: 10px 30px;

        &.active {
          background-image: linear-gradient(#65beac, #3e8575) !important;
          color: #fff !important;
        }
      }
    }
  }
}
.customer {
  border-radius: 10px;
  overflow-x: hidden;
  margin-top: 20px;
  display: flex;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 10%);
  background: #eff0f5;
  &.cancel {
    background-color: #ffdada;
  }
  .border {
    width: 10px;
    background-image: linear-gradient(#65beac, #3e8575);
    &.cancel {
      background-image: none;
      background-color: #ff4848;
    }
  }
  .main {
    width: 100%;
    .top {
      padding: 20px 20px 5px 20px;
      &.cancel {
        background-color: #ffdada;
      }
      p {
        margin: 0;
      }
      .date {
        font-size: 16px;
        font-weight: 700;
      }
      .time {
        font-size: 15px;
        font-weight: 400;
        color: #0c9b7e;
      }
      .service {
        font-size: 14px;
        font-weight: 400;
      }
      .status {
        font-size: 14px;
        font-weight: 400;
        color: #7f8699;
      }
      .name {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .bottom {
      background-color: #fff;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.cancel {
        background-color: rgb(255 253 253 / 53%);
      }
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: #525c62;
        &.cancel {
          color: red;
        }
      }
      button {
        border: none;
        background-image: linear-gradient(#65beac, #3e8575);
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        padding: 8px 20px;
        border-radius: 12px;
      }
    }
  }
}

//calendar style

.dayCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%; /* Ensure the cell occupies full height */
  div {
    color: #525c62;
    font-weight: 400;
  }
}

.dayButtonContainer {
  margin-top: auto; /* Push the container to the bottom */
}

.dayButton {
}
:global(.fc-daygrid-event-harness) {
  a {
    border: none;
    :global(.fc-event-main) {
      :global(.fc-event-main-frame) {
        :global(.fc-event-title-container) {
          background-color: #eff0f5;
          color: #4c89e4;

          border-left: 3px solid #4c89e4;
        }
      }
    }
  }
}

:global(.fc-daygrid-day-frame) {
  :global(.fc-scrollgrid-sync-inner) {
  }
}
:global(.fc-daygrid-day-top) {
  :global(.fc-daygrid-day-number) {
    text-decoration: none !important;
  }
}
:global(.fc-view-harness) {
  background-color: #fff !important;
}
:global(.fc-col-header-cell-cushion) {
  font-weight: bold;
  text-decoration: none;
  color: #525c62;
  font-size: 14px;
  font-weight: 400;
}

:global(.fc-toolbar-chunk) {
  h2 {
    font-size: 20px !important;
    color: #525c62;
  }
  :global(.fc-today-button) {
    background-image: linear-gradient(var(--Main2), var(--Main3)) !important;
    border: none;
  }
  :global(.fc-button-group) {
    :global(.fc-button-primary) {
      background-image: linear-gradient(var(--Main2), var(--Main3)) !important;
      border: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    :global(.fc-button-primary):first-child {
      border-right: 2px solid rgba(255, 255, 255, 0.447);
    }
  }
}
:global(.fc-daygrid-day-frame) {
  border: 2px solid #eff0f5;
}
:global(.fc) {
  :global(.fc-button:disabled) {
    opacity: 1 !important;
  }
}

@media (max-width: 500px) {
  .btnGroup {
    width: 80%;
    margin: 0 auto;

    ul {
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      margin-top: 10px;
      li {
        width: 100%;
        display: flex;
        justify-content: center;
        .btn {
          width: 99%;
          margin: 0 auto;
          &.active {
            background-image: linear-gradient(#65beac, #3e8575) !important;
            color: #fff !important;
          }
        }
      }
    }
  }
  .customer {
    .main {
      .bottom {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &.cancel {
          background-color: rgb(255 253 253 / 53%);
        }
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: #525c62;
          &.cancel {
            color: red;
          }
        }
        button {
          border: none;
          background-image: linear-gradient(#65beac, #3e8575);
          color: #fff;
          font-weight: 600;
          font-size: 14px;
          padding: 8px 20px;
          border-radius: 12px;
          margin-top: 10px;
        }
      }
    }
  }
}
