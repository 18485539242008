.loginSection {
  padding: 100px 0px;

  .loginBg {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }

  .modalContent {
    padding: 50px 0px;

    .modalCenter {
      position: relative;
      z-index: 1;

      .formBody {
        background: #fff;
        width: 650px;
        margin: 0 auto;
        border-radius: 20px;
        box-shadow: 0 0 20px #00ffff94;
        z-index: 1;
        display: flex;
        padding: 20px 10px;
        min-height: 400px;
        justify-content: center;
        flex-direction: column;

        .content {
          .successImage {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          h5{
            text-align: center;
            font-size: 18px;
            margin-top: 25px;
          }
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .loginSection {
    .loginBg {
      height: 100%;
    }

    .modalContent {
      padding: 30px 20px;

      .modalCenter {
        .formBody {
          width: 100%;
          padding: 20px 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
