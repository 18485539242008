.locationSelect {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eff0f5;
  padding: 7px 0;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .smallText {
    color: #808080;
    font-size: 14px;
  }
}

.changeInput {
  position: relative;

  img {
    position: absolute;
    top: 52%;
    left: 10px;
    cursor: pointer;

    &.clockIcon {
      top: 58%;
      left: 13px;
    }
  }

  input,
  select {
    padding: 10px 40px !important;

    &.v2 {
      text-align: center;
      cursor: pointer;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 5%;
    top: 57%;
    opacity: 0;
    z-index: 3;
  }
}

.addBooking {
  button {
    width: 100%;
    height: 142px;
    background: #eff0f5;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000026;

    &:hover {
      background: #eff0f5;
      border: none;
    }

    &:active {
      background: #eff0f5 !important;
      border: none;
    }

    img {
      height: 50px;
      text-align: center;
    }
  }

  span {
    color: #4d9b8a;
    font-size: 14px;
    display: block;
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
  }
}

.table_services {
  .boldAera {
    font-weight: 600;
  }

  .thAreaClr {
    color: #5cb4a1;
  }
}

.totalAmt {
  span {
    color: #1ab192;
    font-weight: 600;
  }

  color: #7f8699;
  text-align: end;
}

.borderLine {
  border-bottom: 1px solid #e3edf0;
}

.addingServe {
  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      button {
        background: linear-gradient(180.35deg, #65beac 0.27%, #3e8575 98.11%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        border: none;
        padding: 7px 0;
        display: flex;
        align-items: center;

        &:hover {
          background: linear-gradient(180.35deg, #65beac 0.27%, #3e8575 98.11%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border: none;
        }

        &:active {
          background: linear-gradient(180.35deg, #65beac 0.27%, #3e8575 98.11%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border: none;
        }

        img {
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}

.saleStatistic {
  width: 100%;
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .saleStatistic_widthSet {
    width: 49%;

    .saleStatistic_headingMain {
      h5 {
        font-size: 19px;
        font-weight: 600;
      }
    }

    .saleStatistic_flexBox {
      display: flex;
      justify-content: space-between;
      gap: 6px;
    }
  }

  .saleBox {
    background-color: #fff;
    min-width: 31.9%;
    // box-shadow: 0 0 20px 0 #0000001a;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1019607843);
    border-radius: 15px;
    padding: 18px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12px;

    h5 {
      color: #1ab192;
      font-size: 14px;
      margin: 0;
      margin-bottom: 4px;
    }

    h1 {
      font-weight: 600;
      font-size: 22px;
      margin: 0;
    }
  }
}

.btn2Clr {
  background: linear-gradient(
    180.35deg,
    #65beac 0.27%,
    #3e8575 98.11%
  ) !important;
}

.calendarBox {
  display: flex;
  align-items: center;
  justify-content: center;

  .buttonBox {
    display: flex;
    align-items: center;
    background-color: #eff0f5;
    padding: 2px 3px;
    border-radius: 8px;
    gap: 8px;
    height: 38px;

    .todayButton {
      height: 97%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-image: linear-gradient(#65beac, #3e8575);
      border: none;
      color: #fff;
      width: 90px;
      font-size: 12px;
      font-weight: 600;
    }

    .dateSelector {
      border: none;
      width: 130px;
      font-size: 14px;
      background-color: #eff0f5;

      &:focus {
        box-shadow: none;
      }
    }
  }

  button {
    border: none;
    background: none;

    .left {
      font-size: 26px;
      transition: all 0.3s;

      // &:active {
      //   height: 25px;
      // }
    }
  }
}

.afterRemove {
  &::after {
    content: none !important;
  }
}

.availabilityView {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.219);
  border-radius: 8px;
  box-shadow: none;
  overflow: hidden;
  margin-bottom: 0;
  width: 121px;

  :global(.form-select) {
    background-color: #f3f3f3;
    height: 36px;
    text-align: center;
    box-shadow: none;
    cursor: pointer;
    border: none;
    border-radius: 0;
    padding-left: 4px;
    padding-right: 17px;

    &:focus {
      border: 1px solid transparent;
    }
  }
}

.multi_date_pickerMain {
  display: flex;
  align-items: center;
  gap: 9px;

  :global(.left-rightBtn) {
    padding: 5px 6px;
    background-color: #ededed;

    &:active {
      border: 1px solid transparent;
      background-color: #ededed;
    }

    :global(svg) {
      font-size: 22px;
    }
  }

  :global(.rmdp-container) {
    :global(.rmdp-input) {
      position: relative;
      background-image: url(../../../assets/images/Calendar.svg);
      background-repeat: no-repeat;
      background-position: 97% 50%;
      cursor: pointer;
      padding-right: 40px;
      font-size: 14px;
      background-color: #eff0f5;
      height: 37px;
      border: 1px solid #eff0f5;
      min-width: 226px;
    }

    :global(.rmdp-calendar) {
      height: auto;
    }

    :global(.rmdp-calendar) {
      :global(.rmdp-header) {
        :global(.rmdp-arrow-container) {
          transition: 0.3s;
          background-color: #e7e7e7;
          border-radius: 4px;
          padding-top: 1px;

          &:hover {
            background-color: #677683;

            :global(.rmdp-arrow) {
              border-color: #ffffff;
            }
          }

          :global(.rmdp-arrow) {
            border-color: #677683;
            border-width: 0px 2px 2px 0;
            height: 8px;
            width: 8px;
          }
        }
      }

      :global(.rmdp-day-picker) {
        :global(.rmdp-week) {
          :global(.rmdp-week-day) {
            color: #222222;
          }

          :global(.rmdp-range) {
            background-color: #868687;
          }

          // :global(.rmdp-day) {
          //   &:not(.rmdp-disabled, .rmdp-day-hidden) {
          //     span {
          //       &:hover {
          //         background-color: #6e7685;
          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }

  :global(.light-green) {
    background: linear-gradient(
      rgb(101, 190, 172),
      rgb(62, 133, 117)
    ) !important;
  }
}

.multi_date_pick_allBtn {
  display: flex;
  flex-direction: column;
  padding: 4px 10px 10px 10px;

  :global(.btn) {
    font-size: 15px;
    width: 100%;
    padding: 4px 10px;

    &:hover {
      color: #707070;
    }

    &:active {
      border: 1px solid transparent;
    }
  }

  :global(.selectBtn) {
    background-color: #dcdcdc;
    color: #3f3f3f;
    margin-top: 6px;

    &:hover {
      color: #3f3f3f;
    }

    &:active {
      background-color: #dcdcdc;
    }

    &:global(.active) {
      background-image: linear-gradient(#65beac, #3e8575);
      border: none;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}

.chart_mainBox {
  box-shadow: 0px 0px 5.9843559265px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  height: 100%;

  .chart_btns {
    padding: 20px 26px 4px 0px;
    margin: 0 auto;
    text-align: end;

    .all_chartBtn {
      display: inline-flex;
      border: 1px solid #b5b5b5;
      border-radius: 10px;
      overflow: hidden;

      :global(.btn) {
        border-radius: 0px;
        border-left: 1px solid #b5b5b5;
        color: #616060;
        font-weight: 500;

        &:global(.activeClr) {
          color: #1ab192;
        }

        &:active {
          border: 1px solid transparent;
        }
      }
    }
  }

  .chartContainer {
    padding: 20px 18px 20px 0px;
    margin: 0 auto;
  }

  :global(.recharts-wrapper) {
    :global(.recharts-text) {
      &:global(.recharts-cartesian-axis-tick-value) {
        font-size: 12px;
      }
    }

    :global(.recharts-legend-wrapper) {
      bottom: -5px !important;

      :global(.recharts-default-legend) {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        padding-top: 10px;

        :global(.recharts-legend-item) {
          font-size: 14px;
        }
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {
  .borderLine {
    border-bottom: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
