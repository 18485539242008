.btn{
  margin-top: 27px;
  min-width: 170px;
  &.disabled{
    opacity: 0.8;
  }
}
.active{
  background-color: red;
}
.otpHeading{
  margin: 0;
  font-size: 14px;
  font-weight: 500
}
.otpScreen {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;

  input {
    background-color: transparent;
    height:40px;
    width: 40px !important;
    text-align: center;
    font-size: 16px;
    margin-right: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    border: 1px solid #eff0f5;
    padding: 10px 15px;
    border-radius: 8px;

    &:focus {
      outline: none;
      border: 1px solid var(--Main2);
      box-shadow: none;
    }

  }

  .varifyBtn {
    margin-top: 0;

    button {
      min-width: 150px;
      margin-right: 45px;
    }
  }

  .resend {
    font-size: 13px;
    font-weight: 400;
    color: #7f8699;
    display: flex;
    margin-top: 5px;

    span {
      color: #1ab192;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      margin-left: 10px;

    }
  }
}
.setting{
  position: relative;
  .editButton{
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-20%);
    cursor: pointer;
    img{
      height: 18px;
      width: 18px;
    }
  }
}
