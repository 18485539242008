.loginSection {
    // padding: 100px 0px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    margin: auto;
    position: relative;

    .signUpBgClr {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: #F4FAF6;
        height: 100%;
    }

    .title {
        text-align: left;
        font-weight: 500;
        font-size: 26px;
        margin-bottom: 8px;

        &.v2 {
            margin: 0;
        }

    }

    .desc {
        text-align: left;
        font-size: 15px;
        margin-bottom: 25px;
        color: #959595;
        margin-top: 4px;
    }

    span {
        color: var(--Main2);
        text-align: center;
        display: block;
    }

    .loginBg {
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
    }

    .modalContent {
        padding: 50px 0px;
        max-width: 1400px;
        margin: auto;

        .imgSide {
            margin-bottom: 44px;
            text-align: center;

            img {
                width: 200px;
            }
        }

        .modalCenter {
            position: relative;
            z-index: 1;

            .formBody {
                background: #fff;
                width: 620px;
                margin: 0 auto;
                padding: 50px 50px;
                padding-top: 40px;
                border-radius: 10px;
                box-shadow: 0px 5px 40px 0px #3E857526;
                // box-shadow: 0 0 20px #00FFFF94;
                z-index: 1;
                display: flex;
                align-items: center;
                min-height: calc(100% - var(--bs-modal-margin)*2);


                :global(.inputDiv) {
                    background-color: transparent;
                    height: 60px;
                    text-align: center;
                    font-size: 28px;
                }

                ::-webkit-inner-spin-button {
                    display: none;
                }

                .facebookSignup {
                    background: #1A77F2;
                    border: 1px solid #1A77F2;
                    color: #fff;
                    width: 100%;
                    height: 50px;
                    border-radius: 10px;
                    position: relative;
                    text-align: left;
                    padding-left: 45px;
                    font-weight: 500;
                    margin-top: 10px;

                    img {
                        height: 22px;
                        position: absolute;
                        left: 20px;
                    }
                }

                .googleSignup {
                    background: #E9F6F1;
                    color: #132326;
                    border: 1px solid #65BEAC;
                    width: 100%;
                    height: 50px;
                    border-radius: 10px;
                    position: relative;
                    text-align: left;
                    padding-left: 55px;
                    font-weight: 500;
                    margin-top: 10px;

                    img {
                        height: 22px;
                        position: absolute;
                        left: 20px;
                    }
                }

                .alreadyBox {
                    color: #3E8575;
                    text-align: center;
                    margin-top: 20px;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;

                    span {
                        color: #000000;
                        margin-right: 2px;
                        letter-spacing: 0.1px;
                        margin-right: 4px;
                        font-weight: 400;
                    }
                }



                .forgotBox {
                    display: block;
                    color: #3E8575;
                    text-align: end;
                    margin-bottom: 10px;
                    font-weight: 500;
                    text-decoration: none;
                }

                .successfulBox {
                    text-align: center;

                    img {
                        height: 135px;
                        margin-top: 30px;
                        margin-bottom: 45px;
                    }

                    h2 {
                        font-size: 34px;
                        font-weight: 600;
                    }

                    p {
                        color: #7F8699;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.formSign {
    .formSignGroup {
        gap: 16px;

        .btnGray {
            background: #7C7C7C;
            background-image: none !important;
            box-shadow: none !important;
            color: #fff;
            border-radius: 4px !important;
            min-width: auto;

            &.btnGrayLight {
                background: #F1F1F1;
                background-image: none !important;
                color: #959595 !important;
                min-width: 27%;
                max-width: 27%;
            }
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 991px) {

    .loginSection {

        .loginBg {
            height: 100%;
        }

        .modalContent {
            padding: 30px 20px;

            .modalCenter {

                .formBody {
                    width: 100%;
                    padding: 20px 20px;
                }

            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}